import React, { useEffect, useRef, useState } from 'react';
import './Buscador.css'
import downIcon from '../../../assets/icons/down-icon.svg'
import lupaIcon from '../../../assets/icons/lupa-icon.svg'
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import '../../../i18n';

const Buscador = () => {

    let navigate = useNavigate()

    const selectOportunidad = useRef()
    const selectTipo = useRef()

    const [showOportunidad, setShowOportunidad] = useState(false)

    const [showTipo, setShowTipo] = useState(false)

    const [oportunidad, setOportunidad] = useState("Comprar")

    const [tipo, setTipo] = useState("Tipus")

    const [busqueda, setBusqueda] = useState({
        operacion: "Compra",

    })


    const clickedOutside = (e) => {
        if (selectOportunidad.current.contains(e.target) || selectTipo.current.contains(e.target)) {
            // console.log('clicked inside')
            // console.log(e.target)
            return
        }

        // console.log('clicked outside')
        setShowOportunidad(false)
        setShowTipo(false)

    }

    const handleSelectOportunidad = (value) => {

        setBusqueda({
            ...busqueda,
            operacion: value
        })
        setOportunidad(value == "Lloguer" ? "Llogar" : "Comprar")
        setShowOportunidad(false)


    }

    const handleSelectTipo = (value) => {
        setBusqueda({
            ...busqueda,
            tipoInmueble: value
        })
        setTipo(value)

        setShowTipo(false)

    }

    const handleInputChange = (e) => {
        setBusqueda({
            ...busqueda,
            [e.target.name]:
                e.target.type == "checkbox" ?
                    e.target.checked :
                    e.target.value
        })

    }

    const buscar = () => {
        navigate('/immobles', { state: { busqueda } })
    }



    useEffect(() => {
        document.addEventListener('mousedown', clickedOutside)


        return () => {
            document.removeEventListener('mousedown', clickedOutside)
        }


    }, [selectOportunidad, selectTipo])


    return (
        <div className="buscador-container">
            <div id="buscador-opciones-operacion" ref={selectOportunidad} className="buscador-opcion-container-select" onClick={() => { setShowOportunidad(!showOportunidad) }}>
                <div className='buscador-opcion'>
                    {t(oportunidad)}
                    <img className="down-icon" src={downIcon} alt="icono abajo" />
                </div>
                <div className={showOportunidad == true ? "buscador-opcion-container" : "hidden"} >
                    {/* <div className="buscador-opcion-container"> */}
                    <div className='desplegable desplegable-operacion'>
                        <div className='desplegable-item' name="operacion" value="Compra" onClick={() => handleSelectOportunidad('Compra')}>{t("Comprar")}</div>

                        <div className='desplegable-item' name="operacion" value="Lloguer" onClick={() => handleSelectOportunidad('Lloguer')}>{t("Llogar")}</div>
                    </div>
                </div>


            </div>

            <div ref={selectTipo} className="buscador-opcion-container-select" onClick={(e) => { setShowTipo(!showTipo) }}>
                <div className="buscador-opcion">
                    {t(tipo)}
                    <img className="down-icon" src={downIcon} alt="icono abajo" />
                </div>
                <div value={tipo} className={showTipo ? 'buscador-opcion-container' : 'hidden'} >
                    <div className='desplegable'>
                        <div className='desplegable-item' value="Habitatge" onClick={() => handleSelectTipo("Habitatge")}>{t("Habitatge")}</div>

                        <div className='desplegable-item' value="Finca Rústica" onClick={() => handleSelectTipo("Finca Rústica")}>{t("Finca Rústica")}</div>

                        <div className='desplegable-item' value="Local Comercial" onClick={() => handleSelectTipo("Local Comercial")}>{t("Local Comercial")}</div>

                        <div className='desplegable-item' value="Nau Industrial" onClick={() => handleSelectTipo("Nau Industrial")}>{t("Nau Industrial")}</div>

                        <div className='desplegable-item' value="Terrenys i Solars" onClick={() => handleSelectTipo("Terrenys i Solars")}>{t("Terrenys i Solars")}</div>

                        <div className='desplegable-item' value="Pàrquing" onClick={() => handleSelectTipo("Pàrquing")}>{t("Pàrquing")}</div>

                        <div className='desplegable-item' value="Inversions" onClick={() => handleSelectTipo("Inversions")}>{t("Inversions")}</div>
                    </div>
                </div>


            </div>

            <input className="buscador-tf" placeholder={t("Població, ciutat...")} name="direccion" onChange={handleInputChange} />
            <button className='generic-btn btn-buscar' onClick={buscar} >
                <img className="lupa-icon" src={lupaIcon} alt="Icono buscar" />
                {t("Cercar")}
            </button>
        </div>

    );
};

export default Buscador;