import React from 'react';
import './MotivoCard.css'

function MotivoCard({ numero, contenido }) {
    return (
        <div className='motivo-card-container'>
            <div className='motivo-card-icono'>
                {numero}
            </div>
            <div className='motivo-card-content'>
                {contenido}
            </div>
        </div>
    );
}

export default MotivoCard;