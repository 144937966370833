import React from 'react';
import { useEffect } from 'react';
import './Blog.css'
import Entrada from './components/Entrada';

import axios from 'axios'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Blog(props) {

    const [articulos, setArticulos] = useState()

    const navigate = useNavigate();

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_URL_SERVER}/getPosts`).then(response => {

            // console.log('Respuesta...', response.data)
            setArticulos(response.data)
            // response.data.forEach((inmueble) => {
            //     inmueble.imgsData.forEach(img => {
            //         img.original = 'http://127.0.0.1:8080/' + img.original
            //     })

            //     //Se usa para ordenar las fotos según su posición asignada
            //     inmueble.imgsData.sort(function (a, b) {
            //         if (a.position > b.position) {
            //             return 1;
            //         }
            //         if (a.position < b.position) {
            //             return -1;
            //         }
            //         // a must be equal to b
            //         return 0;
            //     })
            // })






        })


    }, [])

    return (
        <div className='blog-container'>
            <h1 className='blog-h1'>Últimes novetats</h1>
            {articulos && articulos.map(articulo => (
                <Entrada
                    titulo={articulo.titulo}
                    fecha={new Date(articulo.fechaSubido).toLocaleDateString()}
                    resumen={articulo.resumen}
                    articulo={articulo.articulo}
                    imagen={articulo.imagen}
                    onClick={() => navigate(`/blog/${articulo._id}`, { state: articulo._id })} />
            )

            )}
            {/* <Entrada
                titulo={'IPC Agost 2022 i Índex de Garantia de Competitivitat'}
                fecha={'29/09/2022'}
                // imagen={'https://images.pexels.com/photos/2462015/pexels-photo-2462015.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
                resumen={'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rem expedita, laborum officiis fuga nemo tempora magnam hic et ipsam minima dignissimos quis, porro iste nobis dolor earum quidem eius, omnis excepturi quas! Aliquam qui nemo facere sapiente commodi consequatur, consequuntur tempore magni totam ab a, ducimus illum velit id quibusdam? ssssss  sssss ssssssss'}

            />

            <Entrada
                titulo={'IPC Agost 2022 i Índex de Garantia de ssss'}
                fecha={'29/09/2022'}
                imagen={'https://images.pexels.com/photos/4386366/pexels-photo-4386366.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
                resumen={'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rem expedita, laborum officiis fuga nemo tempora magnam hic et ipsam minima dignissimos quis, porro iste nobis dolor earum quidem eius, omnis excepturi quas! Aliquam qui nemo facere sapiente commodi consequatur, consequuntur tempore magni totam'}
            /> */}
        </div>
    );
}

export default Blog;