import React from 'react';
import './Card.css'
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import CurrencyFormat from 'react-currency-format';

//Assets
import BathIcon from '../../../assets/icons/bathroom-icon.svg'
import BedIcon from '../../../assets/icons/bedroom-icon.svg'
import M2Icon from '../../../assets/icons/m2-icon.svg'
import { t } from 'i18next';

function Card({ images, oportunidad, precioAnterior, precioActual, zona, hab, banyos, metros, onClick }) {



    return (
        <div className='card-container' >
            <div className='inmueble-imgs-wrapper'>
                <ImageGallery items={images}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    onClick={onClick}

                />
            </div>
            <div className='datos-inmueble-container' onClick={onClick}>
                <div className="inmueble-oportunidad-container"><h1>{t(oportunidad)}</h1></div>
                <div className="inmueble-precio">
                    <CurrencyFormat
                        displayType={'text'} value={precioAnterior} thousandSeparator={'.'} decimalSeparator={','}
                        renderText={value => <span className={precioAnterior && precioAnterior > precioActual ? "precio-anterior" : "hidden"}>{value}€</span>}
                    />
                    <CurrencyFormat
                        displayType={'text'} value={precioActual} thousandSeparator={'.'} decimalSeparator={','}
                        renderText={value => <h1 className='precio-actual'>{value} €</h1>}
                    />

                </div>
                <div className="inmueble-zona">{t("Zona de ")}{zona}</div>
                <div className="inmueble-specs-row">
                    <div className='inmueble-specs' style={{ display: hab != null ? 'flex' : 'none' }}><img className='inmueble-icons' id='bed-icon' src={BedIcon} alt="Icono habitaciones" />{hab}</div>
                    <div className='inmueble-specs' style={{ display: banyos != null ? 'flex' : 'none' }}><img className='inmueble-icons' src={BathIcon} alt="Icono baños" /> {banyos}</div>
                    <div className='inmueble-specs'><img className='inmueble-icons' src={M2Icon} alt="Icono superficie" />{metros}</div>

                </div>
            </div>

        </div>
    );
}

export default Card;