import { t } from 'i18next';
import React from 'react';
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'react-router-dom';

function Sidebar({ onOpen, onClose, isOpen, onClickequipo }) {



    return (
        <Menu
            isOpen={isOpen}
            disableOverlayClick={true}
            onOpen={onOpen}
            onClose={onClose}
            right={true}
            noOverlay



        >
            {/* <div className="modal-burger">
                <div className="nav-links-burger"> */}

            <a className='Link nav-link-burger' href='/servicis/'>{t("Presentació")}</a>

            <div className='line'></div>
            <Link className='Link nav-link-burger' to='/' > {t("Cercar immoble")}</Link >
            <div className='line'></div>
            <Link className='Link nav-link-burger' to='/vendre/' > {t("Vendre | Llogar")}</Link >
            <div className='line'></div>
            <Link className='Link nav-link-burger' to='/blog/'>{t("Blog")}</Link>
            <div className='line'></div>
            {/* </div>

            </div > */}
        </Menu >
    );
}

export default Sidebar;