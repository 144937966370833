import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from './components/Card';
import './Inmuebles.css'
import Field from '../AddInmueble/components/Field';
import { Collapse } from 'react-collapse';
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router-dom';

import ArrowIcon from '../../assets/icons/arrow-down-icon.png'
import ArrowLIcon from '../../assets/icons/arrow-left-icon.svg'
import ArrowRIcon from '../../assets/icons/arrow-right-icon.svg'

import axios from 'axios';
import { t } from 'i18next';


function Inmuebles(props) {

    let navigate = useNavigate()
    const location = useLocation();

    const [inmuebles, setInmuebles] = useState([])
    const [busqueda, setBusqueda] = useState({
        operacion: 'Compra'
    })

    //Estado que oculta el div según el inmueble que se haya seleccionado
    const [hideTipo, setHideTipo] = useState('')
    const [hidePrecio, setHidePrecio] = useState('Compra')

    //Referencias filtros
    const operacion = useRef()
    // const direccionForm = useRef()



    const tipoInmueble = useRef()
    const subtipoInmueble = useRef()

    const precioDesde = useRef()
    const precioHasta = useRef()
    const precioDesdeL = useRef()
    const precioHastaL = useRef()

    const superficieDesde = useRef()
    const superficieHasta = useRef()

    const habs = useRef()
    const banyos = useRef()



    const resetForm = () => {
        operacion.current.value = 'Compra';
        // if (document.getElementById('direccionForm').value != null) {
        document.getElementById('direccionForm').value = ''
        // }
        tipoInmueble.current.value = '';
        subtipoInmueble.current.value = '';
        setHideTipo('')
        document.getElementById('escaparateForm').checked = false

        precioDesde.current.value = ''
        precioHasta.current.value = ''
        precioDesdeL.current.value = ''
        precioHastaL.current.value = ''
        setHidePrecio('Compra')

        superficieDesde.current.value = ''
        superficieHasta.current.value = ''

        habs.current.value = ''
        document.getElementById('habsExactoForm').value = ''

        banyos.current.value = ''
        document.getElementById('banyosExactoForm').value = ''



        document.getElementById('amobladoForm').checked = false;
        document.getElementById('aireAcondicionadoForm').checked = false
        document.getElementById('calefaccionForm').checked = false
        document.getElementById('chimeneaForm').checked = false
        document.getElementById('parkingForm').checked = false
        document.getElementById('ascensorForm').checked = false
        document.getElementById('jardinForm').checked = false
        document.getElementById('balconForm').checked = false
        document.getElementById('terrazaForm').checked = false
        document.getElementById('cocinaOfficeForm').checked = false
        document.getElementById('lavaderoForm').checked = false
        document.getElementById('trasteroForm').checked = false

        document.getElementById('amobladoFormM').checked = false;
        document.getElementById('aireAcondicionadoFormM').checked = false
        document.getElementById('calefaccionFormM').checked = false
        document.getElementById('chimeneaFormM').checked = false
        document.getElementById('parkingFormM').checked = false
        document.getElementById('ascensorFormM').checked = false
        document.getElementById('jardinFormM').checked = false
        document.getElementById('balconFormM').checked = false
        document.getElementById('terrazaFormM').checked = false
        document.getElementById('cocinaOfficeFormM').checked = false
        document.getElementById('lavaderoFormM').checked = false
        document.getElementById('trasteroFormM').checked = false



        setBusqueda({
            operacion: 'Compra'
        })


    }

    useEffect(() => {

        // axios.get('http://localhost:3002/getInmuebles').then(response => {
        //     //  console.log(response.data)
        //     response.data.forEach((inmueble) => {
        //         inmueble.imgsData.forEach(img => {
        //             img.original = 'http://127.0.0.1:8080/' + img.original
        //         })
        //     })

        //     setInmuebles(response.data)

        // })

        axios.get(`${process.env.REACT_APP_URL_SERVER}/inmuebles/buscar`, { params: location.state.busqueda }).then(response => {

            response.data.forEach((inmueble) => {
                inmueble.imgsData.forEach(img => {
                    img.original = `${process.env.REACT_APP_DIR_SERVER}/${img.original}`


                })

                //Se usa para ordenar las fotos según su posición asignada
                inmueble.imgsData.sort(function (a, b) {
                    if (a.position > b.position) {
                        return 1;
                    }
                    if (a.position < b.position) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                })


            })


            setInmuebles(response.data)

            // openClose()
        })




    }, [])




    const [pageNumber, setpageNumber] = useState(0);
    const [inmbPerPage, setInmbPerPage] = useState(20);

    //Usado para arreglar bug cuando debe volver a la primera página
    const [remountComponent, setRemountComponent] = useState(0);

    const pagesVisited = pageNumber * inmbPerPage;
    let pageCount = Math.ceil(inmuebles.length / inmbPerPage);

    const changePage = ({ selected }) => {
        window.scrollTo({ top: 0, behavior: 'auto' })
        setpageNumber(selected);

    }


    const displayInmuebles = inmuebles.slice(pagesVisited, pagesVisited + inmbPerPage).map((inmueble) =>
    (<Card
        images={inmueble.imgsData}
        oportunidad={inmueble.operacion}
        precioAnterior={inmueble.precioAnterior}
        precioActual={inmueble.precio}
        zona={inmueble.zona}
        hab={inmueble.habs}
        banyos={inmueble.banyos}
        metros={inmueble.superficie}
        onClick={() => navigate(`/immoble/${inmueble._id}`, { state: inmueble._id })}
    />)
    )

    const pestanyaFiltros = useRef()
    const arrowDownIcon1 = useRef()
    const arrowDownIcon2 = useRef()

    const [isFilterOpened, setIsFilterOpened] = useState(false);

    function openClose() {
        setIsFilterOpened(!isFilterOpened)
        // if (isFilterOpened) {
        //     arrowDownIcon1.current.style.transform = 'rotateZ(0deg)'
        //     arrowDownIcon2.current.style.transform = 'rotateZ(0deg)'
        //     pestanyaFiltros.current.style.backgroundColor = 'var(--secondary)'
        // }
        // else {
        //     arrowDownIcon1.current.style.transform = 'rotateZ(180deg)'
        //     arrowDownIcon2.current.style.transform = 'rotateZ(180deg)'

        //     // pestanyaFiltros.current.style.backgroundColor = 'var(--primary)'
        // }


        // console.log(filtrosContainer.current.style)
        // filtrosContainer.current.style.animation = 'hideAndShow 1s forwards linear';
        // filtrosContainer.current

        // setTimeout(function () {
        //     filtrosContainer.current.style.animation = 'unset';
        // }, 1000);
        // filtrosContainer.current.style.height = 0 + 'px'
    }


    const handleInputChange = (e) => {
        setBusqueda({
            ...busqueda,
            [e.target.name]:
                e.target.type == "checkbox" ?
                    e.target.checked :
                    e.target.value
        })

        // if (e.target.name == 'Habitatge' || 'Local Comercial' || 'Terrenys i Solars') {
        //     setBusqueda({
        //         ...busqueda,
        //         'subtipoInmueble': '',
        //         'escaparate': ''
        //     })
        // }

    }

    const handleTipoChange = (e) => {
        handleInputChange(e)
        setHideTipo(e.target.value)

    }

    const handleOperacionPrecioChange = (e) => {
        handleInputChange(e)
        setHidePrecio(e.target.value)

    }

    const filtrar = () => {


        axios.get(`${process.env.REACT_APP_URL_SERVER}/inmuebles/buscar`, { params: busqueda }).then(response => {

            response.data.forEach((inmueble) => {
                inmueble.imgsData.forEach(img => {
                    img.original = `${process.env.REACT_APP_DIR_SERVER}/${img.original}`
                })

                //Se usa para ordenar las fotos según su posición asignada
                inmueble.imgsData.sort(function (a, b) {
                    if (a.position > b.position) {
                        return 1;
                    }
                    if (a.position < b.position) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                })


            })
            setInmuebles(response.data)
            resetForm()
            changePage({ selected: 0 })
            setRemountComponent(Math.random());

            // setBusqueda({
            //     operacion: 'Compra'
            // })
            openClose()
        })
    }


    return (
        <div key={remountComponent} className='inmuebles-container'>
            <Collapse isOpened={isFilterOpened} >

                <div className="filtros-container"
                // ref={filtrosContainer}
                >


                    <div className="filtros-subcontainer" >

                        <div className='filtros-section filtros-first-section' >
                            <div style={{ justifyContent: 'start', marginBottom: 0 }} className='filtros-selectores-container'>
                                <div>
                                    <h5 className='filtros-row-title'>{t("Operació")}</h5>
                                    <div style={{ minWidth: 'fit-content', marginRight: 15 }} className='filtros-selectores-container'>
                                        <select ref={operacion} style={{ width: 'fit-content' }} name="operacion" onChange={handleOperacionPrecioChange} id="">
                                            {/* <option value="">{t("Tipus")}</option> */}
                                            <option value="Compra">{t("Compra")}</option>
                                            <option value="Lloguer">{t("Lloguer")}</option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <h5 className='filtros-row-title'>{t("Direcció")}</h5>

                                    <Field
                                        placeholder={t("Ciutat, zona, direcció...")}
                                        width='215px'
                                        inputName='direccion'
                                        inputId='direccionForm'
                                        onChange={handleInputChange}
                                    />
                                </div>


                            </div>
                            <div className='filtros-row-container'>
                                <h5 className='filtros-row-title'>{t("Tipus d'immoble")}</h5>
                                <div style={{ minWidth: 240 }} className='filtros-selectores-container'>


                                    <select ref={tipoInmueble} style={{ width: 'fit-content' }} name="tipoInmueble" onChange={handleTipoChange} id="">
                                        <option value="">{t("Tipus")}</option>
                                        <option value="Habitatge">{t("Habitatge")}</option>
                                        <option value="Finca Rústica">{t("Finca Rústica")}</option>
                                        <option value="Local Comercial">{t("Local Comercial")}</option>
                                        <option value="Nau Industrial">{t("Nau Industrial")}</option>
                                        <option value="Terrenys i Solars">{t("Terrenys i Solars")}</option>
                                        <option value="Pàrquing">{t("Pàrquing")}</option>
                                        <option value="Inversions">{t("Inversions")}</option>
                                    </select>

                                    <div style={{ display: hideTipo != 'Habitatge' ? 'none' : '', marginTop: '-18px', marginLeft: '15px' }}>
                                        <h5>{t("Tipus d'habitatge")}</h5>
                                        <select ref={subtipoInmueble} style={{ width: 'fit-content' }} name="subtipoInmueble" onChange={handleInputChange} id="">
                                            <option value="">{t("Tipus")}</option>
                                            <option value="Pis">{t("Pis")}</option>
                                            <option value="Casa">{t("Casa")}</option>
                                            <option value="Casa Adossada">{t("Casa Adossada")}</option>
                                            <option value="Casa Aparellada">{t("Casa Aparellada")}</option>
                                            {/* <option value="Finca Rústica">Finca Rústica</option> */}
                                            <option value="Dúplex">{t("Dúplex")}</option>
                                            <option value="Àtic">{t("Àtic")}</option>
                                            <option value="Apartament">{t("Apartament")}</option>
                                            <option value="Triplex">{t("Triplex")}</option>
                                            <option value="Xalet">{t("Xalet")}</option>
                                            <option value="Estudi">{t("Estudi")}</option>
                                            <option value="Masia">{t("Masia")}</option>
                                        </select>
                                    </div>

                                    <div style={{ display: hideTipo != 'Terrenys i Solars' ? 'none' : '', marginTop: '-18px', marginLeft: '15px' }}>
                                        <h5>{t("Tipus de terreny/solar")}</h5>
                                        <select ref={subtipoInmueble} style={{ width: 'fit-content' }} name="subtipoInmueble" onChange={handleInputChange} id="">
                                            <option value="">{t("Tipus")}</option>
                                            <option value="Solar Urbà">{t("Solar Urbà")}</option>
                                            <option value="Finca Rústica">{t("Finca Rústica")}</option>
                                            <option value="Solar Industrial">{t("Solar Industrial")}</option>
                                            <option value="Terreny Residencia">{t("Terreny Residencial")}</option>
                                        </select>
                                    </div>

                                    <div style={{ display: hideTipo != 'Local Comercial' ? 'none' : '', marginTop: '-20px', marginLeft: '-15px' }}>
                                        <h5>{t("Aparador")}</h5>
                                        <input id='escaparateForm' style={{ marginTop: '8px' }} type="checkbox" name='escaparate' onChange={handleInputChange} />
                                    </div>

                                </div>
                            </div>
                            {/* PARA COMPRA */}
                            <div style={{ display: hidePrecio == 'Compra' ? '' : 'none' }} className='filtros-row-container'>
                                <h5 className='filtros-row-title'>{t("Preu")}</h5>
                                <div className='filtros-selectores-container'>


                                    <div style={{ display: 'flex', alignItems: 'center', columnGap: 3 }}>

                                        <select className='numero-formato' ref={precioDesde} style={{ width: 'fit-content' }} name="precioDesde" onChange={handleInputChange} id="">
                                            <option value="">{t("Des de")}</option>
                                            <option className='numero-formato' value="65000">65.000 €</option>
                                            <option className='numero-formato' value="100000">100.000 €</option>
                                            <option className='numero-formato' value="150000">150.000 €</option>
                                            <option className='numero-formato' value="200000">200.000 €</option>
                                            <option className='numero-formato' value="250000">250.000 €</option>
                                            <option className='numero-formato' value="300000">300.000 €</option>
                                            <option className='numero-formato' value="500000">500.000 €</option>
                                            <option className='numero-formato' value="700000">700.000 €</option>
                                            <option className='numero-formato' value="1000000">1.000.000 €</option>
                                            <option value=""></option>
                                        </select>
                                        -
                                        <select className='numero-formato' ref={precioHasta} style={{ width: 'fit-content' }} name="precioHasta" onChange={handleInputChange} id="">
                                            <option value="">{t("Fins")}</option>
                                            <option className='numero-formato' value="65000">65.000 €</option>
                                            <option className='numero-formato' value="100000">100.000 €</option>
                                            <option className='numero-formato' value="150000">150.000 €</option>
                                            <option className='numero-formato' value="200000">200.000 €</option>
                                            <option className='numero-formato' value="250000">250.000 €</option>
                                            <option className='numero-formato' value="300000">300.000 €</option>
                                            <option className='numero-formato' value="500000">500.000 €</option>
                                            <option className='numero-formato' value="700000">700.000 €</option>
                                            <option className='numero-formato' value="1000000">1.000.000 €</option>
                                            <option className='numero-formato' value="">+1.000.000 €</option>
                                        </select>
                                    </div>


                                </div>
                            </div>
                            {/* PARA ALQUILER */}
                            <div style={{ display: hidePrecio == 'Lloguer' ? '' : 'none' }} className='filtros-row-container'>
                                <h5 className='filtros-row-title'>{t("Preu / mes")}</h5>
                                <div className='filtros-selectores-container'>


                                    <div style={{ display: 'flex', alignItems: 'center', columnGap: 3 }}>

                                        <select className='numero-formato' ref={precioDesdeL} style={{ width: 'fit-content' }} name="precioDesde" onChange={handleInputChange} id="">
                                            <option value="">{t("Des de")}</option>
                                            <option className='numero-formato' value="100">100 €</option>
                                            <option className='numero-formato' value="200">200 €</option>
                                            <option className='numero-formato' value="300">300 €</option>
                                            <option className='numero-formato' value="400">400 €</option>
                                            <option className='numero-formato' value="500">500 €</option>
                                            <option className='numero-formato' value="600">600 €</option>
                                            <option className='numero-formato' value="700">700 €</option>
                                            <option className='numero-formato' value="800">800 €</option>
                                            <option className='numero-formato' value="900">900 €</option>
                                            <option className='numero-formato' value="1000">1000 €</option>
                                            <option className='numero-formato' value="1250">1250 €</option>
                                            <option className='numero-formato' value="1500">1500 €</option>
                                            <option value=""></option>
                                        </select>
                                        -
                                        <select className='numero-formato' ref={precioHastaL} style={{ width: 'fit-content' }} name="precioHasta" onChange={handleInputChange} id="">
                                            <option value="">{t("Fins")}</option>
                                            <option className='numero-formato' value="100">100 €</option>
                                            <option className='numero-formato' value="200">200 €</option>
                                            <option className='numero-formato' value="300">300 €</option>
                                            <option className='numero-formato' value="400">400 €</option>
                                            <option className='numero-formato' value="500">500 €</option>
                                            <option className='numero-formato' value="600">600 €</option>
                                            <option className='numero-formato' value="700">700 €</option>
                                            <option className='numero-formato' value="800">800 €</option>
                                            <option className='numero-formato' value="900">900 €</option>
                                            <option className='numero-formato' value="1000">1000 €</option>
                                            <option className='numero-formato' value="1250">1250 €</option>
                                            <option className='numero-formato' value="1500">1500 €</option>
                                            <option className='numero-formato' value="">+1500 €</option>

                                        </select>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div className='filtros-section filtros-second-section'>
                            <div className='filtros-row-container'>
                                <h5 className='filtros-row-title'>{t("Superfície")}</h5>
                                <div className='filtros-selectores-container'>

                                    <div style={{ display: 'flex', alignItems: 'center', columnGap: 3 }}>

                                        <select className='numero-formato' ref={superficieDesde} style={{ width: 'fit-content', padding: '8px' }} name="superficieDesde" onChange={handleInputChange} id="">
                                            <option value="">{t("Des de")}</option>
                                            <option className='numero-formato' value="25">25 m²</option>
                                            <option className='numero-formato' value="50">50 m²</option>
                                            <option className='numero-formato' value="75">75 m²</option>
                                            <option className='numero-formato' value="100">100 m²</option>
                                            <option className='numero-formato' value="1000">1000 m²</option>
                                            <option className='numero-formato' value="2000">2000 m²</option>
                                            <option className='numero-formato' value="3000">3000 m²</option>
                                            <option className='numero-formato' value="4000">4000 m²</option>
                                            <option className='numero-formato' value="5000">5000 m²</option>
                                            <option value=""></option>
                                        </select>
                                        -
                                        <select className='numero-formato' ref={superficieHasta} style={{ width: 'fit-content' }} name="superficieHasta" onChange={handleInputChange} id="">
                                            <option value="">{t("Fins")}</option>
                                            <option className='numero-formato' value="25">25 m²</option>
                                            <option className='numero-formato' value="50">50 m²</option>
                                            <option className='numero-formato' value="75">75 m²</option>
                                            <option className='numero-formato' value="100">100 m²</option>
                                            <option className='numero-formato' value="1000">1000 m²</option>
                                            <option className='numero-formato' value="2000">2000 m²</option>
                                            <option className='numero-formato' value="3000">3000 m²</option>
                                            <option className='numero-formato' value="4000">4000 m²</option>
                                            <option className='numero-formato' value="5000">5000 m²</option>
                                            <option className='numero-formato' value="">+5000 m²</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='filtros-row-container'>
                                <h5 className='filtros-row-title'>{t("Habitacions")}</h5>
                                <div style={{ minWidth: 280 }} className='filtros-selectores-container'>


                                    <select className='numero-formato' ref={habs} style={{ width: 'fit-content' }} name="habs" onChange={handleInputChange} id="">
                                        <option value="">{t("Num habs.")}</option>
                                        <option className='numero-formato' value="1">+1</option>
                                        <option className='numero-formato' value="2">+2</option>
                                        <option className='numero-formato' value="3">+3</option>
                                        <option className='numero-formato' value="4">+4</option>
                                        <option className='numero-formato' value="5">+5</option>
                                        <option className='numero-formato' value="6">+6</option>

                                    </select>
                                    o
                                    <Field
                                        placeholder={t("Nombre exacte...")}
                                        width='135px'
                                        inputName='habsExacto'
                                        inputType='number'
                                        inputId='habsExactoForm'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />


                                </div>
                            </div>
                            <div className='filtros-row-container '>
                                <h5 className='filtros-row-title'>{t("Banys")}</h5>
                                <div style={{ minWidth: 280 }} className='filtros-selectores-container'>


                                    <select className='numero-formato' ref={banyos} style={{ width: 'fit-content' }} name="banyos" onChange={handleInputChange} id="">
                                        <option className='numero-formato' value="">{t("Num banys")}</option>
                                        <option className='numero-formato' value="1">+1</option>
                                        <option className='numero-formato' value="2">+2</option>
                                        <option className='numero-formato' value="3">+3</option>
                                    </select>


                                    o
                                    <Field
                                        placeholder={t("Nombre exacte...")}
                                        width='135px'
                                        margin={'0'}
                                        padding={'0'}
                                        inputName='banyosExacto'
                                        inputId='banyosExactoForm'
                                        inputType='number'
                                        onChange={handleInputChange}
                                    />


                                </div>
                            </div>

                        </div>
                        <div className="filtros-section filtros-third-section">
                            <h5>{t("Característiques")}</h5>
                            <div className='filtros-caracts filtros-third-subsection filtros-caracts-desktop-view'>
                                <div>
                                    <Field
                                        inputType='checkbox'
                                        inputId='amobladoForm'
                                        inputName='amoblado'
                                        rowMode='true'
                                        label={t("Moblat")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='aireAcondicionadoForm'
                                        inputName='aireAcondicionado'
                                        rowMode='true'
                                        label={t("Aire Cond.")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='calefaccionForm'
                                        inputName='calefaccion'
                                        rowMode='true'
                                        label={t("Calefacció")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='chimeneaForm'
                                        inputName='chimenea'
                                        rowMode='true'
                                        label={t("Xemeneia")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />

                                </div>
                                <div>
                                    <Field
                                        inputType='checkbox'
                                        inputId='parkingForm'
                                        inputName='parking'
                                        rowMode='true'
                                        label={t("Pàrquing")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='ascensorForm'
                                        inputName='ascensor'
                                        rowMode='true'
                                        label={t("Ascensor")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='jardinForm'
                                        inputName='jardin'
                                        rowMode='true'
                                        label={t("Jardí")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='balconForm'
                                        inputName='balcon'
                                        rowMode='true'
                                        label={t("Balcó")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div>

                                    <Field
                                        inputType='checkbox'
                                        inputId='terrazaForm'
                                        inputName='terraza'
                                        rowMode='true'
                                        label={t("Terrassa")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='cocinaOfficeForm'
                                        inputName='cocinaOffice'
                                        rowMode='true'
                                        label={t("Cuina T. Office")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='lavaderoForm'
                                        inputName='lavadero'
                                        rowMode='true'
                                        label={t("Safareig")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='trasteroForm'
                                        inputName='trastero'
                                        rowMode='true'
                                        label={t("Traster")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            {/* Vista movil */}
                            <div className='filtros-caracts filtros-third-subsection filtros-caracts-movil-view'>
                                <div>
                                    <Field
                                        inputType='checkbox'
                                        inputId='amobladoFormM'
                                        inputName='amoblado'
                                        rowMode='true'
                                        label={t("Moblat")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}

                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='aireAcondicionadoFormM'
                                        inputName='aireAcondicionado'
                                        rowMode='true'
                                        label={t("Aire Cond.")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='calefaccionFormM'
                                        inputName='calefaccion'
                                        rowMode='true'
                                        label={t("Calefacció")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='chimeneaFormM'
                                        inputName='chimenea'
                                        rowMode='true'
                                        label={t("Xemeneia")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='parkingFormM'
                                        inputName='parking'
                                        rowMode='true'
                                        label={t("Pàrquing")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='ascensorFormM'
                                        inputName='ascensor'
                                        rowMode='true'
                                        label={t("Ascensor")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div>
                                    <Field
                                        inputType='checkbox'
                                        inputId='jardinFormM'
                                        inputName='jardin'
                                        rowMode='true'
                                        label={t("Jardí")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='balconFormM'
                                        inputName='balcon'
                                        rowMode='true'
                                        label={t("Balcó")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='terrazaFormM'
                                        inputName='terraza'
                                        rowMode='true'
                                        label={t("Terrassa")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='cocinaOfficeFormM'
                                        inputName='cocinaOffice'
                                        rowMode='true'
                                        label={t("Cuina T. Office")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='lavaderoFormM'
                                        inputName='lavadero'
                                        rowMode='true'
                                        label={t("Safareig")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                    <Field
                                        inputType='checkbox'
                                        inputId='trasteroFormM'
                                        inputName='trastero'
                                        rowMode='true'
                                        label={t("Traster")}
                                        width='130px'
                                        margin={'0'}
                                        padding={'0'}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="filtros-submit-btn">
                        <button onClick={filtrar} className='generic-btn filtros-btn'>{t("Filtrar")}</button>
                    </div>


                </div>
            </Collapse >
            <div className='pestanya-filtros' onClick={openClose} ref={pestanyaFiltros}>
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '100%' }}>
                    {/* <img className='arrow-down-icon' ref={arrowDownIcon1} src={ArrowIcon} alt="" /> */}
                    {t("CERCA AVANÇADA")}
                    {/* <img className='arrow-down-icon' ref={arrowDownIcon2} src={ArrowIcon} alt="" /> */}
                </span>

            </div>
            <h2 className='inmuebles-h2'>{inmuebles.length} {t("coincidències")}</h2>
            <div className="inmuebles-grid">
                {displayInmuebles}

            </div>
            <ReactPaginate

                previousLabel={"⬅"}
                nextLabel={"➡"}
                breakLabel={"..."}
                breakClassName={"breakBttn"}
                pageCount={pageCount}
                pageRangeDisplayed={2}
                marginPagesDisplayed={3}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                renderOnZeroPageCount={null}
            />
            <div className={inmuebles.length > 0 ? 'notDisplayed' : 'sin-inmuebles'}>{t("No s'han trobat immobles amb aquestes característiques...")}</div>
        </div >
    );
}

export default Inmuebles;