import React, { useEffect, useRef, useState } from 'react';
import './Slider.css'
import CurrencyFormat from 'react-currency-format';

//Modules
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Carousel, CarouselProps } from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

//Assets
import BathIcon from '../../../assets/icons/bathroom-icon.svg'
import BedIcon from '../../../assets/icons/bedroom-icon.svg'
import M2Icon from '../../../assets/icons/m2-icon.svg'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import logo from '../../../assets/img/logo-ga-recortado.jpeg'
import { t } from 'i18next';

gsap.registerPlugin(ScrollTrigger);

function Slider({ inmuebles }) {

    const [activeItem, setActiveItem] = useState(0)
    const [sliderAnimHelper, setSliderAnimHelper] = useState(500)

    let navigate = useNavigate()
    let indice = 0

    // const [inmuebles, setInmuebles] = useState();

    // useEffect(() => {
    //     setInmuebles(inmbs)
    // }, [])

    // useEffect(() => {
    //     axios.get('http://localhost:3002/inmuebles/buscar', { params: { destacado: true } }).then(response => {
    //         console.log(response.data)
    //         response.data.forEach((inmueble) => {
    //             inmueble.imgsData.forEach(img => {
    //                 img.original = 'http://127.0.0.1:8080/' + img.original
    //             })
    //         })
    //         setInmuebles(response.data)
    //         setActiveItem(0)
    //         // openClose()
    //     })
    // }, [])


    let images = [
        {
            id: 0,
            original: 'https://images.pexels.com/photos/1743227/pexels-photo-1743227.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
            precio: '100.000',
            zona: 'Zona del Parc Nou ff grgkgkghkkghkghergkekhkhr ghegkkjeghrg',
            hab: 4,
            banyos: 2,
            metros: 180,
            oportunidad: 'Compra'
        },
        {
            id: 1,
            original: 'https://images.pexels.com/photos/2635038/pexels-photo-2635038.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
            precio: '250.000',
            zona: 'Zona de les Tries',
            hab: 3,
            banyos: 1,
            metros: 100,
            oportunidad: 'Compra'
        },
        {
            id: 2,
            original: 'https://images.pexels.com/photos/3952034/pexels-photo-3952034.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
            precio: ' 400',
            zona: 'Zona del Morrot',
            hab: 5,
            banyos: 2,
            metros: 200,
            oportunidad: 'Lloguer'
        }
    ]

    let outstandingDetailsContainer = useRef(null)
    //let activeItem = useRef(null)

    //Reload page to top to avoid animation error.
    // window.onbeforeunload = function () {
    //     window.scrollTo(0, 0);
    // }

    // function renderAnimation() {
    //     gsap.from(outstandingDetailsContainer, {
    //         scrollTrigger: outstandingDetailsContainer,
    //         delay: 0.8,
    //         duration: 1.2,
    //         x: 1000,
    //         ease: "Back.easeOut"

    //     })
    // }

    // gsap.from(outstandingDetailsContainer, {
    //     scrollTrigger: outstandingDetailsContainer,
    //     delay: 0.8,
    //     duration: 1.2,
    //     x: 1000,
    //     ease: "Back.easeOut"

    // })


    // const colorAnim = gsap.timeline()

    // colorAnim.to('.outstanding-data', { visibility: 'hidden', ease: 'Power1.easeInOut', duration: 0.2 })
    //     .to('.cover-details-container', { height: '100%', duration: 0.5 })
    //     .to('.cover-details-container', { height: '0%', duration: 0.5 })
    //     .to('.outstanding-data', { visibility: 'visible', duration: 1 })

    // colorAnim.to('.cover-details-container', { height: '100%', ease: 'Power0.easeNone', duration: 0.4 })
    //     .to('.cover-details-container', { height: '0%', ease: 'Power0.easeNone', duration: 0.4 })

    // async function getCurrentItem(e) {
    //     if (sliderAnimHelper < 4000) {
    //         setSliderAnimHelper(4000)
    //     }

    //     console.log(e)
    //     // tl.play().then(() => setActiveItem(e)).then(() => tl.restart())
    //     colorAnim.play().then(colorAnim.reverse())

    //     setTimeout(() => {
    //         changeItem(e)
    //     }, 400)




    // }

    function getCurrentItem(e) {
        setTimeout(() => {
            setActiveItem(e)
            indice = e
        }, 300);


    }


    return (inmuebles ? (
        <div className='slider-container' >
            {inmuebles.length > 0 ? (<Carousel
                showStatus={false}
                autoPlay={true}
                interval={4500}
                infiniteLoop={true}
                showThumbs={false}
                transitionTime={600}
                showIndicators={false}


                // onChange={(index, item) => { getCurrentItem(index) }}
                onClickItem={(index, item) => navigate(`/immoble/${inmuebles[index]._id}`, { state: inmuebles[index]._id })}

            >
                {inmuebles.map(inmueble => (
                    <>

                        <img className='fill' src={inmueble.imgsData[0] ? inmueble.imgsData[0].original : logo} alt="primera imagen inmueble" />
                        <CurrencyFormat
                            displayType={'text'} value={inmueble.precio} thousandSeparator={'.'} decimalSeparator={','}
                            renderText={value => <h1 className='outstanding-price outstanding-data'>{value} €</h1>}
                        />

                        <div
                            //  ref={(e) => (outstandingDetailsContainer = e)}
                            className='outstanding-details-container'>
                            <div className='outstanding-opportunity'><h1>{t(inmueble.operacion)}</h1></div>
                            <div className='outstanding-title outstanding-data'>{t("Zona")} {inmueble.zona}</div>

                            <div className='main-specs-row outstanding-data'>
                                <div className='outstanding-main-specs outstanding-data'><img className='outstanding-icons' id='bed-icon' src={BedIcon} alt="Icono habitaciones" />{inmueble.habs}</div>
                                <div className='outstanding-main-specs outstanding-data'><img className='outstanding-icons' src={BathIcon} alt="Icono Baños" /> {inmueble.banyos}</div>
                                <div className='outstanding-main-specs outstanding-data'><img className='outstanding-icons' src={M2Icon} alt="Icono superficie" /> {inmueble.superficie}</div>
                            </div>

                            {/* <div className='cover-details-container'>
                                <img className='cover-details-logo' src="https://www.apigirona.com/clients/api/api/agencia/images/965_details.png" alt="" />
                            </div> */}
                        </div>

                    </>


                ))}

            </Carousel>)
                : <div className='slider-vacio-container'>
                    <img style={{ height: '120px' }} src={logo} alt="Icono Garrotxa Activa" />
                    <strong>{t("Pròximament hi haurà immobles destacats...")}</strong></div>
            }


        </div >
    ) : (<>{t("Esperi")}...</>)
    );


};

export default Slider;