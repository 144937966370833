import React from 'react';
import './CaractCard.css'

function CaractCard({ titulo, content, icon }) {
    return (
        <div className='caract-card-container'>
            <img className='caract-card-icon' src={icon} alt="Icono tarjeta" />
            <h3 className='caract-card-title'>{titulo}</h3>
            <p className='caract-card-content'>{content}</p>
        </div>
    );
}

export default CaractCard;