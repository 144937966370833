import React from 'react';
import './Field.css';

const Field = ({ key, refe, id, label, inputId, inputType, inputName, textareaName, value, placeholder, onChange, min, max, step, textarea, onClick, rowMode, width, fullWidth, fieldWidth, hidden, margin, padding, checked, defaultValue, defaultChecked, required }) => {
    return (
        <div className={rowMode ? 'add-inmueble-field-container-row' : 'add-inmueble-field-container'} id={id} style={{ width: fullWidth ? '100%' : width ? width : '', display: hidden == true ? 'none' : '', margin: margin ? margin : '', padding: padding ? padding : '' }}>
            <label htmlFor={inputName} style={{ marginBottom: '5px' }}>
                {label}
            </label>
            {(inputType !== 'textarea') && (<input key={key} ref={refe} id={inputId} type={inputType} name={inputName} placeholder={placeholder} onChange={onChange} min={min} max={max} step={step} required={required} checked={checked} defaultValue={defaultValue} defaultChecked={defaultChecked} value={value} onClick={onClick} style={{ width: fieldWidth ? `${fieldWidth}` : inputType != 'checkbox' ? '100%' : '25px' }} />)}
            {(inputType === 'textarea') && (<textarea key={key} ref={refe} name={inputName} placeholder={placeholder} defaultValue={defaultValue} value={value} onChange={onChange} style={{ width: fieldWidth ? `${fieldWidth}` : '100%', height: '8rem' }}  >{textarea}</textarea>)}
        </div>
    );
};

export default Field;