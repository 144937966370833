import React, { useRef } from 'react';
import emailjs from '@emailjs/browser'
import './FormContacto.css'
import { t } from 'i18next';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import okIcon from '../../../assets/icons/ok-icon.svg'

function FormContacto(
    { refImmueble, operacion, zonaInmueble, precioInmueble, linkInmueble }
) {

    const form = useRef();

    const [captchaStatus, setCaptchaStatus] = useState(0)

    const checkPrivacidad = useRef(false);

    const checkInfo = useRef(false);


    const [mostrarError, setMostrarError] = useState(false);

    const [estaEnviado, setEstaEnviado] = useState(false)

    const sendEmail = (e) => {
        e.preventDefault();
        if (checkPrivacidad.current.checked && checkInfo.current.checked) {
            setMostrarError(false)
            emailjs.sendForm('ga.immobles', 'template_cs2utne', form.current, 'Q0hRQPyLOSAdCMbqv')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
            setEstaEnviado(true)
        }
        else {
            setMostrarError(true)
        }

    };

    //cuerpo de la plantilla por si se pierde
    // Dades de l'immoble d'interes:

    // Ref: {{refInmueble}}

    // Operació: {{operacion}}

    // Zona: {{zonaInmueble}}

    // Preu: {{precioInmueble}}

    // Link: {{link}}



    // Dades del contace interessat en l'immoble

    // Nom: {{nombre}}

    // Email: {{email}}

    // Telèfon: {{telefono}}

    // Missatge:

    //  "{{mensaje}}"

    function captchaDone() {
        // console.log('Captcha hecho')
        setCaptchaStatus(1)
    }

    return (
        <form id='formulario-contacto-id' ref={form} onSubmit={sendEmail} className='form-contacto-container'>
            {estaEnviado == false ?
                <div>
                    <h3 className='detalles-contacto-form-title'>{t("T'interessa aquest immoble?")}</h3>
                    <p className='detalles-contacto-form-subtitle'> {t("Ens posarem en contacte amb tu per resoldre qualsevol dubte o programar una visita a l'immoble.")} </p>
                    <input name='nombre' className='detalles-contacto-form-tf' type="text" placeholder={t('Nom')} />
                    <input name='email' className='detalles-contacto-form-tf' type="text" placeholder='Email' />
                    <input name='telefono' className='detalles-contacto-form-tf' type="text" placeholder={t("Telèfon")} />
                    <textarea name='mensaje' className='detalles-contacto-form-txtarea' placeholder={t("Breu dubte o comentari...")}></textarea>
                    <div className='detalles-politica-priv-container' style={{ marginBottom: '5px' }} ><input name='check' type="checkbox" ref={checkPrivacidad} id="form-contacto-checkbox" /><span>{t("He llegit i accepto la ")} <a className='link-politica-priv' href="/politica-privacitat/" target='_blank'>{t("Política de Privacitat")}</a> </span> </div>
                    <div className='detalles-politica-priv-container' style={{ marginBottom: '15px' }} ><input name='check' type="checkbox" ref={checkInfo} id="form-contacto-checkbox" /><span>{t("Accepto rebre informació sobre activitats, serveis i productes de GARROTXA ACTIVA GRUP* ")}  </span> </div>
                    {/* <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <ReCAPTCHA

                    sitekey='6LdMDyQjAAAAAEHuT8AxeHI3XNwjz9h6sfAVoAeN'
                    onChange={captchaDone}
                />
            </div> */}
                    <p style={{ marginTop: '10px', fontWeight: '600', marginInline: '12px', fontSize: '12px', color: '#ac0025e7', display: mostrarError == true ? 'block' : 'none' }}>{t("*Ha d'acceptar els camps de privacitat per a continuar.")}</p>
                    <input style={{ marginTop: '15px' }} type='submit' className='generic-btn detalles-contacto-form-btn' value={t('Contactar')} />
                    <div style={{ marginTop: '15px', fontSize: '10px' }}> {t("*Les societats que conformen GARROTXA ACTIVA GRUP que presten serveis d'assessoria fiscal, laboral, comptable i d'administració de finques són: GARROTXA ACTIVA SL, RIPOLLÉS ASSESSORS I ADMINISTRADORS SL i ENRIC PLANA SERVEIS A EMPRESES SL.")}</div>

                    <div style={{ fontSize: '9px', marginBottom: '10px', marginTop: '5px' }}>{t("Informació bàsica sobre el tractament de dades (LO 3/2018 i Reglament (UE) 2016/679 ]RGPD])")}
                        {t("Responsable del tractament: GARROTXA ACTIVA, S.L.")}
                        {t("Finalitat: Oferir i gestionar serveis d'assessorament fiscal, laboral, comptable, immobiliaris i d'administració de finques.")}
                        {t("Drets:  Pot exercir els drets previstos als productes 15 a 22 del RGPD, que es recullen a  la nostra política de privacitat.")}
                    </div>


                </div>
                :
                <div className='form-contacto-ok' style={{ height: '650px' }}>
                    <img style={{ width: '150px' }} src={okIcon} alt="Icono ok" />
                    <br />
                    <h2 style={{ color: 'var(--primary)' }}>{t("Enviat correctament!")}</h2>

                </div>
            }


            <input name='refInmueble' style={{ display: 'none' }} type="text" value={refImmueble} />
            <input name='operacion' style={{ display: 'none' }} type="text" value={operacion} />
            <input name='zonaInmueble' style={{ display: 'none' }} type="text" value={zonaInmueble} />
            <input name='precioInmueble' style={{ display: 'none' }} type="text" value={precioInmueble} />
            <input name='linkInmueble' style={{ display: 'none' }} type="text" value={linkInmueble} />



        </form>
    );
}

export default FormContacto;