import React from 'react';
import './Entrada.css'
import defaultBg from '../../../assets/img/defaultBg.png'
import { useEffect } from 'react';

function Entrada({ titulo, fecha, resumen, imagen, onClick }) {

    useEffect(() => {
        // console.log('imagen', imagen)
    }, [])

    return (
        <div className='entrada-container' >
            <div className='vista-grande' onClick={onClick}>
                <div className='entrada-img-wrapper'>
                    <div className='franja'>
                        <div className='banda-azul'></div>
                        <div className='banda-celeste'></div>
                        <div className='banda-azul' ></div>
                    </div>

                    <img className='entrada-img'
                        src={imagen ? `${process.env.REACT_APP_DIR_SERVER}/${imagen.url}` : defaultBg}
                        alt="imagen entrada blog" />
                </div>

                <div className='entrada-content'>
                    <h2 className='entrada-titulo' style={{ width: '100%', lineHeight: '27px', fontSize: '23px', color: 'var(--primary)' }}>{titulo}</h2>
                    <h5 className='entrada-fecha' style={{ marginTop: '5px' }}>{fecha}</h5>
                    <br />
                    <p className='resumen-container'>{resumen}</p>
                </div>

            </div>


            <div className='vista-pequeña' onClick={onClick}>
                <div className='vp-entrada-img-wrapper'>
                    <div className='franja'>
                        <div className='banda-azul'></div>
                        <div className='banda-celeste'></div>
                        <div className='banda-azul' ></div>
                    </div>

                    <img className='vp-entrada-img'
                        src={imagen ? `${process.env.REACT_APP_DIR_SERVER}/${imagen.url}` : defaultBg}

                        alt="imagen entrada blog" />
                </div>

                <div className='vp-entrada-content'>
                    <h2 className='vp-entrada-titulo'>{titulo}</h2>
                    <h5 className='vp-entrada-fecha' >{fecha}</h5>
                    <br />
                    <p className='vp-resumen-container'>{resumen}</p>
                </div>
            </div>

        </div>
    );
}

export default Entrada;