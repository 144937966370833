import React, { useRef } from 'react';
import './VenderPage.css'
import fsBg from '../../assets/img/vender-first-section-bg.jpg'
import fotoOlot from '../../assets/img/fondoHome3.jpg'
import Field from '../AddInmueble/components/Field.jsx'
import { t } from 'i18next';
import MotivoCard from './components/MotivoCard';
import CaractCard from './components/CaractCard';
import emailjs from '@emailjs/browser';
import { useState } from 'react';

import { Fade, Slide } from 'react-reveal';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import programa from '../../assets/icons/programa-icon.png'

import icono from '../../assets/icons/edificios-icon.png'

import asistente from '../../assets/icons/asistente-icon.png'


import okIcon from '../../assets/icons/ok-icon.svg'



function VenderPage(props) {

    const form = useRef();

    const checkPrivacidad = useRef(false);

    const checkInfo = useRef(false);

    const [mostrarError, setMostrarError] = useState(false);

    const [estaEnviado, setEstaEnviado] = useState(false)

    const [captchaStatus, setCaptchaStatus] = useState(0)


    const sendEmail = (e) => {
        e.preventDefault();
        if (checkPrivacidad.current.checked && checkInfo.current.checked) {
            setMostrarError(false)
            emailjs.sendForm('ga.immobles', 'template_63x43r9', form.current, 'Q0hRQPyLOSAdCMbqv')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });

            setEstaEnviado(true)
        }
        else {
            setMostrarError(true)
        }


        // console.log(form)

    };

    function goToSection() {

        // try {


        const section = document.querySelector('#vender-section-id')
        section.scrollIntoView({ behavior: 'smooth', block: 'center' })

        // }
        // catch (e) {


        //     const section = document.querySelector('#vender-section-id')
        //     section.scrollIntoView({ behavior: 'smooth', block: 'center' })



        // }


    }

    function captchaDone() {

        setCaptchaStatus(1)
    }

    return (
        <div className='vender-container'>

            <div id="vender-section-id" className='vender-first-section'>
                <h1 className='vender-first-section-title'>{t("Està decidit a vendre o llogar el seu immoble?")}</h1>
                <p className='vender-first-section-subtitle'>{t("Som conscients que vendre la seva propietat és una decisió important. Volem posar-li les coses fàcils i l'acompanyarem en tot el procés per tal de donar-li tota la seguretat i garanties. Tenim més de 10.000 clients potencials i estadístiques actualitzades. Demani'ns una valoració gratuïta.")}</p>
                <div className='vender-form-container'>
                    <div className='img-form-left-wrapper'>
                        <img className='img-form-left' src={fotoOlot} alt="Imagen olot desde el cielo" />
                    </div>
                    {estaEnviado == false ?
                        <div className='vender-form-wrapper'>
                            <h2 className='vender-form-title'>{t("Demani una valoració gratuïta")}</h2>
                            <form ref={form} onSubmit={sendEmail}>
                                <Field
                                    inputName='direccion'
                                    inputType='text'
                                    placeholder={t("Direcció de l'immoble a vendre...")}
                                    fullWidth
                                />
                                <Field
                                    inputName='nombre'
                                    inputType='text'
                                    placeholder={t("Nom i cognoms")}

                                />
                                <Field
                                    inputName='email'
                                    inputType='email'
                                    placeholder={t("Correu electrònic")}

                                />
                                <Field
                                    inputName='telefono'
                                    inputType='number'
                                    placeholder={t("Telèfon")}

                                />
                                <div style={{ marginBottom: '5px' }} className='detalles-politica-priv-container'><input name='check' ref={checkPrivacidad} type="checkbox" id="form-contacto-checkbox" /><span>{t("He llegit i accepto la ")} <a className='link-politica-priv' href="/politica-privacitat/" target='_blank'>{t("Política de Privacitat")}</a> </span> </div>
                                <div className='detalles-politica-priv-container' style={{ marginBottom: '15px' }} ><input name='check' type="checkbox" ref={checkInfo} id="form-contacto-checkbox" /><span>{t("Accepto rebre informació sobre activitats, serveis i productes de GARROTXA ACTIVA GRUP* ")}  </span> </div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', }}>
                                    {/* <div className='captcha'>
                                    <ReCAPTCHA

                                        sitekey='6LdMDyQjAAAAAEHuT8AxeHI3XNwjz9h6sfAVoAeN'
                                        onChange={captchaDone}
                                    />
                                </div> */}

                                </div>


                                <p style={{ marginTop: '5px', marginBottom: '10px', fontWeight: '600', marginInline: '12px', fontSize: '13px', color: '#ac0025e7', display: mostrarError == true ? 'block' : 'none' }}>{t("*Ha d'acceptar els camps de privacitat per a continuar.")}</p>
                                <button style={{ marginBottom: '15px' }} type='submit' className='generic-btn btn-solicitar'>{t("Sol·licitar contacte")}</button>
                                <div style={{ fontSize: '10px', marginBottom: '5px' }}> {t("*Les societats que conformen GARROTXA ACTIVA GRUP que presten serveis d'assessoria fiscal, laboral, comptable i d'administració de finques són: GARROTXA ACTIVA SL, RIPOLLÉS ASSESSORS I ADMINISTRADORS SL i ENRIC PLANA SERVEIS A EMPRESES SL.")}</div>

                                <div style={{ fontSize: '9px' }}>{t("Informació bàsica sobre el tractament de dades (LO 3/2018 i Reglament (UE) 2016/679 ]RGPD])")}
                                    {t("Responsable del tractament: GARROTXA ACTIVA, S.L.")}
                                    {t("Finalitat: Oferir i gestionar serveis d'assessorament fiscal, laboral, comptable, immobiliaris i d'administració de finques.")}
                                    {t("Drets:  Pot exercir els drets previstos als productes 15 a 22 del RGPD, que es recullen a  la nostra política de privacitat.")}
                                </div>


                            </form>


                        </div>
                        :
                        <div className='form-contacto-ok' style={{ width: '100%' }} >
                            <img style={{ width: '150px' }} src={okIcon} alt="Icono ok" />
                            <br />
                            <h2 style={{ color: 'var(--primary)' }}>{t("Enviat correctament!")}</h2>

                        </div>
                    }
                </div>
            </div>

            <div className='vender-second-section'>
                <h1 className='vender-first-section-title'>{t("Perquè nosaltres?")}</h1>
                <div className='motivo-card-grid'>
                    <Fade delay={500} duration={1200}>
                        <MotivoCard
                            numero='1'
                            contenido={t('Gràcies als mes de 25 anys administrant comunitats de propietaris tenim mes de 10.000 clients potencials.')}
                        />
                    </Fade>
                    <Fade delay={800} duration={1200}>
                        <MotivoCard
                            numero='2'
                            contenido={t("Gaudi d'un tracte proper i personal. Els nostres agents escoltaran les seves necessitats particulars per assessorar-lo de la millor manera.")}
                        />
                    </Fade>
                    <Fade delay={1100} duration={1200}>
                        <MotivoCard
                            numero='3'
                            contenido={t("L'ajudem a valorar el seu immoble de forma gratuïta a través de la nostre base de dades i estadístiques.")}
                        />
                    </Fade>
                    <Fade delay={1400} duration={1200}>
                        <MotivoCard
                            numero='4'
                            contenido={t("Màxima visibilitat en els portals immobiliaris mes utilitzats, i feedback de les estadístiques dels anuncis.")}
                        />
                    </Fade>
                    <Fade delay={1700} duration={1200}>
                        <MotivoCard
                            numero='5'
                            contenido={t("Ajudem als nostres clients compradors a trobar el finançament necessari per a realitzar l'operació.")}
                        />
                    </Fade>
                    <Fade delay={2000} duration={1200}>
                        <MotivoCard
                            numero='6'
                            contenido={t("Disposem de departament fiscal qualificat amb experiéncia de més de 25 anys en el ram empresarial i de particulars.")}
                        />
                    </Fade>
                    <Fade delay={2300} duration={1200}>
                        <MotivoCard
                            numero='7'
                            contenido={t("Treballem a èxit, només cobrem si venem.")}
                        />
                    </Fade>
                </div>

            </div>
            <div className="vender-third-section">
                <div className='vender-third-subsection-1'>
                    <Fade left delay={500} duration={1300}>
                        <CaractCard
                            icon={programa}
                            titulo={t("El mètode Garrotxa Activa")}
                            content={t("Disposem de tecnologia pròpia especialitzada per enllaçar el producte que tenim amb el que demanen els nostres clients.")}
                        />
                    </Fade>
                    <Fade right delay={500} duration={1300}>
                        <CaractCard
                            icon={asistente}
                            titulo={t("Acompanyament i assistència")}
                            content={t("Rebrà assessorament profesional i seguiment constant de les estadístiques dels anuncis i del feedback dels clients.")}
                        />
                    </Fade>
                    <Fade left delay={500} duration={1300}>
                        <CaractCard
                            icon={icono}
                            titulo={t("Cartera de lloguers")}
                            content={t("Administrem el seu lloguer, gestionem les incidències, reclamem activament els saldos pendents i mediem en cas de conflicte.")}
                        />
                    </Fade>
                </div>
                <div className='vender-third-subsection-2'>
                    <h1 className='vender-third-subsection-2-title'>{t("Pla estratègic fet a mida")}</h1>
                    <p className='vender-third-subsection-2-content'>{t("Tant si vol vendre com llogar el seu immoble, dissenyarem un pla estratègic de venda o lloguer adequat a les seves necessitats i preferències per tal d'aconseguir els millors resultats.")}
                        {t("Truqui'ns sense compromís o demani cita amb un dels nostres agents i l'explicarem amb més detall el nostre mètode.")}</p>
                    <button className='generic-btn vender-third-subsection-2-btn' onClick={goToSection} >{t("Sol·licitar contacte")}</button>
                </div>
            </div>
        </div>
    );
}

export default VenderPage;