import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Login from './pages/Login/Login';
import Cookies from 'universal-cookie';
const cookies = new Cookies();



const ProtectedRoutes = () => {

    return (
        cookies.get("ga_tk") ? <Outlet /> : <Navigate to="/login" />
    );
};

export default ProtectedRoutes;