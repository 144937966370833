import React from 'react';
import './Menu.css'
import { Navigate, useNavigate } from 'react-router-dom';

function Menu(props) {

    const navigate = useNavigate()

    return (
        <div className='menu-container'>
            <h1>Administració d'immobles</h1>
            <div className='menu-opciones-wrapper'>
                <div className='menu-opcion-container' onClick={() => navigate('/dashboard')}>
                    <h2>Dashboard</h2>
                </div>
                <div className='menu-opcion-container' onClick={() => navigate('/pujar-immoble')}>
                    <h2>Pujar immoble</h2>
                </div>

            </div>
            <h1>Blog</h1>
            <div className='menu-opciones-wrapper'>
                <div className='menu-opcion-container'>
                    <h2> Pujar Publicació</h2>
                </div>

            </div>

        </div>
    );
}

export default Menu;