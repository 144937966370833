import React from 'react';
import './Login.css';
import logo from '../../assets/img/logo-ga-recortado.jpeg'
import Field from '../AddInmueble/components/Field';
import ojo from '../../assets/icons/ojo-icon.png'
import ojoCerrado from '../../assets/icons/ojo-cerrado-icon.png'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { t } from 'i18next';


function Login(props) {

    //Aspectos navegacion
    const cookies = new Cookies();
    let navigate = useNavigate()

    //Estados
    const [credenciales, setCredenciales] = useState()
    const [showWrongPass, setShowWrongPass] = useState(false)
    const [passVisible, setPassVisible] = useState('password');
    const [iconPass, setIconPass] = useState(ojo)


    //Funciones
    const handlePasswordVisibility = () => {
        if (passVisible == 'password') {
            setPassVisible('text');
            setIconPass(ojoCerrado)
        }
        else {
            setPassVisible('password');
            setIconPass(ojo)
        }

    }

    const handleInputChange = (e) => {
        setCredenciales({
            ...credenciales,
            [e.target.name]:
                e.target.value
        })
        // console.log(credenciales)
    }

    const login = () => {

        axios.post(`${process.env.REACT_APP_URL_SERVER}/login`, credenciales)
            .then(response => {
                // console.log(response)

                if (response.data.status == 1) {
                    setShowWrongPass(true)
                }
                else {
                    cookies.set("ga_tk", response.data.token, {
                        path: "/",
                    })
                    // window.location.href = "/home"
                    navigate(`/home/`)
                }

            })
            .catch(err => {
                console.log(err)
            })

        // navigate(`/dashboard/`)

    }

    return (
        <div className='login-container'>
            <img className='login-img' src={logo} alt="Icono Garrotxa Activa" />
            <Field
                inputName='username'
                label='Usuari'
                onChange={handleInputChange}
            />
            <div style={{ marginLeft: '45px', display: 'flex', }}>
                <Field
                    label='Contrasenya'
                    inputName='password'
                    inputType={passVisible}
                    onChange={handleInputChange}
                />
                <button onClick={handlePasswordVisibility} className='generic-btn pass-visible-btn'> <img className='login-visible-icon' src={iconPass} alt="Icono ver password" /></button>
            </div>

            <button onClick={login} className='generic-btn'>{t("Accedir")}</button>
            {showWrongPass && (<h4 style={{ marginTop: '15px', color: 'red', fontWeight: 800 }}>{t("Credencials incorrectes...")}</h4>)}

        </div>
    );
}

export default Login;