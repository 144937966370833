import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Articulo.css'

import defaultBg from '../../assets/img/defaultBg.png'

import axios from 'axios';
import atrasIcon from '../../assets/icons/atras-icon.svg'
import { useParams } from 'react-router-dom';



function Articulo({ titulo, fecha, imagen, articulos }) {

    let navigate = useNavigate()
    const { id } = useParams();
    const location = useLocation();
    const { pathname } = useLocation();
    // let inmueble = []
    const [articulo, setArticulo] = useState([]);

    useEffect(() => {




        axios.get(`${process.env.REACT_APP_URL_SERVER}/blog/${id}`, { params: { _id: id } }).then(async response => {
            // console.log(response.data[0])


            setArticulo(response.data[0])


            // console.log(pathname)
            // //console.log('estoy recibiendo datos')
            // setInmueble(response.data[0])
        }).catch(() => {
            console.log('Error')
        })

    }, [])


    return (
        <div className='articulo-container'>
            <button className='generic-btn' style={{ paddingInline: '15px', marginTop: '-57px', marginBottom: '57px' }} onClick={() => navigate(`/blog/`)}><img height={20} src={atrasIcon} alt="icono volver atrás" /></button>
            {/* <h1 className='articulo-titulo'>La suspensió de l’obligació de convocar juntes de comunitats de propietaris ha quedat ampliada fins a final de 2021</h1>
            <h5 className='articulo-fecha'>29/09/2022</h5>
            <div className='articulo-img-wrapper'>
                <img className='articulo-img' src="https://images.pexels.com/photos/162539/architecture-building-amsterdam-blue-sky-162539.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
            </div>
            <p> Lamentablement, la desaparició de la Covid-19 encara no ha arribat, i en previsió i per facilitar les tasques dels administradors de comunitats de propietaris, el Decret Llei 52/2020, de 22 de desembre, que modifica el Decret Llei 10/2020, de 27 de març, pel qual s’estableixen noves mesures extraordinàries per fer front a l’impacte sanitari, econòmic i social de la COVID-19, ha modificat el termini de compliment de l’obligació de convocar o celebrar juntes de propietaris en les comunitats subjectes a règim de propietat horitzontal. Aquest termini ha quedat suspès fins al 31 de desembre de 2021, ja que fins ara restava en suspens fins el 30 d’abril d’aquest any.</p>
            <br />
            <p>Això comporta que els òrgans de les persones jurídiques de dret privat subjectes a les disposicions de dret civil català es poden reunir i adoptar acords mitjançant videoconferència o altres mitjans de comunicació anàlogs, encara que els estatuts no ho contemplin, però d’acord amb l’article 312.5.2 del Codi Civil Català, que diu: “Els estatuts de les persones jurídiques poden establir que elsnòrgans es puguin reunir per mitjà de videoconferència o d’altres mitjans de comunicació, sempre que resti garantida la identificació dels assistents, la continuïtat de la comunicació, la possibilitat d’intervenir en les deliberacions i l’emissió del vot. En aquest cas, s’entén que la reunió se celebra al lloc on és la persona que la presideix”.</p>
            <br />
            <p>L’obligació de convocar i celebrar les juntes de propietaris en les comunitats subjectes a règim de propietat horitzontal resta suspesa fins el 31 de desembre 2021, sens perjudici de la possibilitat de cada comunitat de convocar i celebrar la junta de propietaris dins d’aquest termini, d’acord amb les seves circumstàncies i les mesures de seguretat que en cada moment siguin aplicables, a iniciativa de la Presidència o si ho demana almenys un vint per cent de les persones propietàries amb dret a vot, que representin el mateix percentatge de quotes. La celebració de la junta també es pot dur a terme a través dels mitjans que estableix l’article 312-5.2 del Codi civil de Catalunya.</p>
            <br />
            <p>El darrer pressupost anual aprovat s’entén prorrogat fins a la celebració de la junta ordinària, en la qual també s’ha de procedir a l’aprovació dels comptes anteriors i a la renovació dels càrrecs, d’acord amb el que estableix l’article 553-15 del Codi civil de Catalunya.</p>
            <br />
            <p>Mentre no es convoqui i celebri la junta ordinària, es poden prendre acords sense reunió a instància de la persona que la presideix, si es compleixen els requisits que estableix l’article 312-7 del Codi civil de Catalunya.</p> */}
            <h1 className='articulo-titulo'>{articulo.titulo}</h1>
            <h5 className='articulo-fecha'>{new Date(articulo.fechaSubido).toLocaleDateString()}</h5>
            <div className='articulo-img-wrapper'>
                <img className='articulo-img' src={articulo.imagen ? `${process.env.REACT_APP_DIR_SERVER}/${articulo.imagen.url}` : defaultBg} alt="Imagen artículo" />
            </div>
            <div style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}>{articulo.articulo}</div>
        </div>
    );
}

export default Articulo;