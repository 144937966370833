import React, { useEffect, useState, useCallback, Fragment, useRef } from 'react';
import './EditInmueble.css';
import Checkbox from '../AddInmueble/components/Checkbox';
import Field from '../AddInmueble/components/Field';
import Select from '../AddInmueble/components/Select';
import axios from 'axios';

import { useDropzone } from 'react-dropzone';

import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { arrayMoveImmutable, arrayMoveMutable } from 'array-move'

import deleteIcon from '../../assets/icons/delete-icon.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Compressor from 'compressorjs';

function EditInmueble(props) {

    const [avisoPrecioNull, setAvisoPrecioNull] = useState(false)

    const cookies = new Cookies()
    let navigate = useNavigate()
    const location = useLocation();
    const { pathname } = useLocation();

    const [mensajeEstado, setMensajeEstado] = useState()

    const [precioPristine, setPrecioPristine] = useState()

    let prevArray = []
    let datosAuxArray = []
    let finalData = []
    let arrayImgs;


    const [inmuebleData, setInmuebleData] = useState({})


    const [datosAux, setDatosAux] = useState({
        imagenes: [],
        imgsData: []
    })

    //Gestion para reordenar las imagenes
    const [imgs, setImgs] = useState([])

    const [imgsEliminadas, setImgsEliminadas] = useState([])
    const [prevImgs, setPrevImgs] = useState([])


    //Gestion del drag n drop de imagenes
    const onDrop = useCallback(acceptedFiles => {

        console.log('onDrop')





        prevArray.push.apply(prevArray, acceptedFiles.map((file, index) => Object.assign(file, { preview: URL.createObjectURL(file), position: prevArray.length })))

        console.log('Imagenes en onDrop', prevArray)

        //esto estaba antes de mis cambios 21sep
        // setImgs(prevArray.map((file, index) => Object.assign(file, { preview: URL.createObjectURL(file), position: index })))

        setImgs(prevArray)

        //esto estaba antes de mis cambios 21sep
        // arrayImgs = prevArray.map((file, index) => Object.assign(file, { preview: URL.createObjectURL(file), position: index }))
        arrayImgs = imgs
        let imgsData = [];


        prevArray.forEach(img => {
            console.log('IMAGENES EN ArrayImgs.map', img)
            imgsData.push({
                contentType: img.type,
                original: img.name,
                position: img.position,
            })

        })


        prevArray.forEach((imagen, index) => {
            if (typeof (imagen) == File) {
                new Compressor(imagen, {
                    quality: 0.6,
                    success: (compressedResult) => {
                        console.log('^^^^^^^^^^^^^^^^^6', prevArray[index])
                        prevArray[index] = new File([compressedResult], compressedResult.name, { type: compressedResult.type })
                        // imgsReducidas.push(new File([compressedResult], compressedResult.name, { type: compressedResult.type }))

                        console.log('Img comprimida...', compressedResult)
                        console.log('^^^^^^^^^^^^^^^^^6', prevArray[index])
                    },
                })

            }
        })


        setDatosAux({
            imagenes: prevArray,
            imgsData: prevArray.map(img =>
            ({
                contentType: img.type,
                original: img.name,
                position: img.position
            })
            )

        })


    }, [])





    const { getRootProps, getInputProps, isDragActive, isDragAccept, isFocused } = useDropzone({ onDrop })





    const onSortEnd = ({ oldIndex, newIndex }) => {
        console.log('Imagenes en OnsortEnd: ', imgs)
        let arr = arrayMoveImmutable(imgs, oldIndex, newIndex)
        for (let i = 0; i < arr.length; i++) {
            arr[i].position = i
        }
        console.log('Imagenes depsues de OnsortEnd', arr)
        setImgs(arr)

        setDatosAux({
            imgsData: arr.map(img =>
            ({
                contentType: img.contentType,
                original: img.original,
                position: img.position
            })
            )

        })

    }



    const SortableItem = SortableElement(({ value, index, position, idx }) => (
        <div className='add-inmueble-form-img-container' index={index}>
            <div className="add-inmueble-form-img-del" onClick={() => eliminarImagen(idx)} >
                <img style={{ height: 40, width: 40, zIndex: 10000 }} src={deleteIcon} alt="Icono eliminar" />
            </div>
            {/* {position ? (<div className="add-inmueble-form-img-pos">
                {position}
            </div>) : ''} */}
            {idx ? (<div className="add-inmueble-form-img-pos">
                {idx}
            </div>) : <div className="add-inmueble-form-img-pos">
                0
            </div>}
            < img className='add-inmueble-form-img' src={value} alt='' />

            {/* <p>{value.path}</p> */}
        </div>
    ));

    //**Componente que hace de lista que contiene las imágenes */
    const SortableList = SortableContainer(({ items }) => {
        console.log('SortableList:', items)
        return (
            <div className='add-inmueble-form-imagenes-container' >
                {items
                    .sort((a, b) => a.position - b.position)
                    .map((value, index) => {
                        // console.log('valores', value)
                        return <SortableItem position={value.position} value={value.preview} idx={index} index={index} key={value.id} />
                    })


                }

            </div>
        );
    });



    //Estado que oculta el div según el inmueble que se haya seleccionado
    const [hideTipo, setHideTipo] = useState('Habitatge')

    //Estado que oculta la efi y consumo si no se ha seleccionado Sí.
    const [hideEfiEnergetica, setHideEfiEnergetica] = useState('Sí');




    function prepararDatos() {

        console.log('En prepararDatos')



        console.log('Antes de subir', datosAux)

        // let imagenesDatos = datosAux;

        // imagenesDatos.imgsData.forEach((img) => {
        //     imagenesDatos.imagenes.forEach(imagen => {
        //         if (img.original == imagen.name) {
        //             img.position = imagen.position
        //         }
        //     })
        // })
        // console.log('Antes de subir imagenesDatos', imagenesDatos)
        // subirInmueble(imagenesDatos)


        let imagenesDatos = datosAux;


        // imagenesDatos.imgsData.forEach(imagen => {
        //     if (img.original == imagen.name) {
        //         img.position = imagen.position
        //     }
        // })

        console.log('Antes de subir imagenesDatos', imagenesDatos)
        subirInmueble(imagenesDatos)

        // subirInmueble('')


    }



    const subirInmueble = (imagenesDatos) => {
        // const formData = new FormData();
        // imgs.forEach(img => {
        //     console.log(img)
        //     console.log('Uo')
        //     formData.append('files', img, img.name);
        // })


        if (inmuebleData.precio.length < 1) {
            setAvisoPrecioNull(true);
            return
        }
        else {
            setAvisoPrecioNull(false);

        }


        console.log('IMAGENESFINALES', imagenesDatos)
        console.log('INMUEBLEDATA', inmuebleData)
        console.log('PRECIOPRISTINE', precioPristine);
        console.log('inmuebleData.precio', inmuebleData.precio);

        let dataFinal = { ...inmuebleData }


        if (document.getElementById('tipoInmuebleRef').value == 'Habitatge') {
            // console.log('H')
            delete dataFinal.escaparate
            console.log(dataFinal)
            // document.getElementById('subtipoInmuebleTerrenos').value = ''
            // document.getElementById('escaparateForm').checked = false
            // subtipoInmuebleTerrenosW.style.display = 'none'
            // subtipoInmuebleHabitatgeW.style.display = 'block'
            // setHideTipo('Habitatge')

        }
        if (document.getElementById('tipoInmuebleRef').value == 'Local Comercial') {
            // console.log('L')
            // setHideTipo('Local Comercial')
            // document.getElementById('subtipoInmuebleHabitatge').value = ''
            // document.getElementById('subtipoInmuebleTerrenos').value = ''


            dataFinal = { ...inmuebleData }
            delete dataFinal.subtipoInmueble
        }
        if (document.getElementById('tipoInmuebleRef').value == 'Terrenys i Solars') {
            // console.log('T')
            delete dataFinal.escaparate
            // hideTipo('Terrenys i Solars')
            // document.getElementById('subtipoInmuebleHabitatge').value = ''
            // document.getElementById('escaparateForm').checked = false

        }

        console.log('datafinal antes de juntar imagenesDatos con dataFinal', dataFinal);
        console.log('imagenesDatos antes de filtrar los undefined', imagenesDatos)

        let imagenesDatosImgsDataFiltered = imagenesDatos.imgsData.filter(img => img.contentType != undefined)
        imagenesDatos.imgsData = imagenesDatosImgsDataFiltered

        console.log('imagenesDatos despues de filtrar los undefined', imagenesDatos)



        dataFinal = { ...imagenesDatos, ...dataFinal }


        console.log('imagenesDatos.imgsData', imagenesDatos.imgsData)
        console.log('datosAux', datosAux.imgsData)

        let subir = true;
        for (const img of imagenesDatos.imgsData) {

            for (const imgDF of dataFinal.imgsData) {
                if (img.original == imgDF.original) {
                    subir = false;
                    break
                }
            }

            if (subir) {
                dataFinal.imgsData.push(img)
            }

        }





        dataFinal.imgsData = dataFinal.imgsData.filter(img => img.contentType)
        // console.log('Imagenes filtradas', imagenesNuevas)




        if (precioPristine != inmuebleData.precio) {
            // console.log('no son iguales...')
            // dataFinal = { ...inmuebleData, precioAnterior: precioPristine }
            dataFinal.precioAnterior = precioPristine;
            console.log('datafinal con precioAnterior cambiado supuestamente: ', dataFinal);
        }

        // imagenesNuevas.map(img => dataFinal.imgsData.push(img))

        console.log('Datafinal en SubirInmueble', dataFinal)
        let editDate = new Date()
        // console.log(editDate.getDate())
        // console.log(precioPristine, inmuebleData.precio)


        //let dataFinal = {}
        // dataFinal = { ...inmuebleData }

        dataFinal = { ...dataFinal }

        console.log('formulario justo antes de enviar', dataFinal)

        console.log('Imgs eliminadas: ', imgsEliminadas)

        // setInmuebleData({ ...inmuebleData})







        axios.post(`${process.env.REACT_APP_URL_SERVER}/updateInmueble`, dataFinal, { params: { _id: inmuebleData._id, imgsEliminadas: imgsEliminadas }, headers: { "Content-Type": "multipart/form-data", "Accept": "application/json", "Authorization": `Bearer ${cookies.get('ga_tk')}` } })
            .then((response) => {
                if (response.data == 0) {
                    console.log('Respuesta justo despues de actualizar: ', response)
                    navigate(`/dashboard/`)
                }

            })
            .catch(e => console.log("Error", e));



    }

    const eliminarImagen = (index) => {
        console.log('eliminando imagen', index)
        console.log(imgs)
        setImgs(imgs.filter((i, idx) => idx != index))
        let imgsEliminadasLocal = imgs.filter((i, idx) => idx == index)[0]
        setImgsEliminadas([...imgsEliminadas, imgsEliminadasLocal])
        console.log('Deberia haberme eliminado...')
    }

    const handleInputChange = (e) => {
        setInmuebleData({
            ...inmuebleData,
            [e.target.name]:
                e.target.type == "checkbox" ?
                    e.target.checked :
                    e.target.value
        })
        console.log(inmuebleData)
    }

    const handleTipoChange = (e) => {
        handleInputChange(e)
        setHideTipo(e.target.value)

    }

    const handleEstadoChange = (e) => {
        handleInputChange(e)
        setMensajeEstado(
            e.target.value == 'Public' ? "L'immoble serà visible a la web." : e.target.value == 'Privat' ? "L'immoble NO serà visible a la web." : ''
        )
    }

    const handleEfiChange = (e) => {
        handleInputChange(e)
        setHideEfiEnergetica(e.target.value)
    }


    useEffect(() => {

        axios.get(`${process.env.REACT_APP_URL_SERVER}/inmueble/${location.state}`, { params: { _id: location.state } }).then(async response => {
            // console.log(response.data[0])
            console.log('Imagenes en useEffect: ', response.data[0].imgsData.sort())
            // console.log(pathname)
            console.log(response.data[0])
            setPrecioPristine(response.data[0].precio)
            // if (response.data[0].precioAnterior) {
            //     console.log('Existe Precio anterior', response.data[0].precioAnterior)
            //     precioAnterior = response.data[0].precioAnterior
            //     response.data[0].precioAnterior = response.data[0].precio
            // }
            // else {
            //     console.log('no existe precio anterior')
            // }

            setInmuebleData(response.data[0])

            //Referencias de elementos html
            const tipoInmuebleRef = document.getElementById('tipoInmuebleRef')
            const efiEnergeticaRef = document.getElementById('efiEnergetica')

            if (tipoInmuebleRef.value == 'Habitatge') {
                // console.log('Es habitatge')
                // subtipoInmuebleTerrenos.value = ''
                // escaparateForm.checked = false
                // subtipoInmuebleTerrenosW.style.display = 'none'
                // subtipoInmuebleHabitatgeW.style.display = 'block'
                setHideTipo('Habitatge')
                // setInmuebleData({ ...inmuebleData, escaparate: false })
            }
            else if (tipoInmuebleRef.value == 'Local Comercial') {
                // console.log('Es local comercial')
                setHideTipo('Local Comercial')
                // escaparateForm.checked = response.data[0].escaparate
                // subtipoInmuebleHabitatge.value = ''
                // subtipoInmuebleTerrenos.value = ''

                // setInmuebleData({ ...inmuebleData, escaparate: response.data[0].escaparate })
            }
            else if (tipoInmuebleRef.value == 'Terrenys i Solars') {
                // console.log('Es terreno')
                setHideTipo('Terrenys i Solars')
                // subtipoInmuebleHabitatge.value = ''
                // escaparateForm.checked = false
                // setInmuebleData({ ...inmuebleData, escaparate: false })
            }
            else {
                setHideTipo('')
            }



            if (efiEnergeticaRef.value != 'Sí') {
                setHideEfiEnergetica('')
            }
            else {
                // console.log('es otra cosa.. pero no efi')
                setHideEfiEnergetica('Sí')
            }

            let imagenesArr = response.data[0].imgsData

            imagenesArr.forEach(img => {
                // console.log('IMG', img)
                img.preview = `${process.env.REACT_APP_DIR_SERVER}/${img.original}`

            })

            setImgs(
                response.data[0].imgsData
            )

            prevArray = response.data[0].imgsData

        }).catch((e) => {

            console.log('Erroraqui', e)
        })

    }, [])



    return (
        <div className='add-inmueble-container'>
            <h2>Editar Immoble</h2>
            <form className='add-inmueble-form'>
                <div className='add-inmueble-form-column'>
                    <div className='add-inmueble-form-section'>
                        <div className='empty-h3'></div>
                        {/* <h3>General</h3> */}
                        <div className='add-inmueble-form-fields'>

                            <Field
                                label='Alt Standing'
                                inputName='altoStanding'
                                inputType='checkbox'
                                checked={inmuebleData.altoStanding}
                                onChange={handleInputChange}
                            />
                            <Field
                                label='Destacat'
                                inputName='destacado'
                                inputType='checkbox'
                                checked={inmuebleData.destacado}
                                onChange={handleInputChange}

                            />
                            <div className="add-inmueble-form-select-container">
                                <label htmlFor="operacion">
                                    Operació
                                </label>
                                <select value={inmuebleData.operacion} name="operacion" style={{ width: '90px' }} onChange={handleInputChange} id="">
                                    <option value="Compra">Compra</option>
                                    <option value="Lloguer">Lloguer</option>
                                </select>
                            </div>
                            <div className="add-inmueble-form-select-container">
                                <label htmlFor="tipoInmueble">
                                    Immoble
                                </label>
                                <select value={inmuebleData.tipoInmueble} name="tipoInmueble" onChange={handleTipoChange} id="tipoInmuebleRef">
                                    <option value="Habitatge">Habitatge</option>
                                    <option value="Finca Rústica">Finca Rústica</option>
                                    <option value="Local Comercial">Local Comercial</option>
                                    <option value="Nau Industrial">Nau Industrial</option>
                                    <option value="Terrenys i Solars">Terrenys i Solars</option>
                                    <option value="Pàrquing">Pàrquing</option>
                                    <option value="Inversions">Inversions</option>
                                </select>
                            </div>
                            <div className="add-inmueble-form-select-container" id='subtipoInmuebleHabitatgeW'
                                style={{ display: hideTipo != 'Habitatge' ? 'none' : '' }}
                            >
                                <label htmlFor="subtipoInmueble">
                                    Tipus d'habitatge
                                </label>
                                <select value={inmuebleData.subtipoInmueble} name="subtipoInmueble" onChange={handleInputChange} id="subtipoInmuebleHabitatge">
                                    <option value="Pis">Pis</option>
                                    <option value="Casa">Casa</option>
                                    <option value="Casa Adossada">Casa Adossada</option>
                                    <option value="Casa Aparellada">Casa Aparellada</option>
                                    <option value="Finca Rústica">Finca Rústica</option>
                                    <option value="Dúplex">Dúplex</option>
                                    <option value="Àtic">Àtic</option>
                                    <option value="Apartament">Apartament</option>
                                    <option value="Triplex">Triplex</option>
                                    <option value="Xalet">Xalet</option>
                                    <option value="Estudi">Estudi</option>
                                    <option value="Masia">Masia</option>
                                </select>
                            </div>
                            <div className="add-inmueble-form-select-container" id='subtipoInmuebleTerrenosW'
                                style={{ display: hideTipo != 'Terrenys i Solars' ? 'none' : '' }}
                            >
                                <label htmlFor="subtipoInmueble">
                                    Tipus de Terreny/Solar
                                </label>
                                <select value={inmuebleData.subtipoInmueble} style={{ width: '160px' }} name="subtipoInmueble" onChange={handleInputChange} id="subtipoInmuebleTerrenos">
                                    <option value="Solar Urbà">Solar Urbà</option>
                                    <option value="Finca Rústica">Finca Rústica</option>
                                    <option value="Solar Industrial">Solar Industrial</option>
                                    <option value="Terreny Residencia">Terreny Residencial</option>
                                </select>
                            </div>
                            <Field
                                inputId='escaparateForm'
                                label='Aparador?'
                                inputName='escaparate'
                                inputType='checkbox'
                                checked={inmuebleData.escaparate}
                                hidden={hideTipo != 'Local Comercial' ? true : false}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="add-inmueble-form-section">
                        <h3>Direcció</h3>
                        <div className="add-inmueble-form-fields">
                            <Field
                                label='Província'
                                inputType='text'
                                inputName='provincia'
                                placeholder='Ex: Girona'

                                value={inmuebleData.provincia}
                                width={100}
                                onChange={handleInputChange}

                            />
                            <Field
                                label='Ciutat'
                                inputType='text'
                                inputName='ciudad'
                                placeholder='Ex: Olot'
                                value={inmuebleData.ciudad}
                                width={100}
                                onChange={handleInputChange}

                            />
                            <Field
                                label='Domicili'
                                inputType='text'
                                inputName='domicilio'
                                placeholder='Ex: Avda Girona'
                                value={inmuebleData.domicilio}
                                width='235px'
                                onChange={handleInputChange}
                            />
                            <Field
                                label='Num'
                                inputType='number'
                                inputName='num'
                                placeholder='Ex: 72'
                                value={inmuebleData.num}
                                width='70px'
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="add-inmueble-form-section">
                        <h3>Dades</h3>
                        <div className="add-inmueble-form-fields">
                            <Field
                                label='Ref.'
                                inputType='text'
                                inputName='referencia'
                                placeholder='Ex: GA-324850838'
                                value={inmuebleData.referencia}
                                width='155px'
                                onChange={handleInputChange}
                            />
                            <div>
                                <Field
                                    label='Preu'
                                    inputType='text'
                                    inputName='precio'
                                    placeholder='Ex: 350000'
                                    value={inmuebleData.precio}
                                    width={120}
                                    onChange={handleInputChange}

                                />
                                <p style={{ fontSize: '14px', transform: 'translateY(-10px)', color: 'red', fontWeight: '700', display: avisoPrecioNull == true ? 'block' : 'none' }}>El preu es obligatori *</p>
                            </div>

                            <Field
                                label='Zona'
                                inputType='text'
                                inputName='zona'
                                placeholder='Ex: Sant Roc'
                                value={inmuebleData.zona}
                                width='200px'
                                onChange={handleInputChange}
                            />
                            <Field
                                label='Latitud'
                                inputType='text'
                                inputName='latitud'
                                placeholder='Ex: 39.346069'
                                value={inmuebleData.latitud}
                                width='200px'
                                onChange={handleInputChange}
                            />
                            <Field
                                label='Longitud'
                                inputType='text'
                                inputName='longitud'
                                placeholder='Ex: -0.346069'
                                value={inmuebleData.longitud}
                                width='200px'
                                onChange={handleInputChange}
                            />
                            <a className='linkToMaps' style={{ textDecoration: 'none', color: 'white' }} target="_blank" href="https://www.google.es/maps/preview">Cercar coordenades</a>
                        </div>
                    </div>
                    <div className="add-inmueble-form-section">
                        <h3>Distribució</h3>
                        <div className="add-inmueble-form-fields">
                            <Field
                                label='Superficie (m²)'
                                inputType='number'
                                inputName='superficie'
                                placeholder='Ex: 1500'
                                value={inmuebleData.superficie}
                                fieldWidth='100px'
                                onChange={handleInputChange}
                            />

                            <Field
                                label='Habs'
                                inputType='number'
                                inputName='habs'
                                width='75px'
                                value={inmuebleData.habs}
                                onChange={handleInputChange}

                            />
                            <Field
                                label='Banys'
                                inputType='number'
                                inputName='banyos'
                                width='75px'
                                value={inmuebleData.banyos}
                                onChange={handleInputChange}
                            />
                            <Field
                                label='Lavabos'
                                inputType='number'
                                inputName='lavabos'
                                value={inmuebleData.lavabos}
                                width='75px'
                                onChange={handleInputChange}
                            />

                            <span className='add-inmueble-form-space'></span>
                            <Field
                                label='Cuina T. Office?'
                                inputType='checkbox'
                                inputName='cocinaOffice'
                                checked={inmuebleData.cocinaOffice}
                                onChange={handleInputChange}

                            />

                            <Field
                                label='Safareig?'
                                inputType='checkbox'
                                inputName='lavadero'
                                checked={inmuebleData.lavadero}
                                onChange={handleInputChange}
                            />
                            <Field
                                label='Traster'
                                inputType='checkbox'
                                inputName='trastero'
                                checked={inmuebleData.trastero}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                </div>
                <div className='add-inmueble-form-column'>
                    <div className="add-inmueble-form-section">
                        <h3>Característiques</h3>
                        <div className="add-inmueble-form-caracteristicas">
                            <Checkbox
                                label='Moblat'
                                inputName='amoblado'
                                checked={inmuebleData.amoblado}
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Aire Condicionat'
                                inputName='aireAcondicionado'
                                checked={inmuebleData.aireAcondicionado}
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Calefacció'
                                inputName='calefaccion'
                                checked={inmuebleData.calefaccion}
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Xemeneia'
                                inputName='chimenea'
                                checked={inmuebleData.chimenea}
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Pàrquing'
                                inputName='parking'
                                checked={inmuebleData.parking}
                                onChange={handleInputChange}
                            />
                            <Field
                                rowMode={true}
                                label='Any Construcció'
                                inputType='number'
                                inputName='anyoConstruccion'
                                placeholder='1990'
                                value={inmuebleData.anyoConstruccion}
                                fieldWidth='65px'
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Ascensor'
                                inputName='ascensor'
                                checked={inmuebleData.ascensor}
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Jardí'
                                inputName='jardin'
                                checked={inmuebleData.jardin}
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Balcó'
                                inputName='balcon'
                                checked={inmuebleData.balcon}
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Terrassa'
                                inputName='terraza'
                                checked={inmuebleData.terraza}
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Obra nova?'
                                inputName='obraNueva'
                                checked={inmuebleData.obraNueva}
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Piscina'
                                inputName='piscina'
                                checked={inmuebleData.piscina}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className='add-inmueble-form-section'>
                        <h3>Eficiencia Energética</h3>
                        <div className="add-inmueble-form-fields">
                            <div className="add-inmueble-form-select-container">
                                <label htmlFor="efiEnergetica">
                                    Existeix?
                                </label>
                                <select id='efiEnergetica' value={inmuebleData.efiEnergetica} name="efiEnergetica" onChange={handleEfiChange}>
                                    <option value="Sí">Sí</option>
                                    <option value="En tràmit">En tràmit</option>
                                    <option value="Exent">Exempt</option>
                                </select>
                            </div>
                            <Field
                                inputId='emisionesForm'
                                label='Emissions (kg CO2/m² any)'
                                inputType='number'
                                inputName='emisiones'
                                fieldWidth='130px'
                                value={inmuebleData.emisiones}
                                hidden={hideEfiEnergetica != 'Sí' ? true : false}
                                onChange={handleInputChange}

                            />
                            <Field
                                inputId='consumoForm'
                                label='Consum (kg CO2/m² any)'
                                inputType='number'
                                inputName='consumo'
                                fieldWidth='130px'
                                value={inmuebleData.consumo}
                                hidden={hideEfiEnergetica != 'Sí' ? true : false}
                                onChange={handleInputChange}
                            />

                        </div>
                    </div>
                    <div className="add-inmueble-form-section">
                        <h3>Descripció</h3>
                        <div className="add-inmueble-form-fields">
                            <Field
                                inputType='textarea'
                                inputName='descripcion'
                                placeholder='Ex: GA-324850838'
                                value={inmuebleData.descripcion}
                                fullWidth={true}
                                onChange={handleInputChange}
                            />

                        </div>
                    </div>


                    {/* <div className='add-inmueble-form-section'>
                        <h3>Pujar Imatges</h3>
                        <div className="add-inmueble-form-subir-imgs-container">
                            <p>Arrossega les imatges ...</p>
                        </div>
                    </div> */}

                </div>

            </form>
            <div className='add-inmueble-form-column-fWitdh'>
                <div className="add-inmueble-form-section">
                    <h3>Imatges</h3>
                    <div className="add-inmueble-form-subir-imgs-container" style={{ borderColor: isDragActive || isDragAccept ? '#000424E9' : isFocused ? '#19559FE9' : '' }} {...getRootProps()}>
                        <input name='upload-imgs' {...getInputProps()} />
                        {

                            <p>Arrossega aquí o fes clic per seleccionar les imatges</p>

                        }
                    </div>

                </div>
                <div className="add-inmueble-form-section" >
                    <SortableList
                        items={imgs}
                        onSortEnd={onSortEnd}
                        pressDelay={0}
                        axis={'xy'}
                        distance={1}

                    />
                </div>
                <div className='add-inmueble-form-estado'>
                    <div style={{ marginLeft: '35%' }} className="add-inmueble-form-select-container">
                        <label htmlFor="estado">
                            Estat de publicació
                        </label>
                        <select value={inmuebleData.estado} name="estado" onChange={handleEstadoChange} id="">
                            <option value="Public">Pu - Públic</option>
                            <option value="Privat">Pr - Privat</option>
                        </select>
                    </div>
                    <div style={{ minWidth: '150px' }} ><strong>{mensajeEstado}</strong></div>
                </div>
                <div className='add-inmueble-form-section'>
                    <button type='submit' onClick={prepararDatos} className='generic-btn add-inmueble-form-btn' style={{ width: '96%', marginLeft: '8px', margin: 'auto', fontSize: '18px' }}>Actualitzar Inmoble</button>
                </div>
            </div>
        </div>
    );
}

export default EditInmueble;