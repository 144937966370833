import React from 'react';
import './DetallesInmb.css';
import { Carousel, CarouselProps } from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import CurrencyFormat from 'react-currency-format';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

//Iconos
import BathIcon from '../../assets/icons/bathroom-icon.svg'
import BedIcon from '../../assets/icons/bedroom-icon.svg'
import M2Icon from '../../assets/icons/m2-icon.svg'
import PkIcon from '../../assets/icons/parking-icon.svg'
import PlantaIcon from '../../assets/icons/planta-icon.svg'
import CalefaIcon from '../../assets/icons/calefaccion-icon.png'
import JardinIcon from '../../assets/icons/jardin-icon.png'
import AmobladoIcon from '../../assets/icons/amoblado-icon.png'
import AireIcon from '../../assets/icons/aire-icon.png'
import AscensorIcon from '../../assets/icons/ascensor-icon.png'
import PiscinaIcon from '../../assets/icons/piscina-icon.png'
import TerrazaIcon from '../../assets/icons/terraza-icon.png'
import BalconIcon from '../../assets/icons/balcon-icon.png'
import ChimeneaIcon from '../../assets/icons/chimenea-icon.png'
import NuevoIcon from '../../assets/icons/nuevo-icon.png'
import efiA from '../../assets/icons/efi-A-icon.png'
import efiB from '../../assets/icons/efi-B-icon.png'
import efiC from '../../assets/icons/efi-C-icon.png'
import efiD from '../../assets/icons/efi-D-icon.png'
import efiE from '../../assets/icons/efi-E-icon.png'
import efiF from '../../assets/icons/efi-F-icon.png'
import efiG from '../../assets/icons/efi-G-icon.png'
import logo from '../../assets/img/logo-ga-recortado.jpeg'

//Componentes
import Mapa from './components/Mapa';
import FormContacto from './components/FormContacto';
import { useEffect } from 'react';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { useState } from 'react';
import { t } from 'i18next';
import { useRef } from 'react';


function DetallesInmb(props) {

    const carouselRef = useRef();
    const location = useLocation();
    const { id } = useParams()
    const { pathname } = useLocation();
    // let inmueble = []
    const [inmueble, setInmueble] = useState([]);


    // const screen = useFullScreenHandle();

    function goToSection() {
        const section = document.querySelector('#formulario-contacto-id');
        section.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'auto' })
    }, [pathname])

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_URL_SERVER}/inmueble/${id}`, { params: { _id: id } }).then(async response => {
            // console.log(response.data[0].imgsData.sort())

            //Se usa para ordenar las fotos según su posición asignada
            response.data[0].imgsData.sort(function (a, b) {
                if (a.position > b.position) {
                    return 1;
                }
                if (a.position < b.position) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            })

            // console.log(pathname)
            setInmueble(response.data[0])
        }).catch((e) => {
            console.log('Error', e)
        })

    }, [])

    // function fullScreen() {
    //     carouselRef.current.style={}
    // }


    return (
        <div className='detalles-container'>
            <div className='detalles-left-container'>
                <div className='detalles-img-wrapper'>
                    <Carousel
                        infiniteLoop={true}
                        thumbWidth={100}
                        showStatus={false}
                        showIndicators={false}
                        // onClickItem={fullScreen()}
                        ref={carouselRef}
                    // onClickItem={screen.enter}

                    >

                        {inmueble.imgsData ? inmueble.imgsData.map(img => (
                            // <FullScreen handle={screen} >

                            <img key={img.position} className='contain-img' src={`${process.env.REACT_APP_DIR_SERVER}/${img.original}`} alt='Foto inmueble' />

                            // </FullScreen>
                        )
                        ) : <div className='espere-container'> <img style={{ height: '100px', width: '100px' }} src={logo} alt="" />{t("Esperi")}...</div>}
                    </Carousel>
                </div>
                <CurrencyFormat
                    displayType={'text'} value={inmueble.precioAnterior} thousandSeparator={'.'} decimalSeparator={','}
                    renderText={value => <div className={inmueble.precioAnterior && inmueble.precioAnterior > inmueble.precio ? 'detalles-precio-anterior-container' : 'notDisplayed'}>
                        <h3 className='detalles-precio-anterior'>{value} € </h3><h3>⬇{(100 - (inmueble.precio / inmueble.precioAnterior) * 100).toFixed(1)}%</h3>
                    </div>}
                />

                <div className="detalles-precio-container">
                    <CurrencyFormat
                        displayType={'text'} value={inmueble.precio} thousandSeparator={'.'} decimalSeparator={','}
                        renderText={value => <h1>{value} €</h1>}
                    />

                </div>
                <span className='detalles-ref'>Ref: {inmueble.referencia}</span>
                <div className="detalles-titulo-container"><h2>{t(inmueble.tipoInmueble)}
                    {inmueble.operacion == 'Compra' ? t(" en venda") : t(' de lloguer')}{t(" a la zona de ")}{inmueble.zona}</h2></div>
                <div className="detalles-main-specs-container">
                    <div className="main-spec-container" style={{ display: inmueble.habs != null ? 'flex' : 'none' }}>
                        <img className='main-spec-img' src={BedIcon} alt="Icono habitaciones" />
                        <span className='main-spec-data'>{inmueble.habs}</span>
                    </div>
                    <div className="main-spec-container" style={{ display: inmueble.banyos != null ? 'flex' : 'none' }} >
                        <img className='main-spec-img' src={BathIcon} alt="Icono baños" />
                        <span className='main-spec-data'>{inmueble.banyos}</span>
                    </div>
                    <div className="main-spec-container">
                        <img className='main-spec-img' src={M2Icon} alt="Icono superficie" />
                        <span className='main-spec-data'>{inmueble.superficie}</span>
                    </div>
                    <div className={inmueble.planta ? "main-spec-container" : "notDisplayed"}>
                        <img className='main-spec-img' src={PlantaIcon} alt="Icono planta del inmueble" />
                        <span className='main-spec-data'>{inmueble.planta}</span>
                    </div>
                </div>
                <div className="detalles-descripcion-container"><p className='detalles-descripcion'>{inmueble.descripcion}</p></div>
                <h3 className='detalles-subtitle'>{t("Característiques de l'immoble")}</h3>
                <div className="detalles-caracteristicas-container">
                    <div className={inmueble.amoblado == true ? "main-spec-container-sec" : "notDisplayed"}>
                        <img className='main-spec-img-small' src={AmobladoIcon} alt="Icono amoblado/no amoblado" />
                        <span className='main-spec-data'>{t("Moblat")}</span>
                    </div>
                    <div className={inmueble.parking == true ? "main-spec-container-sec" : "notDisplayed"}>
                        <img className='main-spec-img-small' src={PkIcon} alt="Icono parking" />
                        <span className='main-spec-data'>{t("Garatge")}</span>
                    </div>
                    <div className={inmueble.calefaccion == true ? "main-spec-container-sec" : "notDisplayed"}>
                        <img className='main-spec-img-small' src={CalefaIcon} alt="Icono calefacción" />
                        <span className='main-spec-data'>{t("Calefacció")}</span>
                    </div>
                    <div className={inmueble.aireAcondicionado == true ? "main-spec-container-sec" : "notDisplayed"}>
                        <img className='main-spec-img-small' src={AireIcon} alt="Icono aire acondicionado" />
                        <span className='main-spec-data'>{t("Aire condicionat")}</span>
                    </div>
                    <div className={inmueble.jardin == true ? "main-spec-container-sec" : "notDisplayed"}>
                        <img className='main-spec-img-small' src={JardinIcon} alt="Icono jardin" />
                        <span className='main-spec-data'>{t("Jardí")}</span>
                    </div>
                    <div className={inmueble.ascensor == true ? "main-spec-container-sec" : "notDisplayed"}>
                        <img className='main-spec-img-small' src={AscensorIcon} alt="Icono ascensor" />
                        <span className='main-spec-data'>{t("Ascensor")}</span>
                    </div>
                    <div className={inmueble.piscina == true ? "main-spec-container-sec" : "notDisplayed"}>
                        <img className='main-spec-img-small' src={PiscinaIcon} alt="Icono piscina" />
                        <span className='main-spec-data'>{t("Piscina")}</span>
                    </div>
                    <div className={inmueble.terraza == true ? "main-spec-container-sec" : "notDisplayed"}>
                        <img className='main-spec-img-small' src={TerrazaIcon} alt="Icono terraza" />
                        <span className='main-spec-data'>{t("Terrassa")}</span>
                    </div>
                    <div className={inmueble.balcon == true ? "main-spec-container-sec" : "notDisplayed"}>
                        <img className='main-spec-img-small' src={BalconIcon} alt="Icono balcón" />
                        <span className='main-spec-data'>{t("Balcó")}</span>
                    </div>
                    <div className={inmueble.chimenea == true ? "main-spec-container-sec" : "notDisplayed"}>
                        <img className='main-spec-img-small' src={ChimeneaIcon} alt="Icono chimenea" />
                        <span className='main-spec-data'>{t("Xemeneia")}</span>
                    </div>
                    <div className={inmueble.obraNueva == true ? "main-spec-container-sec" : "notDisplayed"}>
                        <img className='main-spec-img-small' src={NuevoIcon} alt="Icono obra nueva" />
                        <span className='main-spec-data'>{t("Obra Nova")}</span>
                    </div>
                </div>
                <h3 className="detalles-subtitle">{t("Eficiència Energètica")}</h3>
                <div className="detalles-eficiencia-container">
                    <div className={inmueble.efiEnergetica == 'Exent' ?
                        "detalles-eficiencia-subcontainer" :
                        "notDisplayed"
                    }>
                        {t("Exempt")}
                    </div>
                    <div className={inmueble.efiEnergetica == 'En tràmit' ?
                        "detalles-eficiencia-subcontainer" :
                        "notDisplayed"
                    }>
                        {t("En tràmit")}
                    </div>
                    <div className={inmueble.efiEnergetica == 'Sí' ?
                        "detalles-eficiencia-subcontainer" :
                        "notDisplayed"
                    }>
                        <img className='efi-icon' src={
                            inmueble.emisiones < 10 ? efiA :
                                inmueble.emisiones < 16.3 ? efiB :
                                    inmueble.emisiones < 25.3 ? efiC :
                                        inmueble.emisiones < 38.9 ? efiD :
                                            inmueble.emisiones < 66.0 ? efiE :
                                                inmueble.emisiones <= 79.2 ? efiF :
                                                    efiG

                        } alt="Icono eficiencia energética" />
                        <strong> {t("Emissions")}:</strong> {inmueble.emisiones}kg CO2/m² {t("any")}
                    </div>
                    <div className={inmueble.efiEnergetica == 'Sí' ?
                        "detalles-eficiencia-subcontainer" :
                        "notDisplayed"
                    }>
                        <img className='efi-icon' src={
                            inmueble.consumo < 44.6 ? efiA :
                                inmueble.consumo < 72.3 ? efiB :
                                    inmueble.consumo < 112.1 ? efiC :
                                        inmueble.consumo < 172.3 ? efiD :
                                            inmueble.consumo < 303.7 ? efiE :
                                                inmueble.consumo <= 382.6 ? efiF :
                                                    efiG
                        } alt="Icono eficiencia consumo" />
                        <strong> {("Consum")}:</strong> {inmueble.consumo} kWh/m² {t("any")}
                    </div>
                </div>

                <h3 className="detalles-subtitle">{t("Ubicació aproximada")}</h3>
                <div className="detalles-mapa-container">
                    {inmueble.latitud && inmueble.longitud ? (<Mapa
                        lat={parseFloat(inmueble.latitud)}
                        long={parseFloat(inmueble.longitud)}
                    />) : (<div>{t("Carregant")}...</div>)}
                </div>
            </div>
            <FormContacto
                id={'formulario-contacto-id'}
                refImmueble={inmueble.referencia}
                operacion={inmueble.operacion}
                zonaInmueble={inmueble.zona}
                precioInmueble={inmueble.precio}
                linkInmueble={pathname}
            />
            <div onClick={goToSection} className="boton-modal-contacto">
                {t("Contacte")}
            </div>

        </div >

    );
}

export default DetallesInmb;