import React from 'react';
import './Footer.css';
import ubicacionIcon from '../assets/icons/ubicacion-icon.svg';
import telfIcon from '../assets/icons/telefono-icon.svg';
import emailIcon from '../assets/icons/email-icon.svg';
import fbIcon from '../assets/icons/fb-icon.svg'
import igIcon from '../assets/icons/ig-icon.svg'
import twIcon from '../assets/icons/tw-icon.svg'
import ytIcon from '../assets/icons/yt-icon.svg'
import catFlag from '../assets/icons/cat-flag-icon.svg'
import esFlag from '../assets/icons/esp-flag-icon.svg'
import ukFlag from '../assets/icons/uk-flag-icon.svg'
import { t } from 'i18next';
import i18n from '../i18n';



function Footer(props) {


    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }


    return (
        <div className='footer-container'>
            <div className='info-subcontainer'>
                <h3 style={{ marginBottom: '10px' }}>GARROTXA ACTIVA</h3>
                <div className='footer-row'><img className='footer-icons' src={ubicacionIcon} alt="Icono dirección" /><a className='Link a link-footer footer-row ' target="_blank" href="https://www.google.es/maps/place/Garrotxa+Activa/@42.1760555,2.4887478,17z/data=!3m2!4b1!5s0x12bacafb380b31c7:0xa6b88b52d74e7368!4m5!3m4!1s0x12bacafb38946c33:0x64fb85b2308e81e5!8m2!3d42.1760515!4d2.4909365?hl=es">Avinguda Sant Jordi, 166 - 17800 Olot</a></div>
                <div className='footer-row'><img className='footer-icons' src={telfIcon} alt="Icono teléfono" /><a className='Link a link-footer footer-row ' href="tel:972 275 112">972 275 112</a></div>
                <div className='footer-row'><img className='footer-icons' src={emailIcon} alt="Icono email" /><a className='Link a link-footer footer-row ' href="mailto:info@garrotxtaactiva.cat">info@garrotxaactiva.cat</a></div>

            </div>
            <div className='info-subcontainer'>
                <h3 style={{ marginBottom: '10px' }}>{t("ENLLAÇOS D'INTERÈS")}</h3>
                <div className='footer-row'><a className='Link a link-footer footer-row' href="/servicis/">{t("Presentació")}</a></div>
                <div className='footer-row'><a className='Link a link-footer footer-row' href="/">{t("Cercar immoble")}</a></div>
                <div className='footer-row'><a className='Link a link-footer footer-row' href="/vendre/">{t("Vendre | Llogar")}</a></div>
                <div className='footer-row'><a className='Link a link-footer footer-row' href="/blog/">{t("Blog")}</a></div>
            </div>
            <div className='info-subcontainer' >
                <h3 style={{ marginBottom: '10px' }}>{t("LEGAL")}</h3>
                <div className='footer-row'> <a className='Link a link-footer footer-row' href="/politica-privacitat/">{t("Política de Privacitat")}</a></div>
                <div className='footer-row'> <a className='Link a link-footer footer-row' href="/politica-cookies/">{t("Política de Cookies")}</a></div>
                <div className='footer-row'> <a className='Link a link-footer footer-row' href="/avis-legal/">{t("Avís legal")}</a></div>

            </div>
            <div className='info-subcontainer'>
                <h3 style={{ marginBottom: '10px' }}>{t("SEGUEIX-NOS")}</h3>
                <div className='footer-icons-row'>
                    <a className='Link a link-footer' href="https://es-es.facebook.com/GarrotxaActiva/">
                        <img style={{ height: 25 }} className='icon-upper-nav' src={fbIcon} alt='Icono Facebook' />
                    </a>
                    <a className='Link a link-footer' href="https://www.instagram.com/garrotxaactiva/">
                        <img style={{ height: 25 }} className='icon-upper-nav' src={igIcon} alt='Icono Instagram' />
                    </a>
                    <a className='Link a link-footer' href="https://twitter.com/garrotxaactiva">
                        <img style={{ height: 25 }} className='icon-upper-nav' src={twIcon} alt='Icono Twitter' />
                    </a>
                    <a className='Link a link-footer' href="https://www.youtube.com/channel/UCduIKGnL0S6p3ay-bhRueyg">
                        <img style={{ height: 25 }} className='icon-upper-nav' src={ytIcon} alt='Icono Youtube' />
                    </a>
                </div>
            </div>
            <div id='idioma-subcontainer' className='info-subcontainer'>
                <h3 style={{ marginBottom: '10px' }}>{t("IDIOMA")}</h3>
                <div className='footer-icons-row'>
                    <div onClick={() => changeLanguage('ca')} className='link-footer' >
                        <img style={{ height: 25 }} className='icon-upper-nav' src={catFlag} alt='Icono Idioma Catalán' />
                    </div>
                    <div onClick={() => changeLanguage('es')} className='link-footer'>
                        <img style={{ height: 25 }} className='icon-upper-nav' src={esFlag} alt='Icono Idioma Español' />
                    </div>
                    {/* <div onClick={() => changeLanguage('en')} className='link-footer' >
                        <img style={{ height: 25 }} className='icon-upper-nav' src={ukFlag} alt='tw-icon' />
                    </div> */}

                </div>
            </div>
        </div>
    );
}

export default Footer;