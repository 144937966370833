import './App.css';
import './i18n'
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";

import WhatsAppWidget from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css'




import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";






import fbIcon from './assets/icons/fb-icon.svg'
import igIcon from './assets/icons/ig-icon.svg'
import twIcon from './assets/icons/tw-icon.svg'
import ytIcon from './assets/icons/yt-icon.svg'



import LAdminFC from './assets/img/logo-admin-finques-colegiat.png';
import LEconoCat from './assets/img/logo-economistes-cat.png';
import LApi from './assets/img/logo-api.png';
import Logo from './assets/img/logo-ga-recortado.jpeg'
import BurgerMenuIcon from './assets/icons/burger-menu-icon.svg'

import Footer from './components/Footer';
import { useState, lazy } from 'react';

import ProtectedRoutes from './ProtectedRoutes';
import Sidebar from './components/Sidebar';
import { useTranslation } from 'react-i18next';



import { useRef } from 'react';

import { useEffect } from 'react';
import ReactGA from 'react-ga4'

//Import Pages
import Home from './pages/Home/Home';
import Inmuebles from './pages/Inmuebles/Inmuebles';
import DetallesInmb from './pages/DetallesInmb/DetallesInmb';
import VenderPage from './pages/VenderPage/VenderPage';
import Login from './pages/Login/Login';
import AvisLegal from './pages/AvisLegal/AvisLegal';
import PoliticaPrivacidad from './pages/PoliticaPrivacidad/PoliticaPrivacidad';
import PoliticaCookies from './pages/PoliticaCookies/PoliticaCookies';
import Menu from './pages/Menu/Menu';
import DashboardInmb from './pages/DashboardInmb/DashboardInmb';
import AddInmueble from './pages/AddInmueble/AddInmueble';
import EditInmueble from './pages/EditInmueble/EditInmueble';
import Blog from './pages/Blog/Blog';
import Articulo from './pages/Articulo/Articulo';
import Servicios from './pages/Servicios/Servicios';

// // Import pages lazy loading (testing...)
// const Home = lazy(() => import('./pages/Home/Home'));
// const Inmuebles = lazy(() => import('./pages/Inmuebles/Inmuebles'));
// const DetallesInmb = lazy(() => import('./pages/DetallesInmb/DetallesInmb'));
// const VenderPage = lazy(() => import('./pages/VenderPage/VenderPage'));
// const Login = lazy(() => import('./pages/Login/Login'));
// const AvisLegal = lazy(() => import('./pages/AvisLegal/AvisLegal'));
// const PoliticaPrivacidad = lazy(() => import('./pages/PoliticaPrivacidad/PoliticaPrivacidad'));
// const PoliticaCookies = lazy(() => import('./pages/PoliticaCookies/PoliticaCookies'));
// const Menu = lazy(() => import('./pages/Menu/Menu'));
// const DashboardInmb = lazy(() => import('./pages/DashboardInmb/DashboardInmb'));
// const AddInmueble = lazy(() => import('./pages/AddInmueble/AddInmueble'));
// const EditInmueble = lazy(() => import('./pages/EditInmueble/EditInmueble'));
// const Blog = lazy(() => import('./pages/Blog/Blog'));
// const Articulo = lazy(() => import('./pages/Articulo/Articulo'));
// const Servicios = lazy(() => import('./pages/Servicios/Servicios'));




function App() {






  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [toEquipo, setToEquipo] = useState(false);

  let botonModal = useRef()

  const openCloseModal = () => {
    setModal(!modal);
    botonModal.current.transform = 'rotateZ(-90deg);'
  };
  //MENU
  // const body = (
  //   <div className="modal-burger">
  //     <div className="nav-links-burger">

  //       <a className='Link nav-link-burger' href='/servicis'>Serveis immobiliaris</a>

  //       <div className='line'></div>
  //       <div className='Link nav-link-burger' onClick={goToEquipo}>Equip</div>
  //       <div className='line'></div>
  //       <Link onClick={openCloseModal} className='Link nav-link-burger' to='/'>Blog</Link>
  //       <div className='line'></div>
  //     </div>

  //   </div >
  // );




  const burgerBtn = document.getElementById('burger-menu-link')
  const sideMenu = document.getElementById('side-burger-menu')

  function gotoEquipoNoResponsive() {
    try {
      const section = document.querySelector('#equipo-section-id')
      section.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    catch (e) {
      goToHome().then(() => {
        const section = document.querySelector('#equipo-section-id')
        section.scrollIntoView({ behavior: 'smooth', block: 'center' })
      })
    }
  }


  function goToEquipo() {

    try {

      openCloseModal()
      const section = document.querySelector('#equipo-section-id')
      section.scrollIntoView({ behavior: 'smooth', block: 'center' })

    }
    catch (e) {
      openCloseModal()
      goToHome().then(() => {
        const section = document.querySelector('#equipo-section-id')
        section.scrollIntoView({ behavior: 'smooth', block: 'center' })
      })


    }


  }

  async function goToHome() {
    window.location.href = '/'
  }

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);


  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      ReactGA.initialize("G-2YJ4V8TQME");
    }
  }, []);

  return (

    <div className="App">

      <Router>

        <nav>
          <div className='upper-nav'>
            <span className='span-upper-nav' id='direccion'>
              <a style={{ fontFamily: 'Poppins', textDecoration: 'none', color: 'white' }} target="_blank" href="https://www.google.es/maps/place/Garrotxa+Activa/@42.1760555,2.4887478,17z/data=!3m2!4b1!5s0x12bacafb380b31c7:0xa6b88b52d74e7368!4m5!3m4!1s0x12bacafb38946c33:0x64fb85b2308e81e5!8m2!3d42.1760515!4d2.4909365?hl=es">
                {t("Avinguda")} Sant Jordi, 166 - 17800 Olot
              </a>
            </span>
            <span className='span-upper-nav' id='fuentes-contacto'>
              <a style={{ fontFamily: 'Poppins', textDecoration: 'none', color: 'white', marginRight: '2px' }} href="tel:972 275 112">
                972 275 112
              </a>
              |
              <a style={{ fontFamily: 'Poppins', textDecoration: 'none', color: 'white', marginLeft: '2px' }} href="mailto:info@garrotxaactiva.cat">
                info@garrotxaactiva.cat
              </a>
            </span>
            <span className='span-upper-nav' id='rrss'>
              <a className='Link a' href="https://es-es.facebook.com/GarrotxaActiva/">
                <img className='icon-upper-nav' src={fbIcon} alt='icono Facebook' />
              </a>
              <a className='Link a' href="https://www.instagram.com/garrotxaactiva/">
                <img className='icon-upper-nav' src={igIcon} alt='icono Instagram' />
              </a>
              <a className='Link a' href="https://twitter.com/garrotxaactiva">
                <img className='icon-upper-nav' src={twIcon} alt='icono Twitter' />
              </a>
              <a className='Link a' href="https://www.youtube.com/channel/UCduIKGnL0S6p3ay-bhRueyg">
                <img className='icon-upper-nav' src={ytIcon} alt='icono Youtube' />
              </a>
            </span>



          </div>
          <div className='lower-nav'>
            <a className='Link a' id='logo-wrapper' href="/">
              <img id='logo-ga' src={Logo} alt="Logo Garrotxa Activa" />
            </a>

            <div className="nav-links">
              <Link className='Link nav-link' to='/servicis/'>{t('Presentació')}</Link>
              <Link className='Link nav-link' to='/'>{t('Cercar immoble')}</Link>
              <Link className='Link nav-link' to='/vendre/' >{t('Vendre | Llogar')}</Link>
              <Link className='Link nav-link' to='/blog/'>{t('Blog')}</Link>

            </div>

            <div ref={botonModal} onClick={openCloseModal} id="burger-menu-link">
              <img id='burger-menu-icon' src={BurgerMenuIcon} alt="icono menu " />
            </div>

          </div>

        </nav>
        <Sidebar

          onClose={() => { }}
          onOpen={openCloseModal}
          isOpen={modal}
          onClickequipo={goToEquipo}
        />
        {/* <Modal
          id='side-burger-menu'
          BackdropProps={{ invisible: true, }}
          open={modal}
          onClose={openCloseModal}
          disableScrollLock
        >
          {body}
        </Modal> */}


        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/immobles/" element={<Inmuebles />} />
          <Route path="/immoble/:id/" element={<DetallesInmb />} />
          <Route path="/vendre/" element={<VenderPage />} />
          <Route path="/portal/" element={<Login />} />
          <Route path="/avis-legal/" element={<AvisLegal />} />
          <Route path="/politica-privacitat/" element={<PoliticaPrivacidad />} />
          <Route path="/politica-cookies/" element={<PoliticaCookies />} />
          <Route element={<ProtectedRoutes />} >
            <Route path="/home/" element={<Menu />} />
            <Route path="/dashboard/" element={<DashboardInmb />} />
            <Route path="/pujar-immoble/" element={<AddInmueble />} />
            <Route path="/editar-immoble/:id/" element={<EditInmueble />} />

          </Route>
          <Route path="/blog/" element={<Blog />} />
          <Route path="/blog/:id/" element={<Articulo />} />
          <Route path="/servicis/" element={<Servicios />} />



        </Routes >

        <footer>
          <Footer />

        </footer>

        <div className="logos-container">
          <a href="https://www.cafgi.org/" target="blank"><img src={LAdminFC} alt="logo Cafgi" className="logos-img" /></a>
          <a href="http://www.coleconomistes.cat/" target="blank">  <img src={LEconoCat} alt="logo Coleconomistes" className="logos-img" /></a>
          <a href="https://www.apigirona.com/" target="blank"> <img src={LApi} alt="logo API Girona" className="logos-img" /></a>
        </div>
        <div style={{ zIndex: '10000 !important' }}>
          <WhatsAppWidget

            placeholder={t('Escriu un missatge...')}
            sendButton={t('Enviar')}
            textReplyTime={t('Sol respondre el mateix dia')}
            message={t("Hola, què podem fer per tu?")}
            companyName="Adrià de Garrotxa Activa"
            phoneNumber='34660697936'
          />
        </div>

      </Router >

      <CookieConsent
        enableDeclineButton='true'
        cookieName='gaImmoblesAcceptCookie'
        // enableDeclineButton
        style={{ background: '#f6fbff', color: '#000000', padding: '20px', paddingBottom: '75px' }}
        buttonStyle={{
          background: "#022949",
          color: "white",
          fontWeight: "bolder",
          borderRadius: '5px'
        }}
        declineButtonStyle={{
          background: "#022949",
          color: "white",
          fontWeight: "bolder",
          borderRadius: '5px'
        }}
        flipButtons
        buttonText={t("Acceptar i continuar navegant")}

        overlay
        declineButtonText={t("Rebutjar")}
        onAccept={() => { ReactGA.initialize("G-2YJ4V8TQME"); }}

      >
        {t("Utilitzem  cookies pròpies i de tercers per al correcte funcionament del lloc web, i si ens dona el seu consentiment,  també farem servir cookies per recopilar dades de les seves visites per obtenir estadístiques agregades per millorar els nostres serveis.")}
        <div style={{ marginTop: '20px' }}>{t("Premi per obtenir")} <a href="/politica-cookies/" style={{ fontWeight: '700' }}>{t('més informació')}</a>.</div>
        {/* {t('o per')} <a href=""  style={{ fontWeight: '700' }} >{t("rebutjar-ne")}</a> {t("l'ús")} */}
      </CookieConsent>

    </div >

  );
}

export default App;
