import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ReactGA from 'react-ga4'
import reportWebVitals from './reportWebVitals';
import './i18n';
import logo from '../src/assets/img/logo-ga-recortado.jpeg';





const loading = (
  <div className='suspense-component'>
    <img className='suspense-img' style={{ height: '100px' }} src={logo} alt='' ></img>
    {/* <h1>Carregant...</h1> */}
  </div>

)

ReactDOM.render(



  <React.StrictMode>
    <Suspense fallback={loading}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
