import React from 'react';
import './PoliticaCookies.css'

function PoliticaCookies(props) {
    return (
        <div className='politica-cookies-container'>
            <h1>Política de cookies</h1>
            <p>Els nostre lloc web utilitza una tecnologia
                anomenada "cookie", amb la finalitat de poder obtenir
                informació sobre l'ús del lloc web, millorar
                l’experiència de l’usuari i garantir el seu
                correcte funcionament.</p>
            <br />
            <h2>Definició de Cookie</h2>
            <p>Una “cookie” és un petit fragment de text que els llocs web envien al navegador i que s'emmagatzemen
                en el terminal de l'usuari, (ordinador o dispositiu mòbil). Aquests arxius tenen la finalitat d'emmagatzemar
                dades que podran ser actualitzades i recuperades per l'entitat responsable de la seva instal·lació. Així,
                cada vegada que l'usuari torna a visitar el mateix lloc web, l'explorador recupera l'arxiu i l'envia al servidor
                del lloc web. Les cookies tenen diverses funcions entre les que s'inclouen, la de recollir informació sobre
                les preferències de l'usuari i recordar-la, i en general, facilitar l'ús del lloc web a l'usuari fent que el lloc
                sigui més útil al personalitzar el seu contingut</p>
            <br />
            <p>Les cookies es poden classificar en base a diferents paràmetres:
            </p>
            <br />
            <p><strong>1.- Titularitat:</strong> <u> cookies pròpies i cookies de tercers:</u> depenent de quina sigui l'entitat que gestioni el
                domini des d'on s'envien les cookies i es tractin les dades que s'obtinguin.</p>
            <br />
            <p><strong>2.- Duració:</strong> <u> cookies de sessió i cookies persistents:</u>  d'acord amb el termini de temps que romanen
                emmagatzemades al terminal de l'usuari.</p>
            <br />
            <p><strong>3.- Finalitat:</strong> <u> cookies tècniques, cookies de personalització, cookies d'anàlisi, cookies publicitàries, cookies
                de publicitat comportamental: </u>  d'acord amb la finalitat per a la qual es tractin les dades obtingudes.</p>
            <br />
            <br />
            <li><u>Cookies tècniques:</u> Són aquelles que permeten a l'usuari la navegació a través de la pàgina web o aplicació
                i la utilització de les diferents opcions o serveis que en ella existeixen. Per exemple, controlar el trànsit i
                la comunicació de dades , identificar la sessió o accedir a les parts web d'accés restringit.</li>
            <br />
            <li><u>Cookies de personalització:</u> Són aquelles que permeten a l'usuari accedir al servei amb algunes
                característiques de caràcter general predefinides en el seu terminal o que el propi usuari defineixi. Per
                exemple, l'idioma, el tipus de navegador a través del qual accedeix al servei o el disseny de continguts
                seleccionat.
            </li>
            <br />
            <li><u> Cookies d'anàlisi:</u> Són aquelles que o bé tractades per nosaltres o per tercers, ens permeten quantificar
                el nombre d'usuaris i així realitzar l'anàlisi estadístic de la utilització que fan els usuaris del servei ofert.
                Per això s'analitza la seva navegació a la nostra pàgina web per tal de millorar l'oferta de productes o
                serveis que li oferim.</li>
            <br />
            <li><u>Cookies publicitàries:</u> Són aquelles que permeten la gestió, de la forma més eficaç possible, dels espais
                publicitaris que, si escau, l'editor hagi inclòs en una pàgina web, aplicació o plataforma des de la qual
                presta el servei sol·licitat sobre la base de criteris com el contingut editat o la freqüència en a qual es
                mostren els anuncis.</li>
            <br />
            <li><u>Cookies de publicitat comportamental:</u> Són aquelles que permeten la gestió, de la manera més eficaç
                possible, dels espais publicitaris que, si escau, l'editor hagi inclòs en una pàgina web, aplicació o
                plataforma des de la que presta el servei sol·licitat. Aquestes cookies emmagatzemen informació del comportament dels usuaris obtinguda a través de l'observació continuada dels seus hàbits de navegació,
                el que permet desenvolupar un perfil específic per mostrar publicitat en funció del mateix.
            </li>
            <br />
            <br />
            <h2>Cookies utilitzades al nostre lloc web</h2>
            <p>El nostre lloc web utilitza tant cookies pròpies, que són les enviades al seu ordinador i gestionades
                exclusivament per nosaltres per al millor funcionament del lloc web, com cookies de tercers, amb la funció
                de servir per a la interacció dels usuaris amb nosaltres.
            </p>
            <br />
            <p>El nostre lloc web fa servir tant cookies temporals de sessió com cookies persistents. Les cookies de sessió
                emmagatzemen dades únicament mentre l'usuari accedeix a la web i les cookies persistents
                emmagatzemen les dades en el terminal perquè siguin accedides i utilitzades en més d'una sessió.</p>
            <br />
            <p>Concretament, les cookies que precisen de consentiment utilitzades al nostre lloc web són:</p>
            <br />
            <table>
                <thead>
                    <th>
                        Nom
                    </th>
                    <th>
                        Titular
                    </th>
                    <th>
                        Finalitat
                    </th>
                    <th>
                        Durada
                    </th>
                </thead>
                <tbody style={{ fontSize: '13px' }}>
                    <tr>
                        <td>
                            <p>_ga</p>
                            <p>_ga_xxxxxxxxx</p>
                        </td>
                        <td>
                            <a href="">immobiliariagarrotxaactiva.cat</a>
                        </td>
                        <td>
                            <strong style={{ marginBottom: '10px' }}>Analítiques:</strong>

                            <li>
                                Generar un identificador d'usuari únic,
                                que és el que s'utilitza per fer recompte
                                de quantes vegades visita el lloc un
                                usuari, així com la data de la primera i
                                l'última vegada que va visitar la web.
                            </li>
                            <br />
                            <li>
                                Comprovar la necessitat de mantenir la
                                sessió d'un usuari oberta o crear una
                                nova
                            </li>
                            <br />
                            <span style={{ fontSize: '12px' }}>Més info: <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es" target={'_blank'}>Google LLC</a> (Google Analytics)</span>
                        </td>
                        <td>
                            <p>_ga: 13 mesos</p>
                            <p>_ga_xxxxxxxxx: 13 mesos</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ fontSize: '13px' }}>Per obtenir més informació sobre les cookies d'aquest tercer pot clicar sobre l'enllaç que hi ha al seu nom.
            </p>
            <br />
            Finalment, també s'utilitzen cookies de complement (plug-in) per intercanviar continguts socials.
            <br />
            <br />
            <h2>Consentiment</h2>
            <br />
            <p>El consentiment per a l'ús de les cookies abans enunciades, pels terminis assenyalats i en les condicions
                contingudes en la present Política de Cookies, s’obtindrà amb l’acceptació de l’avís inicial d’informació
                sobre les cookies que es visualitza en el moment en que carrega la nostra web.</p>
            <br />
            <h2>Com desactivar les cookies en els principals navegadors?</h2>
            <br />
            <p>Tot i que pot variar lleugerament d'una versió de navegador a una altra, la configuració de la política de
                cookies per als navegadors més utilitzats és la següent:
            </p>
            <br />
            <li><strong> Internet Explorer:</strong> Eines -> Opcions d'Internet -> Privacitat -> Configuració.
                <li><strong> Firefox:</strong> Eines -> Opcions -> Privacitat -> Històric -> Configuració Personalitzada.</li>
                <li><strong> Chrome:</strong>  Configuració -> Mostra opcions avançades -> Privacitat -> Configuració de contingut.</li>
                <li><strong> Safari:</strong> Preferències -> Seguretat</li>
                <li><strong> Opera:</strong>Configuració -> Privacitat i seguretat.
                </li>
                <br />
            </li>
            <p>Per a més informació, pot consultar el suport o l'ajuda del seu navegador o a través dels següents enllaços:
                <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target={'_blank'}>Safari, </a>
                <a href="https://support.google.com/chrome/answer/95647?hl=es" target={'_blank'}>Chrome, </a>
                <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we" target={'_blank'}>Firefox, </a>
                <a href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target={'_blank'}>Explorer, </a>
                <a href="https://help.opera.com/en/latest/web-preferences/#cookies" target={'_blank'}>Opera.</a>
            </p>
            <br />
            <p>A més, també pot gestionar el magatzem de cookies al seu navegador a través d'eines com les següents:</p>
            <li> <strong><a href="https://www.youronlinechoices.com/es/" target={'_blank'}>Your online choices</a></strong>
                <li> <strong><a href="https://www.ghostery.com/" target={'_blank'}>Ghostery</a></strong></li>
                <br />
                <h2>Actualització de la política de cookies</h2>
                <br />
                <p>Aquest lloc web pot modificar aquesta Política sobre Cookies, per això li recomanem revisar aquesta
                    política cada vegada que accedeixi al nostre lloc web per tal d'estar adequadament informat sobre l’ús
                    que fem de les cookies. </p>
                <br />
                <p> 14/02/2023</p>
                <br />


            </li>


        </div>
    );
}

export default PoliticaCookies;