import React from 'react';
import './AvisLegal.css';
import { t } from 'i18next';


function AvisLegal(props) {
    return (
        <div className='aviso-legal-container'>
            <h1>{t("Avís Legal")}</h1>
            <h3>{t("Informació de Conformitat amb l'article 10 de la LSSI")}</h3>
            <br />
            <h3>{t("La titular d'aquesta web és:")}</h3>
            <p>{t("Identitat")}: GARROTXA ACTIVA, SL</p>
            <p>NIF: B17538539</p>
            <p>{t("Adreça")}: Avda. Sant Jordi, 166  17800  Olot</p>
            <p>Tel.: 972275112</p>
            <p>Email: info@garrotxaactiva.cat</p>
            <p>{t("Inscrita al Registre Mercantil de Girona, secció. 8ª, Full GI-20877, Foli 124, Tom 1167")}</p>
            <br />
            <h3>{t("Condicions d’ús")}</h3>
            <p>{t(`L’ús d’aquest web implica l’acceptació plena dels termes i condicions del present avís legal.
                Els possibles conflictes relatius a aquest web es regiran exclusivament pel dret de l’Estat espanyol.
                Tota persona usuària del web, independentment de la jurisdicció territorial des de la qual es produeixi el seu accés,
                accepta el compliment i respecte d’aquestes clàusules amb renúncia expressa a qualsevol altre fur que li pogués correspondre.`)}</p>
            <br />
            <h3>Propietat intel·lectual i industrial</h3>
            <p>La totalitat del contingut d’aquest lloc web, ja siguin textos, imatges, sons, marques, logotips o altres elements,
                així com subestructura, disseny, combinacions de colors o forma de presentació dels materials, estan protegits per
                drets de propietat industrial i intel·lectual que l’usuari d’aquest lloc web ha de respectar.
                No és lícita la seva transformació o alteració, comunicació pública o qualsevol altra forma d’explotació per qualsevol
                procediment sense l’autorització expressa de GARROTXA ACTIVA, SL .</p>
            <br />
            <h3>Responsabilitat empresarial i social</h3>
            <p>Encara que GARROTXA ACTIVA, SL actua amb la màxima diligència possible, es pot donar el cas
                que alguna dada o informació no estigui del tot actualitzada en el moment que l’usuari del
                lloc web el consulti. Per això, les informacions que es presenten en aquesta web tenen una
                funció orientativa i no obliguen a GARROTXA ACTIVA, SL.</p>
            <br />
            <p>GARROTXA ACTIVA, SL no serà responsable de la informació que es pugui obtenir a través
                d’enllaços inclosos en la seva web.</p>
            <br />
            <p>GARROTXA ACTIVA, SL es reserva la facultat de realitzar, en qualsevol moment i sense necessitat
                de preavís, modificacions o actualitzacions de la informació i de qualsevol dels elements que
                integrin el disseny i configuració de la pàgina web.</p>
            <br />
            <h3>Llei aplicable i jurisdicció</h3>
            <p>L’ús d’aquest lloc web implica l’acceptació plena dels termes i condicions en el present avís
                legal. Els possibles conflictes relatius a aquest web es regiran exclusivament per la
                legislació espanyola, sent competents els Jutjats i Tribunals espanyols per a conèixer
                ualsevol qüestió que es susciti sobre ella. L’usuari, en virtut de la seva acceptació
                renúncia expressament a qualsevol fur que per aplicació de la Llei d’Enjudiciament Civil
                vigent li  pogués correspondre.</p>

        </div >
    );
}

export default AvisLegal;