import React, { useEffect, useState, useCallback, Fragment } from 'react';
import './AddInmueble.css';
import Checkbox from './components/Checkbox';
import Field from './components/Field';
import Select from './components/Select';
import axios from 'axios';

import { useDropzone } from 'react-dropzone';

import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { arrayMoveImmutable, arrayMoveMutable } from 'array-move'

import deleteIcon from '../../assets/icons/delete-icon.svg'
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Compressor from 'compressorjs';

function AddInmueble(props) {

    const [avisoPrecioNull, setAvisoPrecioNull] = useState(false)


    const cookies = new Cookies()
    let navigate = useNavigate()

    const [mensajeEstado, setMensajeEstado] = useState()

    let prevArray = []
    let datosAuxArray = []
    let finalData = []
    let arrayImgs;
    //TODO:NO SE PQ AL ENVIAR RESETEA TODOS LOS DATOS
    const [datosInm, setDatosInm] = useState({
        altoStanding: false,
        destacado: false,
        operacion: 'Compra',
        tipoInmueble: 'Habitatge',
        subtipoInmueble: 'Pis',
        provincia: null,
        ciudad: null,
        domicilio: null,
        num: null,
        referencia: null,
        precio: null,
        zona: null,
        superficie: null,
        efiEnergetica: 'Sí',
        anyoConstruccion: null,
        estado: 'Public'
    })

    const [datosAux, setDatosAux] = useState({
        imagenes: [],
        imgsData: []
    })

    //Gestion para reordenar las imagenes
    const [imgs, setImgs] = useState([])
    const [prevImgs, setPrevImgs] = useState([])


    //Gestion del drag n drop de imagenes
    const onDrop = useCallback(acceptedFiles => {

        console.log('onDrop')


        prevArray.push.apply(prevArray, acceptedFiles)

        console.log(prevArray)
        setImgs(prevArray.map((file, index) => Object.assign(file, { preview: URL.createObjectURL(file), position: index })))
        // setImgs(prevState => [...prevState, accF])

        // updatePrevArray(prevArray)
        //let arrayImgs = prevArray.map((file, index) => Object.assign(file, { contentType: file.type, name: file.name, position: index }))
        arrayImgs = prevArray.map((file, index) => Object.assign(file, { preview: URL.createObjectURL(file), position: index }))
        let imgsData = [];
        imgs.forEach(img => {

            imgsData.push({
                contentType: img.type,
                original: img.name,
                position: img.position,
            })

        })

        // prevArray.forEach((imagen, index) => {
        //     new Compressor(imagen, {
        //         quality: 0.6,
        //         success: (compressedResult) => {
        //             console.log('^^^^^^^^^^^^^^^^^6', prevArray[index])
        //             prevArray[index] = new File([compressedResult], compressedResult.name, { type: compressedResult.type })
        //             // imgsReducidas.push(new File([compressedResult], compressedResult.name, { type: compressedResult.type }))

        //             console.log('Img comprimida...', compressedResult)
        //             console.log('^^^^^^^^^^^^^^^^^6', prevArray[index])
        //         },
        //     })
        // })


        setDatosAux({
            imagenes: prevArray,
            imgsData: prevArray.map(img =>
            ({
                contentType: img.type,
                original: img.name,
                position: img.position
            })
            )

        })

        console.log('arrayimgs', datosAux)


    }, [])





    const { getRootProps, getInputProps, isDragActive, isDragAccept, isFocused } = useDropzone({ onDrop })





    const onSortEnd = ({ oldIndex, newIndex }) => {
        console.log(imgs)
        let arr = arrayMoveImmutable(imgs, oldIndex, newIndex)
        for (let i = 0; i < arr.length; i++) {
            arr[i].position = i
        }
        setImgs(arr)

    }

    const onSortStart = ({ oldIndex, newIndex }) => {
        console.log('onsortstart')
        // let arr = arrayMoveImmutable(imgs, oldIndex, newIndex)
        // for (let i = 0; i < arr.length; i++) {
        //     arr[i].position = i
        // }
        // setImgs(arr)


    }



    const SortableItem = SortableElement(({ value, index, position, onRemove, idx }) => (
        <div className='add-inmueble-form-img-container' index={index}>
            <div className="add-inmueble-form-img-del" onClick={() => onRemove(idx)} >
                <img style={{ height: 40, width: 40, zIndex: 1000 }} src={deleteIcon} alt="Icono papelera" />
            </div>
            {/* {position ? (<div className="add-inmueble-form-img-pos">
                {position}
            </div>) : ''} */}
            {position ? (<div className="add-inmueble-form-img-pos">
                {position}
            </div>) : <div className="add-inmueble-form-img-pos">
                0
            </div>}
            < img className='add-inmueble-form-img' src={value} alt='' />

            {/* <p>{value.path}</p> */}
        </div>
    ));

    const SortableList = SortableContainer(({ items, onRemove }) => {

        return (
            <div className='add-inmueble-form-imagenes-container' >
                {items
                    .sort((a, b) => a.position - b.position)
                    .map((value, index) => {
                        console.log('valores', value)
                        return <SortableItem position={value.position} value={value.preview} idx={index} index={index} key={value.id} onRemove={onRemove} />
                    })


                }

            </div>
        );
    });



    //Estado que oculta el div según el inmueble que se haya seleccionado
    const [hideTipo, setHideTipo] = useState('Habitatge')

    //Estado que oculta la efi y consumo si no se ha seleccionado Sí.
    const [hideEfiEnergetica, setHideEfiEnergetica] = useState('Sí');




    //TODO: ESTO NO VA A LA PRIMERA, MIRAR POR QUE
    function prepararDatos() {

        console.log('En prepararDatos')

        // imgs.forEach(async img => {

        //     imgsData.push({
        //         contentType: img.type,
        //         name: img.name,
        //         position: img.position,
        //     })

        // })
        // setDatosInm({
        //     ...datosInm,
        //     datosAuxArray
        // })


        // let imgsData = [];
        // imgs.forEach(img => {

        //     imgsData.push({
        //         contentType: img.type,
        //         name: img.name,
        //         position: img.position,
        //     })

        // })


        // setDatosAux({
        //     imagenes: imgs,
        //     imgsData: imgsData
        // });


        // finalData.push.apply(finalData, [{ datosInm, datosAux }])

        console.log('Antes de subir', datosAux)

        let imagenesDatos = datosAux;



        if (imagenesDatos.imgsData.length > 0) {

            console.log('Aqui hay fotos...')

            imagenesDatos.imgsData.forEach((img) => {
                imagenesDatos.imagenes.forEach((imagen, index) => {





                    if (img.original == imagen.name) {
                        img.position = imagen.position
                    }
                })
            })


            // imagenesDatos.imagenes.forEach((imagen, index) => {
            //     new Compressor(imagen, {
            //         quality: 0.6,
            //         success: (compressedResult) => {
            //             console.log('^^^^^^^^^^^^^^^^^6', imagenesDatos.imagenes[index])
            //             imagenesDatos.imagenes[index] = new File([compressedResult], compressedResult.name, { type: compressedResult.type })
            //             // imgsReducidas.push(new File([compressedResult], compressedResult.name, { type: compressedResult.type }))

            //             console.log('Img comprimida...', compressedResult)
            //             console.log('^^^^^^^^^^^^^^^^^6', imagenesDatos.imagenes[index])
            //         },
            //     })

            // })


        }
        console.log('Antes de subir imagenesDatos', imagenesDatos)



        subirInmueble(imagenesDatos)


    }

    const subirInmueble = (imagenesDatos) => {
        // const formData = new FormData();
        // imgs.forEach(img => {
        //     console.log(img)
        //     console.log('Uo')
        //     formData.append('files', img, img.name);
        // })


        if (datosInm.precio == null) {
            setAvisoPrecioNull(true);
            return
        }
        else {
            setAvisoPrecioNull(false);

        }

        let dataFinal = { ...imagenesDatos, ...datosInm }


        console.log('formulario justo antes de enviar', dataFinal)


        axios.post(`${process.env.REACT_APP_URL_SERVER}/uploadInmueble`, dataFinal, { headers: { "Content-Type": "multipart/form-data", "Authorization": `Bearer ${cookies.get('ga_tk')}` } })
            .then((response) => {
                console.log('subidas', response)
                navigate(`/dashboard/`)
            }
            ).catch(e => console.log("Error", e));


        // console.log('Formulario Data:', dataFinal)





    }


    const eliminarImagen = (index) => {
        console.log('eliminando imagen', index)
        // let array = imgs
        // array.splice(index, 1);
        // // let array = imgs.splice(index, 1);
        // setImgs(array)
    }

    const handleInputChange = (e) => {
        setDatosInm({
            ...datosInm,
            [e.target.name]:
                e.target.type == "checkbox" ?
                    e.target.checked :
                    e.target.value
        })
        console.log(datosInm)
    }

    const handleTipoChange = (e) => {
        handleInputChange(e)
        setHideTipo(e.target.value)
        console.log()
    }

    const handleEstadoChange = (e) => {
        handleInputChange(e)
        setMensajeEstado(
            e.target.value == 'Public' ? "L'immoble serà visible a la web." : e.target.value == 'Privat' ? "L'immoble NO serà visible a la web." : ''
        )
    }

    const handleEfiChange = (e) => {
        handleInputChange(e)
        setHideEfiEnergetica(e.target.value)
    }


    return (
        <div className='add-inmueble-container'>
            <h2>Pujar Immoble</h2>
            <form className='add-inmueble-form'>
                <div className='add-inmueble-form-column'>
                    <div className='add-inmueble-form-section'>
                        <div className='empty-h3'></div>
                        {/* <h3>General</h3> */}
                        <div className='add-inmueble-form-fields'>

                            <Field
                                label='Alt Standing'
                                inputName='altoStanding'
                                inputType='checkbox'
                                onChange={handleInputChange}
                            />
                            <Field
                                label='Destacat'
                                inputName='destacado'
                                inputType='checkbox'
                                onChange={handleInputChange}

                            />
                            <div className="add-inmueble-form-select-container">
                                <label htmlFor="operacion">
                                    Operació
                                </label>
                                <select name="operacion" style={{ width: '90px' }} onChange={handleInputChange} id="">
                                    <option value="Compra">Compra</option>
                                    <option value="Lloguer">Lloguer</option>
                                </select>
                            </div>
                            <div className="add-inmueble-form-select-container">
                                <label htmlFor="tipoInmueble">
                                    Immoble
                                </label>
                                <select name="tipoInmueble" onChange={handleTipoChange} id="">
                                    <option value="Habitatge">Habitatge</option>
                                    <option value="Finca Rústica">Finca Rústica</option>
                                    <option value="Local Comercial">Local Comercial</option>
                                    <option value="Nau Industrial">Nau Industrial</option>
                                    <option value="Terrenys i Solars">Terrenys i Solars</option>
                                    <option value="Pàrquing">Pàrquing</option>
                                    <option value="Inversions">Inversions</option>
                                </select>
                            </div>
                            <div className="add-inmueble-form-select-container"
                                style={{ display: hideTipo != 'Habitatge' ? 'none' : '' }}
                            >
                                <label htmlFor="subtipoInmueble">
                                    Tipus d'habitatge
                                </label>
                                <select name="subtipoInmueble" onChange={handleInputChange} id="">
                                    <option value="Pis">Pis</option>
                                    <option value="Casa">Casa</option>
                                    <option value="Casa Adossada">Casa Adossada</option>
                                    <option value="Casa Aparellada">Casa Aparellada</option>
                                    <option value="Finca Rústica">Finca Rústica</option>
                                    <option value="Dúplex">Dúplex</option>
                                    <option value="Àtic">Àtic</option>
                                    <option value="Apartament">Apartament</option>
                                    <option value="Triplex">Triplex</option>
                                    <option value="Xalet">Xalet</option>
                                    <option value="Estudi">Estudi</option>
                                    <option value="Masia">Masia</option>
                                </select>
                            </div>
                            <div className="add-inmueble-form-select-container"
                                style={{ display: hideTipo != 'Terrenys i Solars' ? 'none' : '' }}
                            >
                                <label htmlFor="subtipoInmueble">
                                    Tipus de Terreny/Solar
                                </label>
                                <select style={{ width: '160px' }} name="subtipoInmueble" onChange={handleInputChange} id="">
                                    <option value="Solar Urbà">Solar Urbà</option>
                                    <option value="Finca Rústica">Finca Rústica</option>
                                    <option value="Solar Industrial">Solar Industrial</option>
                                    <option value="Terreny Residencia">Terreny Residencial</option>
                                </select>
                            </div>
                            <Field
                                label='Aparador?'
                                inputName='escaparate'
                                inputType='checkbox'
                                hidden={hideTipo != 'Local Comercial' ? true : false}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="add-inmueble-form-section">
                        <h3>Direcció</h3>
                        <div className="add-inmueble-form-fields">
                            <Field
                                label='Província'
                                inputType='text'
                                inputName='provincia'
                                placeholder='Ex: Girona'
                                width={100}
                                onChange={handleInputChange}

                            />
                            <Field
                                label='Ciutat'
                                inputType='text'
                                inputName='ciudad'
                                placeholder='Ex: Olot'
                                width={100}
                                onChange={handleInputChange}

                            />
                            <Field
                                label='Domicili'
                                inputType='text'
                                inputName='domicilio'
                                placeholder='Ex: Avda Girona'
                                width='235px'
                                onChange={handleInputChange}
                            />
                            <Field
                                label='Num'
                                inputType='number'
                                inputName='num'
                                placeholder='Ex: 72'
                                width='70px'
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="add-inmueble-form-section">
                        <h3>Dades</h3>
                        <div className="add-inmueble-form-fields">
                            <Field
                                label='Ref.'
                                inputType='text'
                                inputName='referencia'
                                placeholder='Ex: GA-324850838'
                                width='155px'
                                onChange={handleInputChange}
                            />
                            <div>
                                <Field
                                    label='Preu'
                                    inputType='text'
                                    inputName='precio'
                                    placeholder='Ex: 350000'
                                    width={120}
                                    required
                                    onChange={handleInputChange}

                                />
                                <p style={{ fontSize: '14px', transform: 'translateY(-10px)', color: 'red', fontWeight: '700', display: avisoPrecioNull == true ? 'block' : 'none' }}>El preu es obligatori *</p>
                            </div>


                            <Field
                                label='Zona'
                                inputType='text'
                                inputName='zona'
                                placeholder='Ex: Sant Roc'
                                width='200px'
                                onChange={handleInputChange}
                            />
                            <Field
                                label='Latitud'
                                inputType='text'
                                inputName='latitud'
                                placeholder='Ex: 39.346069'
                                width='200px'
                                onChange={handleInputChange}
                            />
                            <Field
                                label='Longitud'
                                inputType='text'
                                inputName='longitud'
                                placeholder='Ex: -0.346069'
                                width='200px'
                                onChange={handleInputChange}
                            />
                            <a className='linkToMaps' style={{ textDecoration: 'none', color: 'white' }} target="_blank" href="https://www.google.es/maps/preview">Cercar coordenades</a>
                        </div>
                    </div>
                    <div className="add-inmueble-form-section">
                        <h3>Distribució</h3>
                        <div className="add-inmueble-form-fields">
                            <Field
                                label='Superficie (m²)'
                                inputType='number'
                                inputName='superficie'
                                placeholder='Ex: 1500'
                                fieldWidth='100px'
                                onChange={handleInputChange}
                            />

                            <Field
                                label='Habs'
                                inputType='number'
                                inputName='habs'
                                width='75px'
                                onChange={handleInputChange}

                            />
                            <Field
                                label='Banys'
                                inputType='number'
                                inputName='banyos'
                                width='75px'
                                onChange={handleInputChange}
                            />
                            <Field
                                label='Lavabos'
                                inputType='number'
                                inputName='lavabos'
                                width='75px'
                                onChange={handleInputChange}
                            />

                            <span className='add-inmueble-form-space'></span>
                            <Field
                                label='Cuina T. Office?'
                                inputType='checkbox'
                                inputName='cocinaOffice'
                                onChange={handleInputChange}

                            />

                            <Field
                                label='Safareig?'
                                inputType='checkbox'
                                inputName='lavadero'
                                onChange={handleInputChange}
                            />
                            <Field
                                label='Traster'
                                inputType='checkbox'
                                inputName='trastero'
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                </div>
                <div className='add-inmueble-form-column'>
                    <div className="add-inmueble-form-section">
                        <h3>Característiques</h3>
                        <div className="add-inmueble-form-caracteristicas">
                            <Checkbox
                                label='Moblat'
                                inputName='amoblado'
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Aire Condicionat'
                                inputName='aireAcondicionado'
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Calefacció'
                                inputName='calefaccion'
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Xemeneia'
                                inputName='chimenea'
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Pàrquing'
                                inputName='parking'
                                onChange={handleInputChange}
                            />
                            <Field
                                rowMode={true}
                                label='Any Construcció'
                                inputType='number'
                                inputName='anyoConstruccion'
                                placeholder='1990'
                                fieldWidth='65px'
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Ascensor'
                                inputName='ascensor'
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Jardí'
                                inputName='jardin'
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Balcó'
                                inputName='balcon'
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Terrassa'
                                inputName='terraza'
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Obra nova?'
                                inputName='obraNueva'
                                onChange={handleInputChange}
                            />
                            <Checkbox
                                label='Piscina'
                                inputName='piscina'
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className='add-inmueble-form-section'>
                        <h3>Eficiencia Energética</h3>
                        <div className="add-inmueble-form-fields">
                            <div className="add-inmueble-form-select-container">
                                <label htmlFor="efiEnergetica">
                                    Existeix?
                                </label>
                                <select name="efiEnergetica" onChange={handleEfiChange} id="">
                                    <option value="Sí">Sí</option>
                                    <option value="En tràmit">En tràmit</option>
                                    <option value="Exent">Exempt</option>
                                </select>
                            </div>
                            <Field
                                label='Emissions (kg CO2/m² any)'
                                inputType='number'
                                inputName='emisiones'
                                fieldWidth='130px'
                                hidden={hideEfiEnergetica != 'Sí' ? true : false}
                                onChange={handleInputChange}

                            />
                            <Field
                                label='Consum (kg CO2/m² any)'
                                inputType='number'
                                inputName='consumo'
                                fieldWidth='130px'
                                hidden={hideEfiEnergetica != 'Sí' ? true : false}
                                onChange={handleInputChange}
                            />

                        </div>
                    </div>
                    <div className="add-inmueble-form-section">
                        <h3>Descripció</h3>
                        <div className="add-inmueble-form-fields">
                            <Field
                                inputType='textarea'
                                inputName='descripcion'
                                placeholder='Ex: GA-324850838'
                                fullWidth={true}
                                onChange={handleInputChange}
                            />

                        </div>
                    </div>


                    {/* <div className='add-inmueble-form-section'>
                        <h3>Pujar Imatges</h3>
                        <div className="add-inmueble-form-subir-imgs-container">
                            <p>Arrossega les imatges ...</p>
                        </div>
                    </div> */}

                </div>

            </form>
            <div className='add-inmueble-form-column-fWitdh'>
                <div className="add-inmueble-form-section">
                    <h3>Imatges</h3>
                    <div className="add-inmueble-form-subir-imgs-container" style={{ borderColor: isDragActive || isDragAccept ? '#000424E9' : isFocused ? '#19559FE9' : '' }} {...getRootProps()}>
                        <input name='upload-imgs' {...getInputProps()} />
                        {

                            <p>Arrossega aquí o fes clic per seleccionar les imatges</p>

                        }
                    </div>

                </div>
                <div className="add-inmueble-form-section" >
                    <SortableList
                        items={imgs}
                        onSortEnd={onSortEnd}
                        pressDelay={0}
                        axis={'xy'}
                        onRemove={(index) => eliminarImagen(index)}
                    />
                </div>
                <div className='add-inmueble-form-estado'>
                    <div style={{ marginLeft: '35%' }} className="add-inmueble-form-select-container">
                        <label htmlFor="estado">
                            Estat de publicació
                        </label>
                        <select name="estado" onChange={handleEstadoChange} id="">
                            <option value="Public">Pu - Públic</option>
                            <option value="Privat">Pr - Privat</option>

                        </select>
                    </div>
                    <div style={{ minWidth: '150px' }} ><strong>{mensajeEstado}</strong></div>
                </div>
                <div className='add-inmueble-form-section'>
                    <button type='submit' onClick={prepararDatos} className='generic-btn add-inmueble-form-btn' style={{ width: '96%', marginLeft: '8px', margin: 'auto', fontSize: '18px' }}>Pujar Inmoble</button>
                </div>
            </div>
        </div>
    );
}

export default AddInmueble;