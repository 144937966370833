
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import React, { useEffect } from 'react';
import './DashboardInmb.css';
import thumbnail from '../../assets/img/thumbnail-example.jpg'

import CurrencyFormat from 'react-currency-format';
import axios from 'axios';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Cookies from "universal-cookie";




import logo from '../../assets/img/logo-ga-recortado.jpeg'
import deleteIcon from '../../assets/icons/delete-icon.svg'

function DashboardInmb(props) {

    const cookies = new Cookies();
    const [inmuebles, setInmuebles] = useState([])

    let [isOpen, setIsOpen] = useState(false)
    const [inmuebleAEliminar, setInmuebleAEliminar] = useState();

    function closeModal() {
        setIsOpen(false)
    }



    function openModal(inmueble) {
        setIsOpen(true)
        setInmuebleAEliminar(inmueble)



    }

    function confirmarEliminacion() {

        console.log(inmuebleAEliminar)
        axios.post(`${process.env.REACT_APP_URL_SERVER}/removeInmueble`, inmuebleAEliminar, { headers: { "Authorization": `Bearer ${cookies.get('ga_tk')}` } }).then(response => {
            console.log(response)

            setInmuebles(inmuebles.filter(inmb => inmb._id != inmuebleAEliminar._id))
            setIsOpen(false)
        })
    }


    //Paginacion
    const [pageNumber, setpageNumber] = useState(0);
    const [inmbPerPage, setInmbPerPage] = useState(25);

    const pagesVisited = pageNumber * inmbPerPage;
    const pageCount = Math.ceil(inmuebles.length / inmbPerPage);

    const changePage = ({ selected }) => {
        window.scrollTo({ top: 0, behavior: 'auto' })
        setpageNumber(selected);

    }





    let navigate = useNavigate()

    function ordenamelo(images) {

        return images.imgsData[images.imgsData
            .filter(pos => {
                if (pos.position == 0) {

                    return pos.position
                }
            })].original


    }

    async function eliminarInmueble(event, inmueble) {
        event.stopPropagation();

        openModal(inmueble);



    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => closeModal()} autoFocus className="p-button-text" />
                <Button label="Eliminar" icon="pi pi-check" onClick={() => confirmarEliminacion()} />
            </div>
        );
    }


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_SERVER}/getInmuebles`, { headers: { Authorization: `Bearer ${cookies.get('ga_tk')}` } }).then(response => {
            console.log('ANTES', response.data)
            response.data.forEach((inmueble) => {
                inmueble.imgsData.forEach(img => {
                    img.original = `${process.env.REACT_APP_DIR_SERVER}/${img.original}`
                })

                // inmueble.imgsData[
                // console.log(inmueble.imgsData
                //     .filter(pos => {
                //         if (pos.position == 0) {

                //             return pos.position
                //         }
                //     }
                //     )
                // )

                //Se usa para ordenar las fotos según su posición asignada
                inmueble.imgsData.sort(function (a, b) {
                    if (a.position > b.position) {
                        return 1;
                    }
                    if (a.position < b.position) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                })

            })

            console.log('DESPUES', response.data)

            setInmuebles(response.data)



        })


    }, [])





    return (
        <div className='dashboardinmb-container'>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2> Llistat d'immobles</h2>
                <div className='leyenda-tabla'>
                    <button className="generic-btn" style={{ marginRight: '20px' }} onClick={() => navigate('/pujar-immoble')}>Pujar Immoble</button>

                    <strong>Estat:</strong>
                    <strong> PU = </strong> Públic |
                    <strong> PR = </strong> Privat

                </div>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <ReactPaginate

                    previousLabel={"⬅"}
                    nextLabel={"➡"}
                    breakLabel={"..."}
                    breakClassName={"breakBttn"}
                    pageCount={pageCount}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={3}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                    renderOnZeroPageCount={null}
                    forcePage={pageNumber}
                />
            </div>

            <table id='dashboard-table' cellSpacing="0" cellPadding="0" style={{ overflowX: 'scroll' }}>
                <thead>


                    <tr className="dashboardinmb-header">
                        <th className=' col-estado'>Estat</th>
                        <th className='col-ref'>Ref.</th>
                        <th className='col-img'>Imatge Principal</th>
                        <th className='col-oportunidad'>Oportunitat</th>
                        <th className='col-precio'>Preu</th>
                        <th className='col-ciudad'>Ciutat</th>
                        <th className='col-zona'>Zona</th>
                        <th className='col-domicilio'>Domicili</th>
                        <th className='col-metros'>m²</th>
                        <th className='col-habs'>Habs</th>
                        <th className='col-banyo'>Banys</th>
                        <th className='col-f-subido'>D. Pujat</th>
                        <th className='col-f-editado'>D. Editat</th>
                        <th className='col-f-acciones'>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {inmuebles.length < 1 ? <div>Espere...</div> :
                        inmuebles.slice(pagesVisited, pagesVisited + inmbPerPage).map((inmb, index) =>
                            <tr className={index % 2 == 0 ? 'dashboardinmb-row-data' : 'dashboardinmb-row-data-impar'}
                                onClick={() => navigate(`/editar-immoble/${inmb._id}`, { state: inmb._id })}
                            >
                                <td className='dashboardinmb-cell'><strong>{inmb.estado ? inmb.estado.slice(0, 2).toUpperCase() : ''}</strong></td>
                                <td className='dashboardinmb-cell numbers-cell'>{inmb.referencia}</td>
                                <td className='dashboardinmb-cell thumbnail-cell'><img className='dashboardinmb-thumbnail'
                                    // src={inmb.imgsData[(inmb.imgsData
                                    //     .filter(pos => {
                                    //         if (pos.position == 0) {

                                    //             return pos.position
                                    //         }
                                    //         return
                                    //     }))].original} alt="" 

                                    src={inmb.imgsData.length > 0 ? inmb.imgsData[0].original : logo} alt="Miniatura del inmueble"
                                /></td>
                                <td className='dashboardinmb-cell'>{inmb.operacion}</td>
                                <td className='dashboardinmb-cell numbers-cell'> <CurrencyFormat
                                    displayType={'text'} value={inmb.precio} thousandSeparator={'.'} decimalSeparator={','}
                                    renderText={value => <>{value} €</>}
                                /></td>
                                <td className='dashboardinmb-cell'>{inmb.ciudad}</td>
                                <td className='dashboardinmb-cell'>{inmb.zona}</td>
                                <td className='dashboardinmb-cell'>{inmb.domicilio} {inmb.num ? ',' : ''} {inmb.num}</td>
                                <td className='dashboardinmb-cell numbers-cell'>{inmb.superficie}</td>
                                <td className='dashboardinmb-cell numbers-cell'>{inmb.habs}</td>
                                <td className='dashboardinmb-cell numbers-cell'>{inmb.banyos}</td>
                                <td className='dashboardinmb-cell fecha-cell numbers-cell'>{new Date(inmb.fechaSubido).toLocaleDateString()}</td>
                                <td className='dashboardinmb-cell fecha-cell numbers-cell'>{inmb.fechaEditado ? new Date(inmb.fechaEditado).toLocaleDateString() : ''}</td>
                                <td className='dashboardinmb-cell numbers-cell' style={{ zIndex: '999' }}><div style={{ zIndex: '1000 !important' }} className='eliminar-inmueble-div' onClick={(event) => eliminarInmueble(event, inmb)}>  <img style={{ height: 50, width: 50, zIndex: 10000 }} src={deleteIcon} alt="Icono papelera" /></div></td>
                            </tr>
                        )}


                </tbody>


            </table>
            <ReactPaginate

                previousLabel={"⬅"}
                nextLabel={"➡"}
                breakLabel={"..."}
                breakClassName={"breakBttn"}
                pageCount={pageCount}
                pageRangeDisplayed={2}
                marginPagesDisplayed={3}
                onPageChange={changePage}

                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                renderOnZeroPageCount={null}
                forcePage={pageNumber}
            />

            <Dialog header="Confirmar eliminació" visible={isOpen} footer={renderFooter()} onHide={() => setIsOpen(false)}>
                Estàs segur que vols eliminar aquest immoble?
            </Dialog>

        </div >



    );
}

export default DashboardInmb;