import React from 'react';
import './PoliticaPrivacidad.css'

function PoliticaPrivacidad(props) {
    return (
        <div className='politica-privacidad-container'>
            <h1>Política de Privacitat</h1>
            <p>GARROTXA ACTIVA GRUP* es compromet a protegir la privacitat dels usuaris que accedeixin
                a aquesta web i/o qualsevol dels seus serveis. La utilització de la web i/o de qualsevol
                dels serveis oferts per GARROTXA ACTIVA GRUP* implica l’acceptació per l’usuari de les
                disposicions contingudes en la present Política de Privacitat i que les seves dades personals
                siguin tractades segons s’estipula en ella. Si us plau, tingui en compte que tot i que hi pugui
                haver enllaços de la nostra web a d’altres webs, aquesta Política de Privacitat no s’aplica a
                les webs d’altres companyies o organitzacions a les que la web estigui redirigida. GARROTXA
                ACTIVA GRUP* no controla el contingut de les webs de tercers ni accepta qualsevol responsabilitat
                pel contingut o les polítiques de privacitat d’aquestes webs.</p>
            <br />
            <br />
            <h3>Informació sobre el tractament de dades (Reglament (UE) 2016/679 i LO 3/2018)</h3>
            <br />
            <h4>Responsable del tractament</h4>
            <p>GARROTXA ACTIVA GRUP*</p>
            <p>Avda. Sant Jordi, 166  17800  Olot</p>
            <p>Email: info@garrotxaactiva.cat</p>
            <br />
            <h4>Finalitat del tractament</h4>
            <p>Oferir i gestionar els nostres
                serveis d’assessorament fiscal, laboral,
                comptable, immobiliaris i d’administració
                de finques.</p>
            <br />
            <h4>Legitimació</h4>
            <p>Consentiment obtingut de l’interessat.</p>
            <p>Execució del contracte de serveis.</p>
            <br />
            <h4>Destinataris</h4>
            <p>Les dades no seran comunicades a tercers,
                llevat que ho exigeixi una llei o sigui
                necessari per complir amb la finalitat del
                tractament.</p>
            <br />
            <h4>Drets de les persones</h4>
            <p>Els interessats tenen dret a exercir els drets
                d’accés, rectificació, limitació de tractament,
                supressió, portabilitat i oposició, enviant la
                seva sol·licitud a la nostra l’adreça.</p>
            <br />
            <h4>Termini de conservació de les dades</h4>
            <p>Mentre es mantingui la relació comercial o
                durant els anys necessaris per complir amb
                les obligacions legals.</p>
            <br />
            <h4>Reclamació</h4>
            <p>Els interessats es poden dirigir a l’AEPD
                per presentar la reclamació que consideri
                oportuna.</p>
            <br />
            <h4>Informació addicional</h4>
            <p>Pot consultar la informació addicional i
                detallada a continuació a les “Qüestions
                sobre privacitat”.</p>
            <br />
            <p>*Les societats que conformen GARROTXA ACTIVA
                GRUP que presten serveis d’assessoria fiscal,
                laboral, comptable, immobiliaris i
                d’administració de finques són: GARROTXA
                ACTIVA SL, RIPOLLÉS ASSESSORS I
                ADMINISTRADORS SL i ENRIC PLANA SERVEIS A
                EMPRESES SL.</p>
            <br />
            <br />
            <h3>Qüestions sobre privacitat</h3>
            <br />
            <p>En compliment del Reglament (UE) 2016/679 del
                Parlament Europeu i del Consell, de 27 d’abril
                de 2016, (RGPD) i la Llei Orgànica 3/2018,
                de 5 de desembre, de Protecció de Dades
                Personals i garantia dels drets digitals
                (LOPDGDD), li oferim la següent informació
                sobre el tractament de les seves dades
                personals:</p>
            <br />
            <h4>Qui és el responsable del tractament de les
                seves dades?</h4>
            <p>Les societats de GARROTXA ACTIVA GRUP que presten
                serveis són:</p>
            <br />
            <p>Identitat: GARROTXA ACTIVA SL</p>
            <p>Serveis d’assessorament fiscal, laboral, comptable,
                immobiliaris i d’administració de finques.</p>
            <p>NIF: B17538539</p>
            <p>Adreça: Avda. Sant Jordi, 166  17800  Olot</p>
            <p>Tel.: 972275112</p>
            <p>Email: info@garrotxaactiva.cat</p>
            <br />
            <p>Identitat: RIPOLLÉS ASSESSORS I ADMINISTRADORS, SL</p>
            <p>Serveis d’assessorament fiscal, laboral, comptable, immobiliaris i d’administració de finques</p>
            <p>NIF: B55164107</p>
            <p>Adreça: Ctra/ Barcelona, 27 baixos. 17500  Ripoll</p>
            <p>Tel.: 972701385</p>
            <p>Email: info@ripollesassessors.cat</p>
            <br />
            <p>Identitat: ENRIC PLANA SERVEIS A EMPRESES, SL</p>
            <p>Serveis d’assessorament fiscal, laboral i comptable.</p>
            <p>NIF: B17620006</p>
            <p>Adreça: Avda. Sant Jordi, 166  17800  Olot</p>
            <p>Tel.: 972275112</p>
            <p>Email: info@garrotxaactiva.cat</p>
            <br />
            <h4>Amb quina finalitat tractem les seves dades personals?</h4>
            <li>Tractem la informació que se’ns facilita
                per gestionar els nostres serveis
                d’assessorament fiscal, laboral, comptable,
                immobiliaris i d’administració de
                finques.</li>
            <li>En el cas que es posi en contacte amb
                nosaltres mitjançant el formulari de
                contacte de la nostra pàgina web, les
                tractarem per gestionar la seva consulta.</li>
            <li>Si ens dóna el seu consentiment també
                podrem tractar les seves dades per
                enviar-li informació sobre les nostres
                activitats, productes o serveis.</li>
            <li>Al accedir a les nostres instal·lacions la
                seva imatge pot ser enregistrada
                mitjançant càmeres de videovigilància amb
                finalitats de control de seguretat.</li>
            <li>Si ens envia un currículum, tractarem les
                dades amb la finalitat de gestionar la
                base de CV’s per a la selecció de personal.</li>
            <br />
            <h4>Per quant de temps conservarem les seves dades?</h4>
            <li>Les dades personals proporcionades es
                conservaran mentre sigui usuari dels
                nostres serveis o en vulgui rebre
                informació, i després, durant el
                terminis establerts per complir amb les
                nostres obligacions legals, que en el cas
                documentació comptable i fiscal a efectes
                mercantils seran 6 anys, de conformitat
                amb l’Art. 30 del codi de Codi Comerç,
                i als efectes fiscals seran 4 anys,
                d’acord amb els articles 66 a 70 de
                la Llei general tributària.</li>
            <li>Les imatges captades pel sistema de
                videovigilància es conservaran durant un mes.</li>
            <li>En el cas dels currículums les dades es conservaran durant un any.</li>
            <br />
            <h4>Quina és la legitimació per al tractament de les seves dades?</h4>
            <p>La legitimació per tractar-les es troba en
                l’execució del contracte de serveis i en els
                consentiments que ens dóna.</p>
            <br />
            <p>Respecte a aquella informació que sigui remesa
                per menors de 16 anys, serà requisit
                imprescindible que es faci amb el consentiment
                patern, del tutor o del legal representant del
                menor perquè les dades personals puguin ser
                objecte de tractament. Si no és així, el
                representant legal del menor ens ho ha de
                comunicar tan aviat com en tingui coneixement.</p>
            <br />
            <p>Pel que fa a la recollida de dades en el
                registre de visitants i a la captació
                d’imatges pel sistema de videovigilància,
                la legitimació ve donada per l’interès
                legítim de preservar la seguretat de
                les persones i béns.</p>
            <br />
            <h4>A quins destinataris es comunicaran les seves dades?</h4>
            <p>Les dades no seran comunicades a tercers, llevat que ho exigeixi una llei o sigui necessari per complir amb la finalitat del tractament.</p>
            <br />
            <h4>Quins són els seus drets quan ens facilita les seves dades?</h4>
            <li>Qualsevol persona té dret a obtenir confirmació sobre si estem tractant o no les seves dades personals.</li>
            <li>Les persones interessades tenen dret a accedir a les seves dades personals, així com a sol·licitar la rectificació de les dades inexactes o, si escau, sol·licitar la seva supressió quan, entre d’altres motius, les dades ja no siguin necessàries per a les finalitats per a les que es van recollir.</li>
            <li>En determinades circumstàncies els interessats podran sol·licitar la limitació del tractament de les seves dades, en aquest cas únicament les conservarem per a l’exercici o la defensa de reclamacions.</li>
            <li>També, en determinades circumstàncies i per motius relacionats amb la seva situació particular, els interessats es poden oposar al tractament de les seves dades. En aquest cas deixarem de tractar-les, excepte per motius legítims imperiosos o per a l’exercici o la defensa de possibles reclamacions.</li>
            <li>Els interessats també tenen dret a la portabilitat de les seves dades.</li>
            <li>Qualsevol interessat té dret a no ser objecte d’una decisió basada únicament en el tractament automatitzat, inclosa l’elaboració de perfils, que produeixi efectes jurídics que l’afectin o que l’afectin significativament de manera similar.</li>
            <li>Finalment, els interessats tenen dret a presentar una reclamació davant l’Autoritat de Control competent.</li>
            <br />
            <h4>Com pot exercir els seus drets? </h4>
            <p>Enviant-nos un escrit adjuntant una còpia d’un document que l’identifiqui,  a la nostra adreça física o a l’electrònica.</p>
            <br />
            <h4>Com hem obtingut les seves dades?</h4>
            <p>Les dades personals que tractem procedeixen del propi interessat, qui garanteix que les dades personals facilitades són certes i es fa responsable de comunicar-ne qualsevol modificació. Les dades que estiguin marcades amb un asterisc són obligatòries per poder donar-li el servei sol·licitat.</p>
            <br />
            <h4>Quines dades tractem?</h4>
            <p>Les categories de dades que  podem tractar són:</p>
            <li>Dades de caràcter identificatiu</li>
            <li>Dades de característiques personals</li>
            <li>Dades de circumstàncies socials.</li>
            <li>Dades acadèmiques i professionals.</li>
            <li>Detalls de l’ocupació laboral.</li>
            <li>Dades econòmiques, financeres i d’assegurances</li>
            <li>Informació comercial.</li>
            <li>Transaccions de béns i serveis.</li>
            <li>Imatge</li>
            <li>Dades de categoria especial:</li>
            <li>Salut</li>
            <br />
            <p>Les dades són limitades, atès que únicament tractem les dades necessàries per a la prestació dels nostres serveis i la gestió de la nostra activitat.</p>
            <br />
            <h4>Utilitzem cookies?</h4>
            <p>Utilitzem cookies durant la navegació a la nostra web amb el consentiment de l’usuari.</p>
            <p>L’usuari pot configurar el seu navegador perquè l’avisi de la utilització de cookies i per evitar-ne el seu ús. Si us plau, visiti la nostra política de cookies.</p>
            <br />
            <h4>Quines mesures de seguretat apliquem?</h4>
            <p>Apliquem les mesures de seguretat establertes a l’article 32 del RGPD, per tant hem adoptat les mesures de seguretat necessàries per garantir un nivell de seguretat adequat al risc del tractament de dades que realitzem, amb mecanismes que ens permeten garantir la confidencialitat, integritat, disponibilitat i resiliència permanent dels sistemes i serveis de tractament.</p>
            <br />
            <p>Algunes d’aquestes mesures són</p>
            <li>Informació de les polítiques de tractament de dades al personal.</li>
            <li>Realització de còpies de seguretat periòdiques.</li>
            <li>Control d’accés a les dades.</li>
            <li>Processos de verificació, avaluació i valoració regulars.</li>
            <br />
            <h4>Com tractem les dades per compte de tercers?</h4>
            <p>Quan en la prestació dels nostres serveis tractem dades de caràcter personal de les quals els nostres clients són els responsables, ho fem en qualitat d’encarregats del tractament, de conformitat amb el que estableix l’article 28 del RGPD i, per tant,  en  aquests tractaments de les dades personals:</p>
            <br />
            <p>1. Tractarem les dades personals únicament seguint instruccions documentades del responsable, fins i tot en relació amb les transferències de dades personals a un tercer país o a una organització internacional, tret que hi estiguem obligats en virtut del dret de la Unió o dels estats membres al qual estiguem subjectes. En aquest cas, informarem al responsable d’aquesta exigència legal prèvia al tractament, tret que aquest dret ho prohibeixi per raons importants d’interès públic.</p>
            <p>2. Garantim que les persones autoritzades per tractar dades personals s’han compromès a respectar-ne la confidencialitat.</p>
            <p>3.Hem adoptat totes les mesures de seguretat necessàries, de conformitat amb l’article 32 del RGPD, implantant mecanismes per tal de:</p>
            <br />
            <li>Garantir la confidencialitat, integritat, disponibilitat i resiliència permanent dels sistemes i serveis de tractament.</li>
            <li>Restaurar la disponibilitat i l’accés a les dades personals de forma ràpida, en cas d’incident físic o tècnic.</li>
            <li>Verificar, avaluar i valorar, de manera regular, l’eficàcia de les mesures tècniques i organitzatives implantades per garantir la seguretat del tractament.</li>
            <li>Pseudonimitzar i xifrar les dades personals, si s’escau.</li>
            <br />
            <p>1. Respectarem les condicions indicades en els apartats 2 i 4 de l’article 28 del RGPD per recórrer a un altre encarregat del tractament.</p>
            <p>2. Assistirem al responsable, sempre que sigui possible, d’acord amb la naturalesa  del tractament i mitjançant les mesures tècniques i organitzatives adequades, perquè pugui complir amb l’obligació de respondre les sol·licituds que tinguin per objecte l’exercici dels drets dels interessats establerts en el capítol III del RGPD.</p>
            <p>3. Ajudarem el responsable a garantir el compliment de les obligacions que sobre seguretat de les dades estableixen els articles 32 a 36 del RGPD, tenint en compte la naturalesa del tractament i la informació posada a la nostra disposició.</p>
            <p>4. A elecció del responsable, suprimirem o retornarem totes les dades personals un cop finalitzi la prestació dels serveis de tractament i suprimirem les còpies existents llevat que es requereixi la conservació de les dades personals en virtut del Dret de la Unió o dels Estats membres.</p>
            <p>Posarem a disposició del responsable tota la informació necessària per demostrar el compliment de les obligacions establertes en l’art. 28 del RGPD, així com per a permetre i contribuir a la realització d’auditories, incloses inspeccions, per part del responsable o d’un altre auditor autoritzat per aquest responsable.</p>
            <br />
            <p>Com a encarregats del tractament la tipologia de dades, la categoria d’interessats i els tractaments que podrem realitzar per compte dels nostres clients seran els següents:</p>
            <br />
            <ul>
                <li>Tipus de dades personals que podem tractar:
                    <ul>
                        <li>Dades de caràcter identificatiu</li>
                        <li>Dades de característiques personals</li>
                        <li>Dades acadèmiques i professionals.</li>
                        <li>Dades de circumstàncies socials</li>
                        <li>Detalls de l’ocupació laboral</li>
                        <li>Dades econòmiques, financeres i d’assegurances</li>
                        <li>Informació comercial.</li>
                        <li>Transaccions de béns i serveis</li>
                        <li>Salut</li>
                    </ul>
                </li>
            </ul>
            <br />

            <ul>
                <li> Categoria d’interessats afectats</li>
                <ul> <li>Clients</li>
                    <li>Proveïdors</li>
                    <li>Personal</li></ul>
            </ul>
            <br />
            <ul>
                <li>Tractaments de dades que podrem realitzar:</li>
                <ul>
                    <li>Recollida</li>
                    <li>Registre</li>
                    <li>Organització</li>
                    <li>Estructuració</li>
                    <li>Conservació</li>
                    <li>Modificació</li>
                    <li>Consulta</li>
                    <li>Utilització</li>
                    <li>Incorporació de documentació</li>

                </ul>


            </ul>











        </div>
    );
}

export default PoliticaPrivacidad;