import React from 'react';
import './Checkbox.css';

function Checkbox({ id, label, inputName, onChange, value, onClick, inputId, checked, defaultChecked }) {
    return (
        <div className='add-inmueble-checkbox-container' id={id}>
            <label htmlFor={inputName}>
                {label}
            </label>
            <input id={inputId} type='checkbox' name={inputName} onChange={onChange} value={value} defaultChecked={defaultChecked} checked={checked} onClick={onClick} />

        </div>
    );
}

export default Checkbox;