import './Home.css'
import React, { useEffect, useRef } from 'react';
import Buscador from './components/Buscador';
import { Fade } from 'react-reveal';
import Slider from './components/Slider';
import ProfilePic from '../../assets/img/profile-pic.png'
import eduPic from '../../assets/img/eduDef-min.png'
import adriaPic from '../../assets/img/adriaDef-min.png'
import ProfilePic1 from '../../assets/img/profile-pic1.png'
import jefesPic from '../../assets/img/jefesDef-min.png'
import { Parallax } from 'react-parallax';
import { Modal } from '@material-ui/core'

import Field from '../AddInmueble/components/Field';

import axios from 'axios';


import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import video from '../../assets/img/Immobles-min.mp4'
import { t } from 'i18next';

const Home = () => {
    const headerParallax = useRef()
    const equipoParallax = useRef()
    const heapara = document.getElementById('hs')

    const [modal, setModal] = useState(false);


    let navigate = useNavigate()
    const location = useLocation();

    const [inmuebles, setInmuebles] = useState([])
    const [busqueda, setBusqueda] = useState({
        operacion: 'Compra'
    })

    const [sliderIzdo, setSliderIzdo] = useState([])
    const [sliderDcho, setSliderDcho] = useState([])

    //Estado que oculta el div según el inmueble que se haya seleccionado
    const [hideTipo, setHideTipo] = useState('')
    const [hidePrecio, setHidePrecio] = useState('Compra')


    const handleInputChange = (e) => {
        setBusqueda({
            ...busqueda,
            [e.target.name]:
                e.target.type == "checkbox" ?
                    e.target.checked :
                    e.target.value
        })

    }

    const handleTipoChange = (e) => {
        handleInputChange(e)
        setHideTipo(e.target.value)

    }

    const handleOperacionPrecioChange = (e) => {
        handleInputChange(e)
        setHidePrecio(e.target.value)

    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_SERVER}/inmuebles/buscar`, { params: { destacado: true } }).then(response => {
            // console.log('Respuesta...', response.data)
            response.data.forEach((inmueble) => {
                if (inmueble.imgsData) {
                    inmueble.imgsData.forEach(img => {
                        if (img.original) {
                            img.original = `${process.env.REACT_APP_DIR_SERVER}/${img.original}`

                        }

                    })


                    //Se usa para ordenar las fotos según su posición asignada
                    inmueble.imgsData.sort(function (a, b) {
                        if (a.position > b.position) {
                            return 1;
                        }
                        if (a.position < b.position) {
                            return -1;
                        }
                        // a must be equal to b
                        return 0;
                    })
                }
            })




            let mitad = Math.floor(response.data.length / 2);

            setSliderIzdo(response.data.slice(0, Math.floor(response.data.length / 2) + 1))
            setSliderDcho(response.data.slice(Math.floor(response.data.length / 2) + 1))


            // setInmuebles(response.data)

            // openClose()
        })
    }, [])

    // const ffiltrar = () => {

    //     console.log('Búsqueda: ', busqueda)
    //     axios.get('http://localhost:3002/inmuebles/buscar', { params: busqueda }).then(response => {
    //         console.log(response.data)
    //         response.data.forEach((inmueble) => {
    //             inmueble.imgsData.forEach(img => {
    //                 img.original = 'http://127.0.0.1:8080/' + img.original
    //             })
    //         })
    //         setInmuebles(response.data)

    //     })





    // }


    const filtrar = () => {

        // console.log(busqueda)

        navigate('/immobles', { state: { busqueda } })
    }

    const body = (
        // <div className="modal-window">

        <div className="filtros-container" id='filtros-home-container'
        // ref={filtrosContainer}
        >

            <h2 style={{ color: 'var(--secondary)' }}>{t("Cerca avançada")}</h2>
            <div className="filtros-subcontainer" id='filtros-home-subcontainer' >

                <div id='filtros-first-section' className='filtros-section filtros-first-section' >
                    <div style={{ justifyContent: 'start', marginBottom: 0 }} className='filtros-selectores-container'>
                        <div>
                            <h5 className='filtros-row-title'>{t("Operació")}</h5>
                            <div style={{ minWidth: 'fit-content', marginRight: 15 }} className='filtros-selectores-container'>
                                <select style={{ width: 'fit-content' }} name="operacion" onChange={handleOperacionPrecioChange} id="">
                                    {/* <option value="">{("Tipus")}</option> */}
                                    <option selected value="Compra">{t("Compra")}</option>
                                    <option value="Lloguer">{t("Lloguer")}</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <h5 className='filtros-row-title'>{t("Direcció")}</h5>

                            <Field
                                placeholder={t('Ciutat, zona, direcció...')}
                                width='215px'
                                inputName='direccion'
                                onChange={handleInputChange}
                            />
                        </div>


                    </div>
                    <div className='filtros-row-container'>
                        <h5 className='filtros-row-title'>{t("Tipus d'immoble")}</h5>
                        <div style={{ minWidth: 240 }} className='filtros-selectores-container'>


                            <select style={{ width: 'fit-content' }} name="tipoInmueble" onChange={handleTipoChange} id="">
                                <option value="">{t("Tipus")}</option>
                                <option value="Habitatge">{t("Habitatge")}</option>
                                <option value="Finca Rústica">{t("Finca Rústica")}</option>
                                <option value="Local Comercial">{t("Local Comercial")}</option>
                                <option value="Nau Industrial">{t("Nau Industrial")}</option>
                                <option value="Terrenys i Solars">{t("Terrenys i Solars")}</option>
                                <option value="Pàrquing">{t("Pàrquing")}</option>
                                <option value="Inversions">{t("Inversions")}</option>
                            </select>

                            <div style={{ display: hideTipo != 'Habitatge' ? 'none' : '', marginTop: '-18px', marginLeft: '15px' }}>
                                <h5>Tipus d'habitatge</h5>
                                <select style={{ width: 'fit-content' }} name="subtipoInmueble" onChange={handleInputChange} id="">
                                    <option value="">{t("Tipus")}</option>
                                    <option value="Pis">{t("Pis")}</option>
                                    <option value="Casa">{t("Casa")}</option>
                                    <option value="Casa Adossada">{t("Casa Adossada")}</option>
                                    <option value="Casa Aparellada">{t("Casa Aparellada")}</option>
                                    {/* <option value="Finca Rústica">Finca Rústica</option> */}
                                    <option value="Dúplex">{t("Dúplex")}</option>
                                    <option value="Àtic">{t("Àtic")}</option>
                                    <option value="Apartament">{t("Apartament")}</option>
                                    <option value="Triplex">{t("Triplex")}</option>
                                    <option value="Xalet">{t("Xalet")}</option>
                                    <option value="Estudi">{t("Estudi")}</option>
                                    <option value="Masia">{t("Masia")}</option>
                                </select>
                            </div>

                            <div style={{ display: hideTipo != 'Terrenys i Solars' ? 'none' : '', marginTop: '-18px', marginLeft: '15px' }}>
                                <h5>{t("Tipus de terreny/solar")}</h5>
                                <select style={{ width: 'fit-content' }} name="subtipoInmueble" onChange={handleInputChange} id="">
                                    <option value="">{t("Tipus")}</option>
                                    <option value="Solar Urbà">{t("Solar Urbà")}</option>
                                    <option value="Finca Rústica">{t("Finca Rústica")}</option>
                                    <option value="Solar Industrial">{t("Solar Industrial")}</option>
                                    <option value="Terreny Residencia">{t("Terreny Residencial")}</option>
                                </select>
                            </div>

                            <div style={{ display: hideTipo != 'Local Comercial' ? 'none' : '', marginTop: '-20px', marginLeft: '-15px' }}>
                                <h5>{t("Aparador")}</h5>
                                <input style={{ marginTop: '8px' }} type="checkbox" name='escaparate' onChange={handleInputChange} />
                            </div>

                        </div>
                    </div>
                    {/* PARA COMPRA */}
                    <div style={{ display: hidePrecio == 'Compra' ? '' : 'none' }} className='filtros-row-container'>
                        <h5 className='filtros-row-title'>{t("Preu")}</h5>
                        <div className='filtros-selectores-container'>


                            <div style={{ display: 'flex', alignItems: 'center', columnGap: 3 }}>

                                <select className='numero-formato' style={{ width: 'fit-content' }} name="precioDesde" onChange={handleInputChange} id="">
                                    <option value="">{t("Des de")}</option>
                                    <option className='numero-formato' value="65000">65.000 €</option>
                                    <option className='numero-formato' value="100000">100.000 €</option>
                                    <option className='numero-formato' value="150000">150.000 €</option>
                                    <option className='numero-formato' value="200000">200.000 €</option>
                                    <option className='numero-formato' value="250000">250.000 €</option>
                                    <option className='numero-formato' value="300000">300.000 €</option>
                                    <option className='numero-formato' value="500000">500.000 €</option>
                                    <option className='numero-formato' value="700000">700.000 €</option>
                                    <option className='numero-formato' value="1000000">1.000.000 €</option>
                                    <option className='numero-formato' value=""></option>
                                </select>
                                -
                                <select className='numero-formato' style={{ width: 'fit-content' }} name="precioHasta" onChange={handleInputChange} id="">
                                    <option value="">{t("Fins")}</option>
                                    <option className='numero-formato' value="65000">65.000 €</option>
                                    <option className='numero-formato' value="100000">100.000 €</option>
                                    <option className='numero-formato' value="150000">150.000 €</option>
                                    <option className='numero-formato' value="200000">200.000 €</option>
                                    <option className='numero-formato' value="250000">250.000 €</option>
                                    <option className='numero-formato' value="300000">300.000 €</option>
                                    <option className='numero-formato' value="500000">500.000 €</option>
                                    <option className='numero-formato' value="700000">700.000 €</option>
                                    <option className='numero-formato' value="1000000">1.000.000 €</option>
                                    <option className='numero-formato' value="">+1.000.000 €</option>
                                </select>
                            </div>


                        </div>
                    </div>
                    {/* PARA ALQUILER */}
                    <div style={{ display: hidePrecio == 'Lloguer' ? '' : 'none' }} className='filtros-row-container'>
                        <h5 className='filtros-row-title'>{t("Preu")} / {t("mes")}</h5>
                        <div className='filtros-selectores-container'>


                            <div style={{ display: 'flex', alignItems: 'center', columnGap: 3 }}>

                                <select className='numero-formato' style={{ width: 'fit-content' }} name="precioDesde" onChange={handleInputChange} id="">
                                    <option value="">{t("Des de")}</option>
                                    <option className='numero-formato' value="100">100 €</option>
                                    <option className='numero-formato' value="200">200 €</option>
                                    <option className='numero-formato' value="300">300 €</option>
                                    <option className='numero-formato' value="400">400 €</option>
                                    <option className='numero-formato' value="500">500 €</option>
                                    <option className='numero-formato' value="600">600 €</option>
                                    <option className='numero-formato' value="700">700 €</option>
                                    <option className='numero-formato' value="800">800 €</option>
                                    <option className='numero-formato' value="900">900 €</option>
                                    <option className='numero-formato' value="1000">1000 €</option>
                                    <option className='numero-formato' value="1250">1250 €</option>
                                    <option className='numero-formato' value="1500">1500 €</option>
                                    <option value=""></option>
                                </select>
                                -
                                <select className='numero-formato' style={{ width: 'fit-content' }} name="precioHasta" onChange={handleInputChange} id="">
                                    <option value="">{t("Fins")}</option>
                                    <option className='numero-formato' value="100">100 €</option>
                                    <option className='numero-formato' value="200">200 €</option>
                                    <option className='numero-formato' value="300">300 €</option>
                                    <option className='numero-formato' value="400">400 €</option>
                                    <option className='numero-formato' value="500">500 €</option>
                                    <option className='numero-formato' value="600">600 €</option>
                                    <option className='numero-formato' value="700">700 €</option>
                                    <option className='numero-formato' value="800">800 €</option>
                                    <option className='numero-formato' value="900">900 €</option>
                                    <option className='numero-formato' value="1000">1000 €</option>
                                    <option className='numero-formato' value="1250">1250 €</option>
                                    <option className='numero-formato' value="1500">1500 €</option>
                                    <option className='numero-formato' value="">+1500 €</option>
                                </select>
                            </div>


                        </div>
                    </div>

                </div>
                <div id='filtros-second-section' className='filtros-section filtros-second-section'>
                    <div className='filtros-row-container'>
                        <h5 className='filtros-row-title'>{t("Superfície")}</h5>
                        <div className='filtros-selectores-container'>

                            <div style={{ display: 'flex', alignItems: 'center', columnGap: 3 }}>

                                <select className='numero-formato' style={{ width: 'fit-content', padding: '8px' }} name="superficieDesde" onChange={handleInputChange} id="">
                                    <option value="">{t("Des de")}</option>
                                    <option className='numero-formato' value="25">25 m²</option>
                                    <option className='numero-formato' value="50">50 m²</option>
                                    <option className='numero-formato' value="75">75 m²</option>
                                    <option className='numero-formato' value="100">100 m²</option>
                                    <option className='numero-formato' value="1000">1000 m²</option>
                                    <option className='numero-formato' value="2000">2000 m²</option>
                                    <option className='numero-formato' value="3000">3000 m²</option>
                                    <option className='numero-formato' value="4000">4000 m²</option>
                                    <option className='numero-formato' value="5000">5000 m²</option>
                                    <option value=""></option>
                                </select>
                                -
                                <select className='numero-formato' style={{ width: 'fit-content' }} name="superficieHasta" onChange={handleInputChange} id="">
                                    <option value="">{t("Fins")}</option>
                                    <option className='numero-formato' value="25">25 m²</option>
                                    <option className='numero-formato' value="50">50 m²</option>
                                    <option className='numero-formato' value="75">75 m²</option>
                                    <option className='numero-formato' value="100">100 m²</option>
                                    <option className='numero-formato' value="1000">1000 m²</option>
                                    <option className='numero-formato' value="2000">2000 m²</option>
                                    <option className='numero-formato' value="3000">3000 m²</option>
                                    <option className='numero-formato' value="4000">4000 m²</option>
                                    <option className='numero-formato' value="5000">5000 m²</option>
                                    <option className='numero-formato' value="">+5000 m²</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='filtros-row-container'>
                        <h5 className='filtros-row-title'>{t("Habitacions")}</h5>
                        <div style={{ minWidth: 280 }} className='filtros-selectores-container'>


                            <select className='numero-formato' style={{ width: 'fit-content' }} name="habs" onChange={handleInputChange} id="">
                                <option value="">{t("Num habs")}.</option>
                                <option className='numero-formato' value="1">+1</option>
                                <option className='numero-formato' value="2">+2</option>
                                <option className='numero-formato' value="3">+3</option>
                                <option className='numero-formato' value="4">+4</option>
                                <option className='numero-formato' value="5">+5</option>
                                <option className='numero-formato' value="6">+6</option>

                            </select>
                            o
                            <Field
                                placeholder={t("Nombre exacte...")}
                                width='135px'
                                inputName='habsExacto'
                                inputType='number'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />


                        </div>
                    </div>
                    <div className='filtros-row-container '>
                        <h5 className='filtros-row-title'>{t("Banys")}</h5>
                        <div style={{ minWidth: 280 }} className='filtros-selectores-container'>


                            <select className='numero-formato' style={{ width: 'fit-content' }} name="banyos" onChange={handleInputChange} id="">
                                <option value="">{t("Num banys")}</option>
                                <option className='numero-formato' value="1">+1</option>
                                <option className='numero-formato' value="2">+2</option>
                                <option className='numero-formato' value="3">+3</option>
                            </select>


                            o
                            <Field
                                placeholder={t("Nombre exacte...")}
                                width='135px'
                                margin={'0'}
                                padding={'0'}
                                inputName='banyosExacto'
                                inputType='number'
                                onChange={handleInputChange}
                            />


                        </div>
                    </div>

                </div>

                <div id='filtros-third-section' className="filtros-section filtros-third-section">
                    <h5>{t("Característiques")}</h5>
                    <div id='filtros-home-third-subsection' className='filtros-caracts filtros-third-subsection filtros-caracts-desktop-view'>
                        <div>
                            <Field
                                inputType='checkbox'
                                inputName='amoblado'
                                rowMode='true'
                                label={t("Moblat")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='aireAcondicionado'
                                rowMode='true'
                                label={t("Aire Cond.")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='calefaccion'
                                rowMode='true'
                                label={t("Calefacció")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='chimenea'
                                rowMode='true'
                                label={t("Xemeneia")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />

                        </div>
                        <div>
                            <Field
                                inputType='checkbox'
                                inputName='parking'
                                rowMode='true'
                                label={t("Pàrquing")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='ascensor'
                                rowMode='true'
                                label={t("Ascensor")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='jardin'
                                rowMode='true'
                                label={t("Jardí")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='balcon'
                                rowMode='true'
                                label={t("Balcó")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>

                            <Field
                                inputType='checkbox'
                                inputName='terraza'
                                rowMode='true'
                                label={t("Terrassa")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='cocinaOffice'
                                rowMode='true'
                                label={t("Cuina T. Office")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='lavadero'
                                rowMode='true'
                                label={t("Safareig")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='trastero'
                                rowMode='true'
                                label={t("Traster")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    {/* Vista movil */}
                    <div className='filtros-caracts filtros-third-subsection filtros-caracts-movil-view'>
                        <div>
                            <Field
                                inputType='checkbox'
                                inputName='amoblado'
                                rowMode='true'
                                label={t("Moblat")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='aireAcondicionado'
                                rowMode='true'
                                label={t("Aire Cond.")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='calefaccion'
                                rowMode='true'
                                label={t("Calefacció")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='chimenea'
                                rowMode='true'
                                label={t("Xemeneia")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='parking'
                                rowMode='true'
                                label={t("Pàrquing")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='ascensor'
                                rowMode='true'
                                label={t("Ascensor")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div>
                            <Field
                                inputType='checkbox'
                                inputName='jardin'
                                rowMode='true'
                                label={t("Jardí")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='balcon'
                                rowMode='true'
                                label={t("Balcó")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='terraza'
                                rowMode='true'
                                label={t("Terrassa")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='cocinaOffice'
                                rowMode='true'
                                label={t("Cuina T. Office")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='lavadero'
                                rowMode='true'
                                label={t("Safareig")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                            <Field
                                inputType='checkbox'
                                inputName='trastero'
                                rowMode='true'
                                label={t("Traster")}
                                width='130px'
                                margin={'0'}
                                padding={'0'}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="filtros-submit-btn">
                <button onClick={filtrar} className='generic-btn filtros-btn'>{t("Filtrar")}</button>
            </div>


        </div>
        // </div>
    );

    const openCloseModal = () => {
        setModal(!modal);
    };


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'auto' })

    }, [])

    // useEffect(() => {
    //     window.addEventListener('scroll', function () {
    //         let offset = window.scrollY;
    //         if (headerParallax.current) {
    //             headerParallax.current.style.backgroundPositionY = offset * -0.4 + 'px'
    //         }


    //         // heapara.style.backgroundPositionY = offset * -3 + 'px'
    //     })
    // }, [])

    // useEffect(() => {
    //     window.addEventListener('scroll', function () {
    //         let offset2 = window.scrollY;

    //         if (equipoParallax.current) {
    //             equipoParallax.current.style.backgroundPositionY = offset2 * -0.1009 + 'px'
    //             // heapara.style.backgroundPositionY = offset * -3 + 'px'
    //         }
    //     })
    // }, [])

    return (
        <div className='home-container'>
            <div id='hs' ref={headerParallax} className='header-section'>
                <h1 className='header-title'>{t("La seva propera llar és aquí")}</h1>
                <Buscador

                />
                <div className='busqueda-avanzada-link'>
                    <a style={{ cursor: 'pointer' }} className='Link a ' onClick={openCloseModal}>{t("Cerca Avançada")}</a>
                </div>


            </div>
            <div className='destacados-section'>
                <Fade delay={500} duration={1300} >
                    <h1 className='destacados-section-title'>{t("Immobles Destacats")}</h1>
                </Fade>
                <Fade delay={900} duration={1500} >
                    <div className='destacados-sliders-container'>
                        <Slider
                            inmuebles={sliderIzdo} />
                        <Slider
                            inmuebles={sliderDcho}
                        />
                    </div>

                </Fade>


            </div>
            <div id='equipo-section-id' className='equipo-section' ref={equipoParallax}>
                <Fade delay={500}>
                    <h1 className='equipo-titulo'>{t("El nostre equip")}</h1>
                </Fade>
                <div className='equipo-wrapper'>
                    <Fade bottom delay={900} duration={1600}>
                        <div className='equipo-container'>
                            <img className='equipo-profile-img' src={jefesPic} alt="Enric Plana y Montse Curós" />
                            <div className='equipo-description' >
                                <h3>Enric Plana {t("i")} Montse Curós</h3>
                                <i style={{ fontSize: '14px' }}> {t("Socis fundadors de")} Garrotxa Activa.</i>
                                <br />
                                <br />
                                {t("Porten més de 30 anys treballant conjuntament en l'àmbit de l'assessorament i la gestió centrada en les persones. Garrotxa Activa va néixer en aquest esperit i ha anat creixent i desenvolupant les seves àrees (fiscal, laboral, comptable, mercantil, administració de comunitats de propietaris i agència immobiliària) de manera progressiva, consolidant un projecte que, actualment, ha posicionat a Garrotxa Activa com una de les oficines capdavanteres a la Garrotxa.")}
                            </div>
                        </div>
                    </Fade>



                </div>
                <div className='equipo-wrapper'>
                    <Fade left delay={900} duration={1600}>
                        <div className='equipo-container'>
                            <img className='equipo-profile-img' src={adriaPic} alt="Adrià Plana" />
                            <div className='equipo-description'>
                                <h3>Adrià Plana</h3>
                                <i style={{ fontSize: '14px' }}>{t("Agent immobiliari i responsable de")} Garrotxa Activa Immobiliària.</i>
                                <br />
                                <br />
                                {t("Especialitzat en la compra venda de tot tipus d'immobles i finques rústiques.")}
                                <br />
                                <br />
                            </div>
                        </div>
                    </Fade>
                    <Fade right delay={900} duration={1600}>
                        <div className='equipo-container'>

                            <img className='equipo-profile-img' src={eduPic} alt="Eduard Viñas" />
                            <div className='equipo-description'>
                                <h3>Eduard Viñas</h3>
                                <i style={{ fontSize: '14px' }}>{t("Gestor immobiliari de")} Garrotxa Activa Immobiliària.</i>
                                <i style={{ visibility: 'hidden' }}> GA </i>
                                <br />

                                <br />
                                {t("Aporta un ampli coneixement en la gestió de venda i l'administració de lloguers oferint un valor afegit als clients de Garrotxa Activa Immobiliària.")}
                            </div>
                        </div>
                    </Fade>


                </div>


                <Modal
                    open={modal}
                    onClose={openCloseModal}>
                    {body}
                </Modal>
            </div>
            <div className='video-promo-section-home'>
                <div className='video-presentacion-container'>
                    <video title='GA' className='video-presentacion-home' src={video} type="video/mp4" frameBorder='0' controls autoPlay muted preload="auto" >
                        {/* <source src={video} type="video/mp4" /> */}
                    </video>

                </div>
            </div>

        </div >
    );
};

export default Home;