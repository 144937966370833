import React, { useEffect } from 'react';
import './Mapa.css'
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet'
// import "leaflet/dist/leaflet.css";
// import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
// import "leaflet-defaulticon-compatibility";

function Mapa({ lat, long }) {

    let valores = [
        0.0001,
        -0.0001,
        0.0002,
        0.0000005,
        -0.0002,
        -0.0003,
        0.0003
    ]

    let valoresShort = [
        0.0001,
        -0.0001,
        0,
        0.0002,
        -0.0002,
    ]

    function randomLocation(location, valores) {
        let randNum = Math.floor(Math.random() * (valores.length - 1))
        // console.log(valores[randNum])
        return parseFloat(location) + valores[randNum]
    }

    // useEffect(() => {
    //     randomLocation()
    // }, [])

    return (
        <MapContainer center={[lat, randomLocation(long, valores)]} zoom={16} whenReady={() => console.log("Ready")}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                updateWhenIdle={true}
            />
            <Circle center={[randomLocation(lat, valoresShort), randomLocation(long, valores)]} radius={205} color={'#4302f7'} />
        </MapContainer>
    );
}

export default Mapa;