import './Servicios.css'
import React from 'react';

import logo from '../../assets/icons/amoblado-icon.png'
import compraventa from '../../assets/icons/compraventa-icon.png'
import alquiler from '../../assets/icons/alquilar-icon.png'
import valoracion from '../../assets/icons/valoracion-icon.png'
import anuncios from '../../assets/icons/anuncios-icon.png'
import mediacion from '../../assets/icons/mediacion-icon.png'
import tecnologia from '../../assets/icons/tecnologia-icon.png'
import consultoria from '../../assets/icons/consultoria-icon.png'
import carteraAlquileres from '../../assets/icons/carteraAlquileres-icon.png'
import financiacion from '../../assets/icons/financiacion-icon.png'
import inversion from '../../assets/icons/inversion-icon.png'
import gestion from '../../assets/icons/gestion-icon.png'
import videoPresentacion from '../../assets/img/videoPresentacion-min.mp4'
import zIndex from '@material-ui/core/styles/zIndex';
import { t } from 'i18next';




function Servicios(props) {
    return (
        <div className='servicios-container'>
            <div className='first-section'>
                <h1 className='servicios-h1'>{t("Qui som?")}</h1>
                <div className='first-subsection-container'>

                    <div className='first-subsection'>
                        {t("Som una empresa familiar, jove, activa i dinàmica que apostem per una formació constant, sempre un pas per endavant de les exigències del mercat.")}
                        <br />
                        GARROTXA ACTIVA IMMOBILIÀRIA {t("és una branca especialitzada en la intermediació en la compra, venda i lloguer de tot tipus d'immobles situats a la Garrotxa.")}
                        <div style={{ height: '20px' }}></div>
                        {/* Som agents de la propietat immobiliària (API) i administradors de finques que ens hem consolidat com una moderna i eficient immobiliària, per donar servei als clients a través del nostre equip humà altament qualificat, que rep formació constant per poder oferir un servei de consultoria precís i actualitzat. */}
                        {t("Ens esforcem cada dia en oferir el millor de nosaltres mateixos per garantir les millors operacions, un servei integral i un tracte personalitzat.")}
                        <br />
                        {t("El reconeixement obtingut per part dels nostres clients avala la nostra trajectòria professional que es basa en la confiança, el compromís, l’agilitat, la transparència, la serietat i la màxima qualitat en el servei.")}
                        <br />
                        {t("Posem tot el nostre coneixement i l’ajuda necessària per fer de la seva operació immobiliària un èxit.")}
                    </div>
                    <div className='first-subsection'>
                        {t("Estem col·legiats i associats en els següents col·lectius professionals del sector:")}
                        <br />
                        <br />
                        <ul style={{ 'marginLeft': '40px' }}>
                            <li>
                                {t("Col·legi d’agents de propietat immobiliària de Girona (API)")}
                            </li>
                            <br />
                            <li>
                                {t("Administradors i administradores de finques de Girona")}
                            </li>
                            <br />
                            <li>
                                {t("Col·legi d’economistes de Catalunya")}
                            </li>
                            <br />
                            <li>
                                {t("Col·legi oficial de graduats socials de Girona")}
                            </li>
                            <br />
                            <li>
                                {t("Associació catalana d’assessors fiscals, comptables i laborals (ACAT)")}
                            </li>
                        </ul>
                        {/* Amb mes de 25 anys d'experiència gestionant comunitats de propietaris tenim un ampli coneixement de la zona i del sector així com una gran varietat de producte. A mes a mes, també estem especialitzats en la compra-venda de finques rústiques. */}
                        <div style={{ height: '18px' }}></div>
                        {/* Disposem d'oficines a Olot, Ripoll i St. Joan de les Abadesses. */}
                    </div>
                </div>
                <div className='video-container'>
                    <video className='video-presentacion' src={videoPresentacion} type="video/mp4" controls autoPlay muted preload="auto" >

                        Your browser does not support the video tag.
                    </video>
                </div>

            </div>
            <div className='valores-section'>
                <h1 className='servicios-h1'>{t("Els nostres valors")}</h1>
                <p className='servicios-subtitulo'>GARROTXA ACTIVA IMMOBILIÀRIA {t("va néixer de la llarga experiència en l'administració de comunitats de propietaris, en gestió fiscal, laboral i l'assessorament al món empresarial.  Coneixem be el nostre territori i l'estimem. Això fa que  cuidem el detall  atenent a principis ètics i de compromís real amb els nostres clients, les")} <strong> {t("PERSONES")}</strong>.</p>
                <div className='valores-subsection-container'>

                    <div className='valores-card-container'>
                        <div className="valores-img-wrapper">
                            <img className='valores-img' src="https://images.pexels.com/photos/5668842/pexels-photo-5668842.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="imagen Responsabilidad empresarial y social" />
                        </div>
                        <h3 className='valores-titulo'>{t("Responsabilitat empresarial i social")}</h3>
                        <div className='valores-card-texto'>
                            {t("Cerquem l’equilibri de les exigències econòmiques, ambientals i socials, fomentant la formació continua, conciliació familiar i el benestar laboral.")}
                            <br />
                            {t("Participem en projectes i activitats organitzades per entitats socials")}
                            {t("de la Garrotxa que aporten un valor afegit al territori.")}
                        </div>
                    </div>

                    <div className='valores-card-container'>
                        <div className="valores-img-wrapper">

                            {/* <img className='valores-img' src="https://images.pexels.com/photos/416320/pexels-photo-416320.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /> */}

                            <img className='valores-img' src="https://images.pexels.com/photos/7176319/pexels-photo-7176319.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Imagen Honestidad" />
                        </div>
                        <h3 className='valores-titulo'>{t("Honestedat")}</h3>
                        <div className='valores-card-texto'>
                            {t("Oferim als nostres clients allò realment adequat a les seves necessitats.")}
                            {t("Actuem amb la màxima sinceritat, transparència i amb respecte en relació al mercat, els fets i les persones.")}
                            {t("Vetllem per la tranquil·litat i la satisfacció dels nostres clients.")}
                            {/* assessorem en base a la realitat actual,
                            tenint en compte la realitat del mercat i les finances. Vetllem per la
                            tranquil·litat i la satisfacció dels nostres clients. */}

                        </div>
                    </div>

                    <div className='valores-card-container'>
                        <div className="valores-img-wrapper">
                            <img className='valores-img' src="https://images.pexels.com/photos/313691/pexels-photo-313691.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Imagen Profesionalidad" />
                        </div>
                        <h3 className='valores-titulo'>{t("Professionalitat")}</h3>
                        <div className='valores-card-texto'>
                            {t("Conscients d'un món que canvia ràpidament, els nostres professionals estan al dia de les novetats legislatives, econòmiques i fiscals per oferir solucions personalitzades.")}

                        </div>
                    </div>
                    <div className='valores-card-container'>
                        <div className="valores-img-wrapper">
                            <img className='valores-img' src="https://images.pexels.com/photos/4792282/pexels-photo-4792282.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Imagen Rigor" />
                        </div>
                        <h3 className='valores-titulo'>{t("Rigor")}</h3>
                        <div className='valores-card-texto'>
                            {t("Expliquem els processos de manera completa i detallada.")}
                            {t("Aclarim i estudiem totes les despeses i condicions que suposa la compra, la venda o el lloguer d'un immoble, de manera anticipada, perquè els clients estiguin ben informats i assessorats en tot moment.")}
                        </div>
                    </div>
                    <div className='valores-card-container'>
                        <div className="valores-img-wrapper">
                            <img className='valores-img' src="https://images.pexels.com/photos/8293778/pexels-photo-8293778.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Imagen Ilusión y compromiso" />
                        </div>
                        <h3 className='valores-titulo'>{t("Il·lusió i compromís")}</h3>
                        <div className='valores-card-texto'>
                            {t("Ens agrada la nostra feina, la gaudim. Creiem fermament que la il·lusió i el compromís són fonamentals per assolir l’excel·lència.")}
                        </div>
                    </div>
                    <div className='valores-card-container'>
                        <div className="valores-img-wrapper">
                            <img className='valores-img' src="https://images.pexels.com/photos/416320/pexels-photo-416320.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Imagen innovación" />
                        </div>
                        <h3 className='valores-titulo'>{t("Innovació")}</h3>
                        <div className='valores-card-texto'>
                            {t("Invertim en millores tecnològiques i implementem processos, per oferir un servei òptim als nostres clients.")}
                        </div>
                    </div>


                </div>


            </div>
            <div className='servicios-section'>

                <h1 className='servicios-h1'>{t("Serveis")}</h1>
                <div className='second-subsection-container'>

                    <div className='servicio-card-container'>
                        <div className='column-icon'>
                            <img className='servicio-icon' src={compraventa} alt="Icono compraventa" />
                        </div>
                        <div className='column-servicios-content'>
                            <h3 className="servicio-card-title">{t("Compra-venda, inversió i consultoria")}</h3>
                            <div className='column-servicios-texto'>
                                {/* Assesorem i acompanyem en tot el procés de la compra-venda de tot tipus d'immobles.
                                Cerquem oportunitats d'inversió per rendibilitzar el capital del nostres clients. */}
                                {t("Estem al seu costat durant tot el procés. L'assessorem i gestionem cuidadosament el seu patrimoni per aconseguir un òptim rendiment.")}
                            </div>
                        </div>
                    </div>
                    <div className='servicio-card-container'>
                        <div className='column-icon'>
                            <img className='servicio-icon' src={alquiler} alt="Icono alquileres" />
                        </div>
                        <div className='column-servicios-content'>
                            <h3 className="servicio-card-title">{t("Lloguers")}</h3>
                            <div className='column-servicios-texto'>{t("Proporcionem solucions de valor a propietaris i arrendataris en lloguers d'habitatge habitual, oficines i locals comercials.")}</div>
                        </div>
                    </div>
                    <div className='servicio-card-container'>
                        <div className='column-icon'>
                            <img className='servicio-icon' src={valoracion} alt="Icono valoración y mediación" />
                        </div>
                        <div className='column-servicios-content'>
                            <h3 className="servicio-card-title">{t("Valoració i mediació")}</h3>
                            <div className='column-servicios-texto'>
                                {t("L'ajudem a valorar econòmicament el seu immoble d’acord amb els preus de mercat.")}
                                <br />
                                {t("Intervenim amb la negociació del preu perquè totes les parts quedin satisfetes amb l'operació.")}
                            </div>
                        </div>
                    </div>
                    <div className='servicio-card-container'>
                        <div className='column-icon'>
                            <img className='servicio-icon' src={anuncios} alt="Icono Anuncios" />
                        </div>
                        <div className='column-servicios-content'>
                            <h3 className="servicio-card-title">{t("Anuncis i feedback")}</h3>
                            <div className='column-servicios-texto'>{t("Publiquem el seu immoble a diversos portals immobiliaris dels quals disposem d'estadístiques en relació amb la gent que ha visualitzat l'anunci, contactes i visites realitzades.")}</div>
                        </div>
                    </div>
                    {/* <div className='servicio-card-container'>
                        <div className='column-icon'>
                            <img className='servicio-icon' src={mediacion} />
                        </div>
                        <div className='column-servicios-content'>
                            <h3 className="servicio-card-title">Mediació</h3>
                            <div className='column-servicios-texto'> Intervenim amb la negociació del preu perquè totes les parts quedin satisfetes amb l'operació</div>
                        </div>
                    </div> */}
                    {/* <div className='servicio-card-container'>
                        <div className='column-icon'>
                            <img className='servicio-icon' src={tecnologia} />
                        </div>
                        <div className='column-servicios-content'>
                            <h3 className="servicio-card-title">Tecnologia</h3>
                            <div className='column-servicios-texto'>A Garrotxa Activa tenim un programa per creuar el que busca la gent amb la nostra cartera de productes.</div>
                        </div>
                    </div> */}
                    {/* <div className='servicio-card-container'>
                        <div className='column-icon'>
                            <img className='servicio-icon' src={consultoria} />
                        </div>
                        <div className='column-servicios-content'>
                            <h3 className="servicio-card-title">Consultoria</h3>
                            <div className='column-servicios-texto'>L'equip immobiliari participa en formacions constants per mantenir-se actualitzat amb totes les normatives que afecten al sector immobiliari. </div>
                        </div>
                    </div> */}
                    <div className='servicio-card-container'>
                        <div className='column-icon'>
                            <img className='servicio-icon' src={carteraAlquileres} alt="Icono cartera de alquileres" />
                        </div>
                        <div className='column-servicios-content'>
                            <h3 className="servicio-card-title">{t("Cartera de lloguers")}</h3>
                            <div className='column-servicios-texto'>{t("Ens ocupem d'administrar el lloguer de qualsevol immoble, liquidacions mensuals als propietaris, gestió d'incidències, reclamació de morositat, actualització de l'IPC i certificat anual per a la renda.")} </div>
                        </div>
                    </div>
                    <div className='servicio-card-container'>
                        <div className='column-icon'>
                            <img className='servicio-icon' src={financiacion} alt="Icono financiación" />
                        </div>
                        <div className='column-servicios-content'>
                            <h3 className="servicio-card-title">{t("Finançament")}</h3>
                            <div className='column-servicios-texto'>{t("Li busquem el finançament més adequat a les seves necessitats i situació personal.")}</div>
                        </div>
                    </div>
                    {/* <div className='servicio-card-container'>
                        <div className='column-icon'>
                            <img className='servicio-icon' src={inversion} />
                        </div>
                        <div className='column-servicios-content'>
                            <h3 className="servicio-card-title">Inversió</h3>
                            <div className='column-servicios-texto'>Cerquem oportunitats d'inversió per rendibilitzar el capital del nostres clients</div>
                        </div>
                    </div> */}
                    <div className='servicio-card-container'>
                        <div className='column-icon'>
                            <img className='servicio-icon' src={gestion} alt="Icono Gestión" />
                        </div>
                        <div className='column-servicios-content'>
                            <h3 className="servicio-card-title">{t("Gestió i tràmits")}</h3>
                            <div className='column-servicios-texto'>{t("Contractes, Certificats energètics, Cèdules d'habitabilitat i preparació de documents per a qualsevol operació.")}</div>
                        </div>
                    </div>
                </div>
                <div className="custom-shape-divider-bottom-1664896550">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                    </svg>
                </div>
            </div>

        </div >
    );
}

export default Servicios;